<template>
  <div>
    <Modal @close-modal="setShow(false)">
      <div class="borrow">
        <div class="ellipse" />
        <img
          class="vector-grid"
          alt=""
          src="../../../assets/image/icon/new/grid.svg"
          v-if="!themetype"
        />
        <img
          class="vector-grid"
          alt=""
          src="../../../assets/image/icon/new/grid-dark.svg"
          v-else
        />
        <template slot="closeIcon">
          <div class="close-icon-wrapper">
            <img
              src="../../../assets/image/dark/Close_L3.svg"
              v-if="themetype"
            />
            <img src="../../../assets/image/icon/Close_L.svg" v-else />
          </div>
        </template>

        <div class="header">
          <div class="header-img">
            <img
              src="../../../assets/image/icon/new/modal/borrow.svg"
              alt="borrow"
              width="24px"
              height="24px"
            />
          </div>
          <div class="title">Borrow</div>
        </div>

        <div class="modal-body">
          <div class="current-chain field-row">
            <div class="field-left">Destination Chain</div>
            <div class="field-right">
              <div class="chain-img">
                <img
                  :src="icons(item.chain.name)"
                  alt="chain"
                  width="24px"
                  height="24px"
                />
              </div>
              <div class="chain-text">
                {{ this.item.chain.name }}
              </div>
            </div>
          </div>

          <hr class="divider" />

          <div class="available-amt field-row">
            <div class="field-left field-row">
              <div class="field-left">Available</div>
              <div class="field-right">
                {{ toFixedCutZero(walletBalance, 2, false) }} {{ Borrow.token }}
              </div>
            </div>
            <div class="field-right percent-btn-group">
              <button
                class="percent-btn"
                @click="() => setAvailableQuarter(0.25)"
              >
                25%
              </button>
              <button
                class="percent-btn"
                @click="() => setAvailableQuarter(0.5)"
              >
                50%
              </button>
              <button
                class="percent-btn"
                @click="() => setAvailableQuarter(0.75)"
              >
                75%
              </button>
              <button class="percent-btn" @click="() => setAvailableQuarter(1)">
                100%
              </button>
            </div>
          </div>

          <div class="amount-input field-row">
            <div class="left">
              <div class="token-input" v-if="inputtype">
                <input
                  type="text"
                  placeholder="0"
                  class="token"
                  min="0"
                  v-model="inputNumber"
                />
                <div class="dollar">
                  ~ $ {{ toFixedNumber(Borrow.price, 2, true) }}
                </div>
              </div>
              <div class="token-input" v-else>
                <input
                  type="text"
                  placeholder="0"
                  class="token"
                  min="0"
                  v-model="inputNumber"
                />
                <!--  TODO Dynamically add Token symbol -->
                <div class="dollar">
                  ~ {{ toFixedNumber(Borrow.price, 2, true) }}
                  {{ Borrow.token }}
                </div>
              </div>
            </div>
            <div class="right">
              <div class="token" v-if="inputtype">{{ Borrow.token }}</div>
              <div class="token" v-else>$</div>

              <div class="switch-input">
                <button class="switch-btn" @click="Switchupdown">
                  <img
                    src="../../../assets/image/icon/new/switch.svg"
                    alt="switch"
                    width="24px"
                    height="24px"
                  />
                </button>
              </div>
            </div>
          </div>

          <div class="health-factor">
            <div class="field-row">
              <div class="field-left">Health Factor</div>
              <div class="field-right">
                {{ toFixedNumber(Borrow.Health, 2, false) }} %
              </div>
            </div>
            <a-progress :percent="Borrow.Health" :show-info="false" />
          </div>

          <hr class="divider" />

          <div class="borrow-limit field-row">
            <div class="field-left">New Borrow Limit</div>
            <div class="field-right">
              <div class="old-value">
                $ {{ toFixedNumber(Borrow.limitOld, 2, true) }}
              </div>
              <img
                src="../../../assets/image/icon/new/directionRight.svg"
                class=""
                height="20px"
                width="20px"
              />
              <div class="new-value">
                $ {{ toFixedNumber(Borrow.limitNew, 2, true) }}
              </div>
            </div>
          </div>

          <div class="apy field-row">
            <div class="field-left">Borrow APY</div>
            <div class="field-right">
              <div class="value">
                {{ toFixedCutZero(Borrow.apy, 2, false) }}%
              </div>
            </div>
          </div>

          <div class="receive-amt field-row">
            <div class="field-left">You Will Receive</div>
            <div class="field-right">
              <div class="value">
                {{ Borrow.receive }} {{ Borrow.token.replace('test', 'ce') }}
              </div>
            </div>
          </div>

          <hr class="divider" />

          <div class="transaction-fee">
            <div class="title">Transaction Fee</div>
            <div class="transaction-method field-row">
              <div class="field-left">
                <div class="icon-wrapper">
                  <img
                    src="../../../assets/image/icon/layerZero.png"
                    alt="layer zero"
                    class="icon"
                    width="17px"
                    height="28px"
                  />
                </div>
                Layer Zero
              </div>
              <div class="field-right">
                <div class="value">{{ Borrow.fee }} {{ feeToken() }}</div>
                <div class="icon-wrapper">
                  <img
                    src="../../../assets/image/icon/iconUp.svg"
                    alt=""
                    class="icon"
                    width="10px"
                    height="10px"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="buttons">
            <Button
              variant="secondary"
              :handleClick="
                () => {
                  setShow(false);
                }
              "
              >Cancel</Button
            >
            <Button showIcon :handleClick="BorrowF" :isDisabled="btndis">
              Borrow
            </Button>
          </div>
        </div>
      </div>
    </Modal>
    <TxnPending
      v-if="showTxnLoading"
      :hash="hash"
      :setShow="setShow"
      :openTxnComplete="openTxnComplete"
    />
    <TxnComplete
      v-if="showTxnComplete"
      :setShow="setShow"
      modalType="borrow"
      :values="completeValues"
      :hash="hash"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  toFixedCutZero,
  borrow,
  estimateFees,
  getLiquidityValue,
  setTransaction,
  toFixedNumber,
} from '../../../SDK';

import Modal from '../../../components/new/Modal/index';
import Button from '../Btn/index';
import TxnPending from '../../../components/new/Transaction/TxnPending.vue';
import TxnComplete from '../../../components/new/Transaction/TxnCompleted.vue';

export default {
  name: 'Borrow',
  components: {
    Button,
    Modal,
    TxnComplete,
    TxnPending,
  },
  computed: {
    ...mapGetters(['chainid', 'themetype']),
  },

  data() {
    return {
      money: '$',
      money2: '$',
      inputtype: true,
      btndis: true,
      toFixedCutZero: toFixedCutZero,
      toFixedNumber: toFixedNumber,
      inputNumber: 0,
      tokenlist: {},
      maxBorrowLimit: 0,
      Borrow: {
        token: ' ',
        price: 0,
        limitNew: 0.0,
        limitOld: 0.0,
        utilization: 0,
        apy: 0,
        Health: 0,
        Health2: 0,
        receive: 0,
        fee: 0,
      },
      showTxnLoading: false,
      showTxnComplete: false,
      hash: '',
      completeValues: {
        borrowed: '10.234 ETH',
        borrowAPY: '2.35%',
        received: '10.234 debtETH',
      },
    };
  },
  props: {
    setShow: Function,
    item: Object,
    AllMyNumber: Object,
    walletBalance: Number,
  },
  created() {
    console.log('created :>> ', {
      item: this.item,
      AllMyNumber: this.AllMyNumber,
    });
    this.BorrowInfo();
  },
  methods: {
    Switchupdown() {
      this.inputtype = !this.inputtype;

      [this.inputNumber, this.Borrow.price] = [
        this.Borrow.price,
        this.inputNumber,
      ];
      if (this.inputtype) {
        this.maxBorrowLimit =
          (this.AllMyNumber.AllSupply * +this.item.LTV -
            this.AllMyNumber.AllMyBorrow) /
          this.item.OraclePrice;
      } else {
        this.maxBorrowLimit =
          this.AllMyNumber.AllSupply * +this.item.LTV -
          this.AllMyNumber.AllMyBorrow;
      }
    },
    BorrowInfo() {
      this.AllMyNumber.AllBorrowLimit =
        this.AllMyNumber.AllSupply * +this.item.LTV -
        this.AllMyNumber.AllMyBorrow;
      let val = this.inputtype
        ? this.inputNumber
        : +this.inputNumber / +this.item.OraclePrice;

      this.maxBorrowLimit =
        (this.AllMyNumber.AllSupply * +this.item.LTV -
          this.AllMyNumber.AllMyBorrow) /
        this.item.OraclePrice;

      this.Borrow = {
        balance: 0,
        price: 0,
        able: 0,
        token: this.item.TokenName,
        img: this.item.icon,
        limitNew: this.AllMyNumber.AllBorrowLimit - val * this.item.OraclePrice,
        limitOld: this.AllMyNumber.AllBorrowLimit,
        Utilization: 0,
        apy: this.item.BorrowApy,
        loan: this.item.myBorrow - val * this.item.OraclePrice,
        receive: val,
        fee: 0,
        Health:
          (this.AllMyNumber.AllMyBorrow + +val * +this.item.OraclePrice) /
          (this.AllMyNumber.AllSupply * +this.item.LTS),
      };
      estimateFees('borrow', this.item.chain.id).then((a) => {
        this.Borrow.fee = a;
      });
    },
    BorrowF() {
      this.btnloading = true;
      let val = this.inputtype
        ? this.inputNumber
        : +this.inputNumber / +this.item.OraclePrice;
      borrow(
        this.Borrow.token,
        this.item.chain.id,
        val / this.item.dtScaled,
        (code, hash) => {
          if (code === 0) {
            this.showmodal = false;
            this.showTxnLoading = true;
            this.hash = hash;
          } else if (code === 1) {
            setTransaction('Borrow', this.Borrow.token, hash, this.inputNumber);
            this.btnloading = false;
          } else if (code === -1) {
            this.btnloading = false;
            this.$message.error(hash);
          } else if (code === 4) {
            this.btnloading = false;
            getLiquidityValue(this.Borrow.token, this.NetworkName.id).then(
              (a) => {
                this.failedinfo.balance = a.balance;
                this.failedinfo.token = this.Borrow.token;
                this.showfailed = !this.showfailed;
              }
            );
          } else if (code > 1) {
            this.btnloading = false;
          }
        }
      );
    },
    handleTokenInputChange() {
      if (!/^(-)?[0-9.]+$/g.test(this.inputNumber.toString())) {
        this.inputNumber = '0';
      }
      if (this.inputtype) {
        if (+this.inputNumber > this.maxBorrowLimit) {
          this.inputNumber = this.maxBorrowLimit;
        }
      } else {
        if (+this.inputNumber > this.maxBorrowLimit * this.item.OraclePrice) {
          this.inputNumber = this.maxBorrowLimit * this.item.OraclePrice;
        }
      }
      if (this.inputNumber === ' ') {
        this.inputNumber = '0';
      }

      let val = this.inputtype
        ? this.inputNumber
        : +this.inputNumber / +this.item.OraclePrice;

      if (this.inputtype) {
        this.Borrow.price = +this.inputNumber * +this.item.OraclePrice;
        this.Borrow.limitNew =
          this.AllMyNumber.AllBorrowLimit - val * this.item.OraclePrice;
      } else {
        this.Borrow.price = val;
        this.Borrow.limitNew =
          this.AllMyNumber.AllBorrowLimit - this.inputNumber;
      }
      this.Borrow.receive = val / this.item.dtScaled;
      this.Borrow.Health =
        (this.AllMyNumber.AllMyBorrow + +val * +this.item.OraclePrice) /
        (this.AllMyNumber.AllSupply * +this.item.LTS);
    },
    toCutFrontZero(num) {
      let str = num + '';
      if (str.startsWith('.')) {
        str = '0' + str;
      }
      while (str.length >= 2 && str.startsWith('0') && str[1] !== '.') {
        str = str.slice(1);
      }
      return str;
    },
    setAvailableQuarter(percent) {
      if (this.inputtype) {
        this.inputNumber = this.walletBalance * percent;
      } else {
        this.inputNumber = this.walletBalance * percent * this.item.OraclePrice;
      }
      this.handleTokenInputChange();
    },
    openTxnComplete() {
      let val = this.inputtype
        ? this.inputNumber
        : +this.inputNumber / +this.item.OraclePrice;
      this.completeValues = {
        borrowed: `${val} ${this.Borrow.token}`,
        borrowAPY: `${this.Borrow.apy}%`,
        received: `${this.Borrow.receive} debt${this.Borrow.token}`,
      };
      this.showTxnLoading = false;
    },
    feeToken() {
      if (this.chainid === 4002) {
        return 'FTM';
      } else if (this.chainid === 97) {
        return 'BNB';
      } else if (this.chainid === 43113) {
        return 'AVAX';
      } else {
        return 'ETH';
      }
    },
    icons(name) {
      if (name === 'Fantom Testnet') {
        return require('../../../assets/image/icon/FTM.svg');
      } else if (name === 'Avalanche Testnet') {
        return require('../../../assets/image/icon/AVAX.svg');
      } else if (name === 'Bsc Testnet(Root Chain)') {
        return require('../../../assets/image/icon/BNB.svg');
      } else if (name === 'Polygon Mumbai') {
        return require('../../../assets/image/icon/polygon.svg');
      } else if (name === 'Goerli Testnet') {
        return require('../../../assets/image/icon/ETHicon.svg');
      } else if (name === 'Arbitrum Testnet') {
        return require('../../../assets/image/icon/Arbitrum.svg');
      } else {
        return require('../../../assets/image/wallect/unknown.webp');
      }
    },
  },
  watch: {
    inputNumber(val) {
      if (!/^(-)?[0-9.]+$/g.test(val.toString())) {
        this.inputNumber = '0';
      }
      if (+this.inputNumber > 0) {
        this.btndis = false;
        if (this.inputNumber.includes('.')) {
          let index = this.inputNumber.indexOf('.');
          this.inputNumber = this.inputNumber.slice(0, index + 8 + 1);
        }
        this.handleTokenInputChange();
      } else {
        this.btndis = true;
      }
      this.inputNumber = this.toCutFrontZero(this.inputNumber);
    },
  },
};
</script>

<style>
.close-icon-wrapper {
  cursor: pointer;
  background: #ffffff;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  margin-top: 18px;
  z-index: 4;
}
.ellipse {
  position: absolute;
  margin: 0 !important;
  top: -100px;
  left: calc(50% - 100px);
  border-radius: 50%;
  background-color: #ffc2ff;
  filter: blur(100px);
  width: 200px;
  height: 200px;
  z-index: 0;
}

.vector-grid {
  position: absolute;
  margin: 0 !important;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 331.48px;
  height: 237.75px;
  mix-blend-mode: lighten;
  z-index: 1;
}
.borrow {
  position: relative;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    position: relative;
    z-index: 3;
    margin-bottom: 36px;

    .header-img {
      padding: 9px;
      border-radius: 112.5px;
      border: 1.125px solid rgba(255, 255, 255, 0.17);
      background: linear-gradient(212deg, #ff93e0 10.56%, #ffb7eb 93.19%);
      box-shadow: 0px 0px 0px 0px rgba(210, 157, 193, 0.06),
        0px 1px 3px 0px rgba(210, 157, 193, 0.06),
        1px 5px 5px 0px rgba(210, 157, 193, 0.05),
        3px 10px 7px 0px rgba(210, 157, 193, 0.03),
        6px 19px 8px 0px rgba(210, 157, 193, 0.01),
        9px 29px 9px 0px rgba(210, 157, 193, 0);
    }
    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 130%;
      color: #141414;
    }
  }

  .modal-body {
    position: relative;
    z-index: 3;

    .field-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .field-left {
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;
        color: #14141480;
      }

      .field-right {
        font-size: 15px;
        font-style: normal;
        font-weight: 900;
        line-height: 16px;
        color: #141414;
      }
      &.current-chain {
        margin-bottom: 0;
        .field-right {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      &.available-amt {
        margin-bottom: 0;

        .field-left.field-row {
          gap: 10px;
          margin-bottom: 0;

          .field-left {
            font-size: 12px;
            font-weight: 900;
            line-height: 120%;
            color: #7a7a8c99;
          }
        }

        .percent-btn-group {
          display: flex;
          align-items: center;
          gap: 10px;

          .percent-btn {
            border-radius: 9px;
            background: #ffffff;
            font-size: 12px;
            font-weight: 900;
            line-height: 120%;
            color: #7a7a8c;
            padding: 6px 8px;
            border: none;
          }
        }
      }

      &.amount-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        padding: 12px 20px;
        border-radius: 18px;
        margin-top: 8px;

        .left {
          text-align: left;
          flex-grow: 1;

          .token-input {
            .token {
              width: 225px;
              font-size: 36px;
              font-style: normal;
              font-weight: 900;
              line-height: 44px;
              color: #141414;
              border: none;
            }
            .dollar {
              font-size: 15px;
              font-weight: 900;
              line-height: 16px;
              color: #7a7a8c99;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          gap: 16px;

          .token {
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            color: #141414;
          }

          .switch-input {
            .switch-btn {
              background-color: #141414;
              border-radius: 8px;
              padding: 10px;
              border: none;

              :active {
                border: none;
              }
            }
          }
        }
      }

      &.borrow-limit {
        .field-right {
          display: flex;
          align-items: center;
          gap: 7px;

          .old-value {
            color: #14141480;
          }
        }
      }
      &.transaction-method {
        background: #ffffff;
        padding: 6px;
        border-radius: 13px;
        .field-left,
        .field-right {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .field-left {
          font-size: 14px;
          font-weight: 900;
          letter-spacing: 0.14px;

          .icon-wrapper {
            padding: 1px 6px;
            background: #faf7ff;
            border-radius: 8px;
          }
        }

        .field-right {
          font-size: 15px;
          font-weight: 900;
          line-height: 100%;

          .icon-wrapper {
            transform: rotate(90deg);
            padding: 5px;
          }
        }
      }
    }
    .transaction-fee {
      color: #14141480;
      .title {
        text-align: left;
        margin-bottom: 12px;
      }
    }
    .health-factor {
      margin-top: 24px;
      display: block;
      text-align: left;
      .ant-progress-outer {
        .ant-progress-inner {
          background-color: #14141414;
          .ant-progress-bg {
            background-color: #48ff5b;
            filter: drop-shadow(0px 0px 0px rgba(76, 224, 46, 0.2))
              drop-shadow(0px 1px 1px rgba(76, 224, 46, 0.2))
              drop-shadow(0px 2px 2px rgba(76, 224, 46, 0.17))
              drop-shadow(0px 6px 3px rgba(76, 224, 46, 0.1))
              drop-shadow(0px 10px 4px rgba(76, 224, 46, 0.03))
              drop-shadow(0px 16px 4px rgba(76, 224, 46, 0));
          }
        }
      }
      .field-row {
        margin-bottom: 0px;
      }
    }

    .divider {
      border-top: 1px solid #14141414;
      margin: 24px 0;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 24px;
    }
  }
}
</style>
