<template>
  <Modal @close-modal="closeModal()" modalWidth="525px">
    <div class="txn-pending">
      <template slot="closeIcon">
        <div class="close-icon-wrapper">
          <img src="../../../assets/image/dark/Close_L3.svg" v-if="themetype" />
          <img src="../../../assets/image/icon/Close_L.svg" v-else />
        </div>
      </template>

      <div class="modal-content">
        <div class="modal-header">
          <div class="title">
            <div class="header-img">
              <img
                src="../../../assets/image/icon/new/modal/txnPending.svg"
                alt="Transaction Pending"
                width="24px"
                height="24px"
              />
            </div>
            <div class="title-text">
              Transaction in Progress...
              <!-- TODO remove this -->
              {{ toFixedCutZero(timeinfo, 2, false) }}%
            </div>
          </div>
          <div class="subtitle">
            This transaction is expected to take 3~30 minutes to complete
          </div>
        </div>

        <div class="modal-body">
          <table class="txn-progress">
            <tr>
              <td class="icon">
                <img
                  src="../../../assets/image/icon/new/modal/txnPending.svg"
                  alt="Step 1"
                  width="24px"
                  height="24px"
                  v-if="txnStage === 0"
                />
                <img
                  src="../../../assets/image/icon/new/modal/txnComplete2.svg"
                  alt="Step 1"
                  width="24px"
                  height="24px"
                  v-else-if="txnStage > 0"
                />
              </td>
              <td class="description">Tx settled on source chain</td>
            </tr>
            <tr class="gap">
              <td></td>
            </tr>
            <tr>
              <td class="icon">
                <img
                  src="../../../assets/image/icon/new/modal/txnNotStarted.svg"
                  alt="Step 2"
                  width="24px"
                  height="24px"
                  v-if="txnStage === 0"
                />
                <img
                  src="../../../assets/image/icon/new/modal/txnPending.svg"
                  alt="Step 2"
                  width="24px"
                  height="24px"
                  v-if="txnStage === 1"
                />
                <img
                  src="../../../assets/image/icon/new/modal/txnComplete2.svg"
                  alt="Step 2"
                  width="24px"
                  height="24px"
                  v-else-if="txnStage > 1"
                />
              </td>
              <td class="description">
                Message passed by LayerZero to the destination chain
              </td>
            </tr>
            <tr class="gap">
              <td></td>
            </tr>
            <tr>
              <td class="icon">
                <img
                  src="../../../assets/image/icon/new/modal/txnNotStarted.svg"
                  alt="Step 3"
                  width="24px"
                  height="24px"
                  v-if="txnStage < 2"
                />

                <img
                  src="../../../assets/image/icon/new/modal/txnComplete.svg"
                  alt="Step 3"
                  width="24px"
                  height="24px"
                  v-else-if="txnStage === 2"
                />
              </td>
              <td class="description">Tx settled on destination chain</td>
            </tr>
          </table>

          <div class="txn-hash" v-if="showHash">
            <div class="txn-hash-title">Tx Hash</div>
            <div class="txn-hash-value">
              <!-- <a
                :href="`https://cchain.explorer.avax.network/tx/${hash}`"
                target="_blank"
                >{{ hash }}</a
              > -->
              <a href="#" target="_blank">{{
                hash.slice(0, 3) + '...' + hash.slice(-4)
              }}</a>
            </div>
          </div>

          <div class="txn-hash-loading" v-else>Generating Hash</div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  createClient,
  waitForMessageReceived,
} from '@layerzerolabs/scan-client';
import { getHashStatus, getSlider, toFixedCutZero } from '../../../SDK';

import Modal from '../../../components/new/Modal/index';

const client = createClient('testnet');
export default {
  name: 'TxnPending',
  components: { Modal },
  computed: {
    ...mapGetters(['themetype']),
  },
  data() {
    return {
      showHash: false,
      toFixedCutZero: toFixedCutZero,
      txnStage: 0,

      timeinfo: 0,
      timetext: 0,
      loadingtimetype: false,
    };
  },
  props: {
    hash: String,
    setShow: Function,
    openTxnComplete: Function,
  },
  created() {
    this.txnProgress();

    // TODO Remove this
    console.log('hash :>> ', this.hash);
    waitForMessageReceived(10121, this.hash)
      .then((message) => {
        console.log('waitForMessageReceived', {
          completed: true,
          confirmation: {
            chainId: message.dstChainId,
            txHash: message.dstTxHash,
          },
        });
        console.log('message :>> ', message);
      })
      .finally(() => {
        console.log('Txn Complete');
      });
  },
  methods: {
    closeModal() {
      if (this.setShow) {
        this.setShow(false);
      }
    },
    txnProgress() {
      let txnInterval;
      getSlider(this.hash, (a, b) => {
        if (a > 0) {
          this.showHash = true;
          txnInterval = setInterval(() => {
            this.queryApi(this.hash);
          }, 3000);
        }
        this.timeinfo = a;
        this.timetext = b / 60;
        if (this.timetext > 10) {
          this.loadingtimetype = true;
        }

        if (this.timeinfo === 100) {
          clearInterval(txnInterval);
          this.openTxnComplete();
        }
      });
    },
    openhash(index) {
      getHashStatus(index).then((i) => {
        if (i[0]?.url) {
          window.open(i[0].url);
        }
      });
    },
    queryApi(hash) {
      client.getMessagesBySrcTxHash(hash).then((res) => {
        console.log('messages :>> ', res);
        res.messages.forEach((item) => {
          if (item.status === 'DELIVERED') {
            this.txnStage = 2;
          } else {
            this.txnStage = 1;
          }
        });
      });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.txn-pending {
  .modal-content {
    margin: 20px 15px;

    .modal-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 32px;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 18px;
        position: relative;
        z-index: 3;
        font-size: 26px;
        font-weight: 700;
        line-height: 100%;
        color: #141414;

        .header-img {
          padding: 9px;
          border-radius: 112.5px;
          border: 1.125px solid rgba(255, 255, 255, 0.17);
          background: linear-gradient(212deg, #daf51b 10.56%, #edfb8b 93.19%);
          box-shadow: 0px 0px 0px 0px rgba(210, 157, 193, 0.06),
            0px 1px 3px 0px rgba(210, 157, 193, 0.06),
            1px 5px 5px 0px rgba(210, 157, 193, 0.05),
            3px 10px 7px 0px rgba(210, 157, 193, 0.03),
            6px 19px 8px 0px rgba(210, 157, 193, 0.01),
            9px 29px 9px 0px rgba(210, 157, 193, 0);
        }
      }
      .subtitle {
        max-width: 240px;
        font-size: 12px;
        line-height: 16px;
        color: #a3a3a3;
        margin-top: 8px;
      }
    }

    .modal-body {
      .txn-progress {
        margin-bottom: 40px;
        tr {
          .icon {
            padding-right: 20px;
          }
          .description {
            text-align: left;
            font-size: 15px;
            font-weight: 700;
            line-height: 100%;
            color: #141414;
          }

          &.gap {
            height: 40px;
          }
        }
      }

      .txn-hash {
        display: flex;
        justify-content: space-between;

        .txn-hash-title {
          font-size: 15px;
          font-weight: 700;
          line-height: 100%;
          color: #141414;
        }
        .txn-hash-value {
          font-size: 15px;
          font-style: normal;
          font-weight: 900;
          line-height: 16px;
          color: #7268ff;
        }
      }

      .txn-hash-loading {
        text-align: center;
      }
    }
  }
}
</style>
