<template>
  <div class="chips-rate">
    <div :class="trend ? 'trend-up' : 'trend-down'">
      <span class="chips-rate__rate__icon">
        <img src="../../../assets/image/icon/new/trend-up.svg" v-if="trend" />
        <img src="../../../assets/image/icon/new/trend-down.svg" v-else-if="!trend" />
      </span>
      <span class="chips-rate__rate__number">{{ absRate }} %</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChipsRate',
  props: {
    rate: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    trend() {
      return this.rate > 0;
    },
    absRate() {
      return Math.abs(this.rate);
    },
  },
};
</script>

<style scoped lang="scss">
.chips-rate {
  .trend-up,
  .trend-down {
    backdrop-filter: blur(22.5px);
    border-radius: 28px;
    padding: 10px 15px;
    vertical-align: middle;
  }

  .trend-up {
    background: rgba(51, 171, 115, 0.45);
    color: #00ea7d;
  }
  .trend-down {
    background: rgba(204, 61, 68, 0.45);
    color: #ffb0c3;
  }
  .chips-rate__rate__icon {
    width: 13px;
    height: auto;
    margin-right: 5px;
    img {
      padding-bottom: 2px;
    }
  }
  .chips-rate__rate__number {
    font-family: Satoshi;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
  }
}
</style>
