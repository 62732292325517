<template>
  <div class="chips" :class="theme">
    <div
      class="title"
      :class="textColor !== '' ? textColor : theme"
      v-if="title !== ''"
    >
      {{ title }}
    </div>
    <div class="value" :class="textColor !== '' ? textColor : theme">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chips',
  props: {
    theme: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.chips {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 10px 16px;
  border-radius: 57px;

  &.yellow {
    background-color: #bacc3d73;
  }

  &.pink {
    background-color: #ba1e8e73;
  }

  .title {
    font-size: 15px;
    font-weight: 700;
    line-height: 100%;
    &.black {
      color: #000000;
    }
    &.yellow {
      color: #edfb8b80;
    }
    &.pink {
      color: #ff9fe4cc;
    }
  }
  .value {
    font-size: 15px;
    font-weight: 900;
    line-height: 16px;
    &.black {
      color: #000000;
    }
    &.yellow {
      color: #edfb8b;
    }
    &.pink {
      color: #ffade8;
    }
  }
}
</style>
