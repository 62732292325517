<template>
  <div class="txn-history">
    <div class="txnhistory-main" v-if="account !== ''">
      <div class="header">Transaction History</div>
      <a-tabs default-active-key="0" :tabBarGutter="35" type="card">
        <a-tab-pane :key="index" v-for="(historyType, index) in historyTypes">
          <template #tab>
            <span class="tab-header" @click="ChooseDate(historyType.name)">
              <!-- TODO Add new images -->
              <img
                :src="
                  require(`../../../assets/image/icon/new/modal/${
                    historyType.name !== 'Deposit'
                      ? String(historyType.name).toLowerCase()
                      : 'supply'
                  }.svg`)
                "
                alt=""
                width="30px"
                height="30px"
                class="tab-header-icon"
                v-if="historyType.name !== 'All'"
              />
              <img
                :src="
                  require(`../../../assets/image/icon/new/modal/${
                    historyType.name !== 'Deposit'
                      ? String(historyType.name).toLowerCase()
                      : 'supply'
                  }.svg`)
                "
                alt=""
                width="30px"
                height="30px"
                class="tab-header-icon-inactive"
                v-if="historyType.name !== 'All'"
              />

              {{ historyType.name !== 'Deposit' ? historyType.name : 'Supply' }}
            </span>
          </template>
          <div class="txnhistoryList txnhistory-table">
            <div class="list-header">
              <div class="table-list-row">
                <div class="table-list-item w1">Type</div>
                <div class="table-list-item w2">Chain</div>
                <div class="table-list-item w3">Status</div>
                <div class="table-list-item w4">Amount</div>
                <div class="table-list-item w5">Date</div>
              </div>
            </div>
            <div class="list-body" v-if="HistoryData2.length > 0">
              <div
                class="table-list-row"
                v-for="(item, index) in HistoryData2"
                :key="index"
                @click="openUrl(item)"
              >
                <div class="table-list-item type">
                  <div
                    class="icon"
                    :class="{
                      withdraw: item.type === 'Withdraw',
                      supply: item.type === 'Supply',
                      repay: item.type === 'Repay',
                      borrow: item.type === 'Borrow',
                    }"
                  >
                    <img
                      :src="
                        require(`../../../assets/image/icon/new/modal/${
                          item.type !== 'Deposit'
                            ? String(item.type).toLowerCase()
                            : 'supply'
                        }.svg`)
                      "
                      alt=""
                      width="24px"
                      height="24px"
                    />
                  </div>
                  <div class="name">{{ item.type }} {{ item.TokenName }}</div>
                </div>

                <div class="table-list-item chain">{{ item.Chain }}</div>

                <div class="table-list-item status">
                  <div
                    class="icon"
                    :class="{
                      'txn-pending': item.Status === 'Pending',
                      'txn-failed': item.Status === 'Failed',
                      'txn-complete': item.Status === 'Complete',
                    }"
                  >
                    <img
                      :src="
                        require(`../../../assets/image/icon/new/modal/txn${item.Status}.svg`)
                      "
                      alt=""
                      width="24px"
                      height="24px"
                    />
                  </div>
                  <div class="right" v-if="item.Status === 'Pending'">
                    <div class="status-pending">
                      {{ item.Status }}
                    </div>
                    <div class="status-time">
                      Estimated {{ toFixedCutZero(item.StatusTime, 0) }} min
                    </div>
                  </div>
                  <div class="right" v-else>
                    {{ item.Status }}
                  </div>
                </div>

                <div class="table-list-item amount">
                  <div class="token">
                    {{ toFixedCutZero(item.Amount, 2, false) }}
                    {{ item.TokenName }}
                  </div>
                  <div class="dollar">
                    $ {{ toFixedNumber(item.AmountMoney, 2, true) }}
                  </div>
                </div>

                <div class="table-list-item date">{{ item.Time }}</div>
              </div>
            </div>

            <div class="spinner" v-else-if="isFetching">
              <img src="../../../assets/image/icon/new/spinner.svg" alt="" />
            </div>

            <div v-else>No data</div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  formatTime,
  getPrice,
  getTxh,
  getHashStatus,
  toFixedCutZero,
  toFixedNumber,
  getTxHistory5,
  getScanUrl,
} from '../../../SDK';
import { getTxHistory } from '../../../SDK/graphql';
import { chainIdDict } from '../../../SDK/config';
import { getScanUrl3 } from '../../../SDK/httpSearch';

export default {
  name: 'TxnHistory',
  components: {},
  computed: {
    ...mapGetters(['account', 'chainid', 'themetype', 'equipment']),
  },
  data() {
    return {
      mintime: 'Estimated < 1 min',
      settimer: null,
      Moblie: false,
      currPage: 0,
      isFetching: false,
      isFinished: false,
      iconHighlight: true,
      loadingpage: false,
      oldtime: 480,
      showloadingdate: true,
      toFixedNumber: toFixedNumber,
      toFixedCutZero: toFixedCutZero,
      showlist: true,
      loadingdata: [
        {
          id: 1,
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'ETH',
          type: 'Borrow',
          Chain: 'Ethereum',
          Amount: 1.3859,
          hash: '',
          AmountMoney: 400.0,
          Status: 'Pending',
          StatusTime: '8',
          Time: 'December 28, 2022 16:00',
        },
        {
          id: 2,
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'ETH',
          type: 'Withdraw',
          Chain: 'Ethereum',
          Amount: 1.3859,
          hash: '',
          AmountMoney: 400.0,
          Status: 'Pending',
          StatusTime: '8',
          Time: 'December 28, 2022 16:00',
        },
      ],
      HistoryData2: [
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'ETH',
          type: 'Borrow',
          Chain: 'Ethereum',
          Amount: 1.3859,
          hash: '',
          AmountMoney: 400.0,
          Status: 'Pending',
          StatusTime: '8',
          Time: 'December 28, 2022 16:00',
        },
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'ETH',
          type: 'Withdraw',
          Chain: 'Ethereum',
          Amount: 1.3859,
          hash: '',
          AmountMoney: 400.0,
          Status: 'Complete',
          StatusTime: '8',
          Time: 'December 28, 2022 16:00',
        },
      ],
      HistoryData: [
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'ETH',
          type: 'any',
          Chain: 'Ethereum',
          Amount: 1.3859,
          hash: '',
          AmountMoney: 400.0,
          Status: 'Pending',
          StatusTime: '8',
          Time: 'December 28, 2022 16:00',
        },
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'ETH',
          type: 'any',
          Chain: 'Ethereum',
          Amount: 1.3859,
          hash: '',
          AmountMoney: 400.0,
          Status: 'Complete',
          StatusTime: '8',
          Time: 'December 28, 2022 16:00',
        },
      ],
      upHistoryData: [],
      historyTypes: [
        {
          name: 'All',
          type: true,
        },
        {
          name: 'Deposit',
          type: false,
        },
        {
          name: 'Withdraw',
          type: false,
        },
        {
          name: 'Borrow',
          type: false,
        },
        {
          name: 'Repay',
          type: false,
        },
      ],
    };
  },
  async created() {
    this.addMore();
    if (this.account !== '') {
      this.loadingpage = true;
      let t = await getScanUrl3();
      this.oldtime = t;
      await this.upInfo();
      this.ChooseDate(this.historyTypes.find((i) => i.type === true).name);
      this.setDateHistory();
    } else {
      this.loadingpage = true;
      this.HistoryData2 = [
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'ETH',
          type: 'Withdraw',
          Chain: 'Ethereum',
          Amount: 1.3859,
          hash: '',
          AmountMoney: 400.0,
          Status: 'Complete',
          StatusTime: '8',
          Time: 'December 28, 2022 16:00',
        },
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'ETH',
          type: 'Repay',
          Chain: 'Ethereum',
          Amount: 1.3859,
          hash: '',
          AmountMoney: 400.0,
          Status: 'Complete',
          StatusTime: '8',
          Time: 'December 28, 2022 16:00',
        },
      ];
    }
  },
  methods: {
    typetime(ts) {
      let time = this.oldtime / 60 - Math.floor((Date.now() / 1000 - ts) / 60);
      if (time < 0) {
        return 0;
      } else {
        return time;
      }
    },
    pendingTime() {
      for (let i = 0; i < this.HistoryData2.length; i++) {
        if (this.HistoryData2[i].Status === 'Pending') {
          let testtime = this.typetime(this.HistoryData2[i].timestamp);
          this.HistoryData2[i].StatusTime = testtime;

          if (this.HistoryData2[i].url === '') {
            getHashStatus(this.HistoryData2[i].hash).then((url) => {
              this.HistoryData2[i].url = url[0]?.url ?? '';
            });
          }
        }
      }
    },
    async upInfo() {
      this.currPage = 0;
      this.isFinished = false;
      let prices = await getPrice();
      let res = await getTxh();
      this.HistoryData = [];
      await this.addMore();
      for (let i = 0; i < res.length; i++) {
        let item = res[i];
        let price = prices.find((it) => it.token.symbol === item.key).price;
        if (
          this.HistoryData.findIndex((it) => it.hash === item.dstTxHash) === -1
        ) {
          let url = await getHashStatus(item.hash);
          if (item.chainid === 97) {
            url = getScanUrl(item.hash, item.chainid);
          }
          this.HistoryData.push({
            icon: require(`../../../assets/image/icon/${item.key}.png`),
            TokenName: item.key,
            type: item.type,
            hash: item.hash,
            url: url[0]?.url ?? '',
            Chain: chainIdDict[item.chainid],
            chainid: item.chainid,
            Amount: item.tokenAmount,
            AmountMoney: +item.tokenAmount * +price,
            Status: item.status,
            StatusTime: this.typetime(item.timestamp),
            timestamp: item.timestamp,
            Time: formatTime(item.timestamp, 'MMMM D, YYYY HH:mm'),
          });
        }
      }
      this.HistoryData = this.HistoryData.sort(
        (a, b) => b.timestamp - a.timestamp
      );
      this.HistoryData = [...this.HistoryData];
      this.ChooseDate(this.historyTypes.find((i) => i.type === true).name);
      this.loadingpage = false;
    },

    ChooseDate(name) {
      this.historyTypes = this.historyTypes.map((item) => {
        return {
          type: item.name === name,
          name: item.name,
        };
      });
      if (name === 'All') {
        this.HistoryData2 = this.HistoryData;
        return;
      }
      this.HistoryData2 = this.HistoryData.filter(
        (item) => item.type === name || item.type === 'any'
      );
    },
    setDateHistory() {
      clearInterval(this.settimer);
      this.settimer = setInterval(async () => {
        this.pendingTime();
        let dd = await getTxHistory5();
        for (let i = 0; i < dd.length; i++) {
          if (
            this.HistoryData.findIndex((item) => item.hash === dd[i].hash) ===
            -1
          ) {
            let prices = await getPrice();
            let price = prices.find(
              (it) => it.token.symbol === dd[i].key
            ).price;
            let url = await getHashStatus(dd[i].hash);
            if (dd[i].chainid === 97) {
              url = getScanUrl(dd[i].hash, dd[i].chainid);
            }
            this.HistoryData.push({
              icon: require(`../../../assets/image/icon/${dd[i].key}.png`),
              TokenName: dd[i].key,
              type: dd[i].type,
              hash: dd[i].hash,
              url: url[0]?.url ?? '',
              Chain: chainIdDict[dd[i].chainId],
              chainid: dd[i].chainId,
              Amount: dd[i].tokenAmount,
              AmountMoney: +dd[i].tokenAmount * +price,
              Status: dd[i].Status,
              StatusTime: this.typetime(dd[i].timestamp),
              timestamp: dd[i].timestamp,
              Time: formatTime(dd[i].timestamp, 'MMMM D, YYYY HH:mm'),
            });
          } else if (
            this.HistoryData.find((item) => item.hash === dd[i].hash)
              ?.Status === 'Pending'
          ) {
            this.HistoryData = this.HistoryData.filter(
              (ui) => !(ui.hash === dd[i].hash && ui.Status === 'Pending')
            );
            let prices = await getPrice();
            let price = prices.find(
              (it) => it.token.symbol === dd[i].key
            ).price;
            let url = await getHashStatus(dd[i].hash);
            if (dd[i].chainid === 97) {
              url = getScanUrl(dd[i].hash, dd[i].chainid);
            }
            this.HistoryData.push({
              icon: require(`../../../assets/image/icon/${dd[i].key}.png`),
              TokenName: dd[i].key,
              type: dd[i].type,
              hash: dd[i].hash,
              url: url[0]?.url ?? '',
              Chain: chainIdDict[dd[i].chainId],
              chainid: dd[i].chainId,
              Amount: dd[i].tokenAmount,
              AmountMoney: +dd[i].tokenAmount * +price,
              Status: dd[i].Status,
              StatusTime: this.typetime(dd[i].timestamp),
              timestamp: dd[i].timestamp,
              Time: formatTime(dd[i].timestamp, 'MMMM D, YYYY HH:mm'),
            });
          } else if (
            this.HistoryData.find((item) => item.hash === dd[i].dstTxHash)
              ?.Status === 'Pending'
          ) {
            let index = this.HistoryData.findIndex(
              (item) => item.hash === dd[i].hash
            );
            this.HistoryData.splice(index, 1);
            let prices = await getPrice();
            let price = prices.find(
              (it) => it.token.symbol === dd[i].key
            ).price;
            let url = await getHashStatus(dd[i].hash);
            if (dd[i].chainid === 97) {
              url = getScanUrl(dd[i].hash, dd[i].chainid);
            }
            this.HistoryData.push({
              icon: require(`../../../assets/image/icon/${dd[i].key}.png`),
              TokenName: dd[i].key,
              type: dd[i].type,
              hash: dd[i].hash,
              url: url[0]?.url ?? '',
              Chain: chainIdDict[dd[i].chainId],
              chainid: dd[i].chainId,
              Amount: dd[i].tokenAmount,
              AmountMoney: +dd[i].tokenAmount * +price,
              Status: dd[i].Status,
              StatusTime: this.typetime(dd[i].timestamp),
              timestamp: dd[i].timestamp,
              Time: formatTime(dd[i].timestamp, 'MMMM D, YYYY HH:mm'),
            });
          }
        }
        let datatxh = await getTxh();
        let a = this.HistoryData.filter((im) => im.Status === 'Pending');
        for (let y = 0; y < a.length; y++) {
          if (datatxh.findIndex((tx) => tx.hash === a[y].hash) === -1) {
            let index = this.HistoryData.findIndex(
              (hd) => hd.hash === a[y].hash
            );
            if (index !== -1) {
              // this.HistoryData= this.HistoryData.filter(ui=>!(ui.hash===a[y].hash&&ui.Status==="Pending"));
              this.HistoryData.splice(index, 1);
            }
          }
        }
        this.HistoryData = this.HistoryData.sort(
          (a, b) => b.timestamp - a.timestamp
        );
        let action = this.historyTypes.find((it) => it.type === true).name;
        this.ChooseDate(action);
      }, 5000);
    },
    async addMore() {
      if (this.isFetching === false && this.isFinished === false) {
        this.isFetching = true;
        let prices = await getPrice();
        let res = await getTxHistory(this.currPage);
        this.currPage += 1;
        if (res.length < 15) {
          this.isFinished = true;
          this.showloadingdate = false;
        }
        res.forEach((item) => {
          let price = prices.find((it) => it.token.symbol === item.key).price;
          this.HistoryData.push({
            icon: require(`../../../assets/image/icon/${item.key}.png`),
            TokenName: item.key,
            type: item.type,
            url: item.url,
            hash: item.hash,
            Chain: chainIdDict[item.chainId],
            Amount: item.tokenAmount,
            AmountMoney: +item.tokenAmount * +price,
            Status: item.Status,
            timestamp: item.timestamp,
            Time: formatTime(item.timestamp, 'MMMM D, YYYY HH:mm'),
          });
        });
        this.isFetching = false;
      }
      let action = this.historyTypes.find((it) => it.type === true).name;
      this.ChooseDate(action);
    },
    openUrl(item) {
      window.open(item.url);
    },
  },
  watch: {
    account(now, old) {
      if (now !== old && now !== '') {
        this.loadingpage = true;
        getScanUrl3().then((t) => {
          this.oldtime = t;
          this.upInfo().then(() => {
            this.ChooseDate(
              this.historyTypes.find((i) => i.type === true).name
            );
            this.setDateHistory();
          });
        });
      } else {
        this.loadingpage = true;
        this.HistoryData2 = [
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'ETH',
            type: 'Withdraw',
            Chain: 'Ethereum',
            Amount: 1.3859,
            hash: '',
            AmountMoney: 400.0,
            Status: 'Complete',
            StatusTime: '8',
            Time: 'December 28, 2022 16:00',
          },
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'ETH',
            type: 'Repay',
            Chain: 'Ethereum',
            Amount: 1.3859,
            hash: '',
            AmountMoney: 400.0,
            Status: 'Complete',
            StatusTime: '8',
            Time: 'December 28, 2022 16:00',
          },
        ];
      }
    },
  },
  destroyed() {
    clearInterval(this.settimer);
  },
};
</script>

<style scoped lang="scss">
.txn-history {
  background: #faf7ff;

  .txnhistory-main {
    background-color: #faf7ff;
    border-radius: 24px 24px 0px 0px;
    padding: 54px 84px;

    .header {
      font-size: 26px;
      font-weight: 900;
      line-height: normal;
      color: #141414;
      margin-bottom: 32px;
    }
    ::v-deep .ant-tabs-bar {
      border-bottom: none;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tab-header {
        display: flex;
        align-items: center;
        gap: 12px;
        .tab-header-icon {
          display: none;
          &-inactive {
            opacity: 0.3;
          }
        }
      }
      .ant-tabs-tab,
      .ant-tabs-tab-active {
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        color: var(--tabheadercolor);
        border: none;
        background-color: transparent;
        padding: 0px;

        .tab-header-icon {
          display: none;
        }
      }

      .ant-tabs-tab-active {
        color: var(--tabheaderactivecolor);
        .tab-header-icon {
          display: block;
          &-inactive {
            display: none;
          }
        }
      }
    }

    .txnhistory-table {
      .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      .table-list-row {
        display: flex;
        align-items: center;
        gap: 2px;
        justify-content: space-between;
        padding: 16px;

        &:hover {
          border-radius: 20px;
          background-color: var(--tablehoverbg);
        }

        .table-list-item {
          &.type,
          &.status {
            display: flex;
            align-items: center;
            gap: 12px;
            .name {
              color: var(--tabletext);
              font-size: 18px;
              font-weight: 700;
              line-height: 100%; /* 18px */
              letter-spacing: -0.18px;
              text-align: left;
            }

            .icon {
              padding: 9px;
              border-radius: 112.5px;
              border: 1.125px solid rgba(255, 255, 255, 0.17);
              background: linear-gradient(
                212deg,
                #ff93e0 10.56%,
                #ffb7eb 93.19%
              );
              box-shadow: 0px 0px 0px 0px rgba(210, 157, 193, 0.06),
                0px 1px 3px 0px rgba(210, 157, 193, 0.06),
                1px 5px 5px 0px rgba(210, 157, 193, 0.05),
                3px 10px 7px 0px rgba(210, 157, 193, 0.03),
                6px 19px 8px 0px rgba(210, 157, 193, 0.01),
                9px 29px 9px 0px rgba(210, 157, 193, 0);
              line-height: 100%;

              &.borrow,
              &.txn-failed {
                background: linear-gradient(
                  212deg,
                  #ff93e0 10.56%,
                  #ffb7eb 93.19%
                );
              }

              &.withdraw,
              &.txn-complete {
                background: linear-gradient(
                  212deg,
                  #9bff93 10.56%,
                  #e8ffb7 93.19%
                );
              }

              &.supply,
              &.txn-pending {
                background: linear-gradient(
                  212deg,
                  #daf51b 10.56%,
                  #edfb8b 93.19%
                );
              }

              &.repay {
                background: linear-gradient(
                  212deg,
                  #93e0ff 10.56%,
                  #b7ebff 93.19%
                );
              }
            }
          }
          &.chain {
            color: var(--tabletext);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 133.333% */
            letter-spacing: -0.18px;
            text-align: center;
            opacity: 0.8;
          }

          &.date {
            color: var(--tabletext);
            font-size: 18px;
            font-weight: 500;
            line-height: 100%; /* 18px */
            letter-spacing: -0.18px;
            text-align: right;
          }

          &.amount {
            text-align: right;
            .token {
              color: var(--tabletext);
              font-size: 18px;
              font-weight: 500;
              line-height: 24px; /* 133.333% */
              letter-spacing: -0.18px;
            }
            .dollar {
              color: var(--tabletext);
              font-size: 14px;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.28px;
              opacity: 0.8;
            }
          }
        }
      }

      ::v-deep.list-header {
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;
        margin-top: 30px;
        border-radius: 20px;
        color: var(--tableheadertext);
        background: var(--tableheaderbg);
        box-shadow: 0px 0px 0px 0px rgba(217, 214, 255, 0.05),
          0px 5px 11px 0px rgba(217, 214, 255, 0.05),
          0px 19px 19px 0px rgba(217, 214, 255, 0.04),
          0px 44px 26px 0px rgba(217, 214, 255, 0.03),
          0px 78px 31px 0px rgba(217, 214, 255, 0.01),
          0px 121px 34px 0px rgba(217, 214, 255, 0);
      }
      .list-header {
        .table-list-row {
          padding: 30px 16px;
        }
      }

      .list-body {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.18px;
        color: var(--tableheadertext);
        min-height: 40vh;
      }

      .empty-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding: 24px;
        margin: 64px auto;
        border-radius: 20px;
        background-color: var(--tableheaderbg);
        width: fit-content;
        color: var(--tableheadertext);

        h2 {
          color: var(--tableheadertext);
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
        }
        p {
          display: flex;
          align-items: center;
          padding: 0 32px;

          .chips {
            margin: 0 6px;
          }
        }
      }

      &.txnhistoryList {
        .table-list-row {
          .table-list-item {
            &:nth-child(1) {
              width: 25%;
              text-align: left;
            }
            &:nth-child(2) {
              width: 15%;
              text-align: left;
            }
            &:nth-child(3) {
              width: 20%;
              text-align: left;
            }
            &:nth-child(4) {
              width: 20%;
              text-align: right;
            }
            &:nth-child(5) {
              width: 25%;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>
