<template>
  <div class="bottom">
    <div class="bottom-flex">
      <div class="bottom-right">
        <div
          @mouseenter="iconHighlight = false"
          @mouseleave="iconHighlight = true"
          @click="opentwitter"
        >
          <img
            src="../../../assets/image/bottom/twitter.svg"
            v-if="iconHighlight"
          />
          <img src="../../../assets/image/bottom/twitterhover.svg" v-else />
        </div>
        <div
          @mouseenter="iconHighlight2 = false"
          @mouseleave="iconHighlight2 = true"
          @click="opentelegrm"
        >
          <img
            src="../../../assets/image/bottom/telegrm.svg"
            v-if="iconHighlight2"
          />
          <img src="../../../assets/image/bottom/telegrmhover.svg" v-else />
        </div>
        <div
          @mouseenter="iconHighlight3 = false"
          @mouseleave="iconHighlight3 = true"
          @click="opendiscord"
        >
          <img
            src="../../../assets/image/bottom/Group3002.svg"
            v-if="iconHighlight3"
          />
          <img src="../../../assets/image/bottom/Grouphover.svg" v-else />
        </div>
        <div
          @mouseenter="iconHighlight4 = false"
          @mouseleave="iconHighlight4 = true"
          @click="openmedium"
        >
          <img
            src="../../../assets/image/bottom/medium.svg"
            v-if="iconHighlight4"
          />
          <img src="../../../assets/image/bottom/medium2.svg" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MoblieBottom',
  computed: {
    ...mapGetters(['themetype']),
  },
  data() {
    return {
      iconHighlight: true,
      iconHighlight2: true,
      iconHighlight3: true,
      iconHighlight4: true,
    };
  },
  methods: {
    opentelegrm() {
      window.open('https://t.me/cedrofinance');
    },
    opentwitter() {
      window.open('https://twitter.com/cedro_finance');
    },
    opendiscord() {
      window.open('https://discord.gg/R2KmG7bSEm');
    },
    openmedium() {
      window.open('https://medium.com/@cedrolabs');
    },
  },
};
</script>

<style scoped lang="scss">
.bottom {
  display: flex;
  justify-content: center;
  margin-top: 34px;
  margin-bottom: 30px;
  width: 100%;
  .bottom-flex {
    width: calc(100% - 32px);
    height: 24px;
    display: flex;
    justify-content: center;

    .bottom-right {
      width: 40%;
      display: flex;
      justify-content: space-between;

      img {
        cursor: pointer;
      }
    }
  }
}
</style>
