<template>
  <div class="SupplyEchartBox">
    <div id="supply" style="height: 260px"></div>
  </div>
</template>

<script>
export default {
  name: 'SupplyEchart',
  props: {
    supplyinfof: Object,
  },
  data() {
    return {
      myChart: {},
    };
  },
  watch: {
    supplyinfof(nes) {
      if (nes) {
        this.drawEcharts();
      }
    },
  },
  methods: {
    drawEcharts() {
      let _x = this.supplyinfof?.x ?? [];
      let _y = this.supplyinfof?.y ?? [];
      let y = _y.map((it) => Number(it));
      let x = _x.map((it) => it);
      const option = {
        tooltip: {
          // position: ['top','top'],
          show: true,
          // trigger: 'axis',
          trigger: 'axis',
          // axisPointer: {
          //   type: 'line',
          //   snap: true,
          // },
          formatter: (params) => {
            // console.log("params",params)
            const a =
              "<div style='height: 70px;width: 114px;display: flex;flex-direction: column;justify-content: center;align-items: center;background: var(--echartgb);margin: -32px;border: 1px solid var(--echartborder);border-radius: 12px;'>" +
              "<div style='color: var(--echartgb-p1);font-family: Inter;font-size: 14px;font-style: normal'>" +
              'APY   ' +
              params[0].data +
              '%' +
              '</div>' +
              "<div style='color: var(--echartgb-p2);font-size: 14px;font-family: Inter;font-style: normal;'>" +
              params[0].name +
              '</div>' +
              '</div>';
            return a;
          },
        },
        xAxis: {
          type: 'category',
          nameLocation: 'middle',
          data: x.reverse(), //['Mar1','Mar5','Mar10','Mar15','Mar20','Mar25','Mar30','Apr1','Apr5','Apr10','Apr15','Apr20','Apr25','Apr30'],
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        grid: {
          top: '15%',
          left: '0%',
          right: '0%',
          bottom: '15%',
          containLabel: true,
        },
        yAxis: {
          max: Math.max(...y) + 10,
          min: Math.min(...y) - 10,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            legendHoverLink: true,
            symbolSize: 12,
            color: '#AA68F0',
            type: 'line',
            data: y.reverse(), //[150, 230, 224, 218, 135, 147, 260,  120, 137, 250, 147, 260,  120, 137],
            showSymbol: false,
            symbol: 'circle',
            encode: {
              x: 'Year',
              y: 'Income',
              itemName: 'Year',
              tooltip: ['Income'],
            },
            lineStyle: {
              color: '#8247E5',
              width: 3,
            },
            itemStyle: {
              normal: {
                borderWidth: 3,
                borderColor: 'rgba(255, 255, 255, 1)',
                color: '#AA68F0',
              },
            },
            emphasis: {
              symbol: 'circle',
              itemStyle: {
                color: '#AA68F0',
              },
            },
          },
        ],
      };
      this.myChart = this.$echarts.init(document.getElementById('supply'));
      this.myChart.setOption(option, true);
    },
  },
};
</script>

<style scoped lang="scss"></style>
