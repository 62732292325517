import { render, staticRenderFns } from "./MoblieAllowance.vue?vue&type=template&id=fb826a86&scoped=true&"
import script from "./MoblieAllowance.vue?vue&type=script&lang=js&"
export * from "./MoblieAllowance.vue?vue&type=script&lang=js&"
import style0 from "./MoblieAllowance.vue?vue&type=style&index=0&id=fb826a86&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb826a86",
  null
  
)

export default component.exports