<template>
<div>
  <a-modal v-model="visible" :footer="false" width="480px" wrapClassName="failed" :afterClose="closemodel" :centered="true">
    <template slot="closeIcon">
      <img src="../assets/image/icon/Close_L2.svg" style="margin-right: 18px;margin-top: 18px"  >
    </template>
    <div class="failed-title">
      <div class="p1">Transaction failed</div>
      <div class="p2" style="margin-bottom: 19px;">Current pool liquidity is insufficient</div>
      <div class="p4">Available Liquidity </div>
      <div class="p3">{{toFixedCutZero(failedinfo.balance,4,false)}} {{failedinfo.token}}</div>

    </div>
  </a-modal>

</div>
</template>

<script>
import {toFixedCutZero, toFixedNumber} from "../SDK";

export default {
  name: "failed",
  props:{
    setShow: Function,
    failedinfo:Object
  },
  data(){
    return{
      toFixedNumber: toFixedNumber,
      toFixedCutZero: toFixedCutZero,
      visible:true,
    }

  },
  methods:{
    closemodel(){
      this.visible=false

      if(this.setShow){
        this.setShow(false);
      }

    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .ant-modal-body {
  padding: 32px !important;
}
::v-deep .ant-modal-content {
  border-radius: 25px;
}
::v-deep .ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  background: #AA68F0;
  border-radius: 20px;
}

.failed{

.failed-title{

  .p1{
    font-family: "GT America Trial Ext Bd";
    margin-top: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 18px;
  }
  .p2{
    font-family: "GT America Trial Ext Rg";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;

  }
  .p4{
    font-family: "GT America Trial Ext md";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;

  }

  .p3{
    font-family: "GT America Trial Ext Md";
    margin-top: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
  }
}

}
</style>
