<template>
  <div>
    <Modal
      @close-modal="setShow(false)"
      modalWidth="860px"
      :showCloseBtn="false"
    >
      <div class="tokeninfo">
        <div class="close-icon-wrapper" @click="() => setShow(false)">
          <img
            src="../../../assets/image/icon/new/arrow-right.svg"
            v-if="themetype"
            alt="Close"
          />
          <img src="../../../assets/image/icon/new/arrow-right.svg" v-else />
        </div>

        <div class="section section1">
          <div class="section-card token-card">
            <div class="token-name">
              <div class="icon">
                <img :src="item.icon" alt="" width="44px" height="44px" />
              </div>
              {{ item.TokenName }}
            </div>
            <div class="actions">
              <Button show-icon :handleClick="() => openSupplyModal(item)"
                >Supply</Button
              >
              <Button show-icon :handleClick="() => openBorrowModal(item)"
                >Borrow</Button
              >
            </div>
          </div>

          <div class="section-card token-details">
            <table>
              <tr>
                <td class="title">Oracle Price</td>
                <td class="value">
                  $ {{ toFixedNumber(item.OraclePrice, 2, true) }}
                </td>
              </tr>
              <tr>
                <td class="title">Available Liquidity</td>
                <td class="value">
                  $ {{ toFixedNumber(item.liquidationPrice, 2, true) }}
                </td>
              </tr>
              <tr>
                <td class="title">Liquidation Threshold</td>
                <td class="value">{{ toFixedNumber(item.LTS, 2, true) }}%</td>
              </tr>
              <tr>
                <td class="title">Liquidation Bonus</td>
                <td class="value">
                  $ {{ toFixedNumber(item.OraclePrice, 2, true) }}%
                </td>
              </tr>
              <tr>
                <td class="title">LTV</td>
                <td class="value">$ {{ toFixedNumber(item.LTV, 2, true) }}%</td>
              </tr>
            </table>
          </div>
        </div>

        <hr class="divider" />

        <div class="section section2">
          <div class="section-card total-supply">
            <div class="info">
              <div class="title">Total Supply</div>
              <div class="value">
                <div>
                  <div class="token-amt">
                    {{ toFixedCutZero(item.TotalSupply, 2, true) }} ETH
                  </div>
                  <div class="dollar-amt">
                    $ {{ toFixedNumber(item.TotalSupplyMoney, 2, true) }}
                  </div>
                </div>
              </div>
              <!-- TODO add Collateral Usage -->
              <!-- <div>
                <div>Collateral Usage</div>
                <div>$10,390.57</div>
              </div> -->
            </div>
            <div class="apy">
              <Chips
                theme="yellow"
                title="APY"
                :value="`${toFixedCutZero(item.SupplyApy, 2, false)}%`"
                textColor="black"
              />
            </div>
          </div>

          <div class="section-card total-borrow">
            <div class="info">
              <div class="title">Total Borrow</div>
              <div class="value">
                <div>
                  <div class="token-amt">
                    {{ toFixedCutZero(item.TotalBorrow, 2, true) }} ETH
                  </div>
                  <div class="dollar-amt">
                    $ {{ toFixedNumber(item.TotalBorrowMoney, 2, true) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="apy">
              <Chips
                theme="pink"
                title="APY"
                :value="`${toFixedCutZero(item.BorrowApy, 2, false)}%`"
                textColor="black"
              />
            </div>
          </div>
        </div>

        <hr class="divider" />

        <!-- TODO Add Graph -->
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toFixedCutZero, toFixedNumber } from '../../../SDK';

import Button from '../../../components/new/Btn/';
import Chips from '../../../components/new/Chips/';
import Modal from '../../../components/new/Modal/';

export default {
  name: 'TokenInfo',
  components: { Button, Chips, Modal },
  computed: {
    ...mapGetters(['chainid', 'themetype']),
  },
  props: {
    setShow: Function,
    item: Object,
    openSupplyModal: Function,
    openBorrowModal: Function,
  },
  data() {
    return {
      toFixedNumber: toFixedNumber,
      toFixedCutZero: toFixedCutZero,
    };
  },
  created() {},
  methods: {
    openSupply(item) {
      this.closeModal();
      this.openSupplyModal(item);
    },
    closeModal() {
      () => this.setShow(false);
    },
  },
};
</script>

<style scoped lang="scss">
.close-icon-wrapper {
  cursor: pointer;
  background: #ffffff;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  margin-top: 18px;
  z-index: 4;
}
.tokeninfo {
  .close-icon-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    margin-left: -10px;
  }
  .section {
    margin-bottom: 24px;
    display: grid;
    grid-row: auto auto;
    grid-column-gap: 44px;
    grid-template-columns: 1fr 1fr;

    .section-card {
      width: 100%;

      &.token-card {
        border-radius: 20px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background: radial-gradient(
            142.12% 115.72% at 29.34% 26.78%,
            rgba(159, 179, 255, 0.7) 0%,
            rgba(167, 186, 255, 0.7) 16.15%,
            rgba(172, 191, 254, 0.7) 47.92%,
            rgba(201, 221, 255, 0.7) 100%
          ),
          radial-gradient(
            444.17% 142.96% at 19.08% 27.87%,
            rgba(205, 215, 255, 0.6) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 0px 0px rgba(153, 173, 255, 0.05),
          1px 6px 14px 0px rgba(153, 173, 255, 0.05),
          6px 24px 25px 0px rgba(153, 173, 255, 0.04),
          13px 54px 33px 0px rgba(153, 173, 255, 0.03),
          22px 96px 39px 0px rgba(153, 173, 255, 0.01),
          35px 150px 43px 0px rgba(153, 173, 255, 0);

        .token-name {
          font-size: 36px;
          font-style: normal;
          font-weight: 900;
          color: #ffffff;
          padding-bottom: 24px;
          line-height: 100%;
          display: flex;
          align-items: center;

          gap: 14px;
        }
        .actions {
          display: flex;
          gap: 8px;

          img {
            opacity: 0.6;
          }
        }
      }

      &.token-details {
        table {
          width: 100%;
          tr {
            td {
              font-size: 14px;
              font-weight: 400;
              color: #a3a3a3;
              padding: 8px 0;
              &.title {
                padding-right: 24px;
              }
            }

            :nth-child(1) {
              color: rgba(0, 0, 0, 0.5);
              font-size: 15px;
              font-weight: 700;
              line-height: 100%;
              text-align: left;
            }
            :nth-child(2) {
              color: #141414;
              font-size: 15px;
              font-weight: 900;
              line-height: 100%;
              text-align: right;
            }
          }
        }
      }

      &.total-supply,
      &.total-borrow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info {
          text-align: left;
          .title {
            color: #00000080;
            font-size: 15px;
            font-weight: 700;
            line-height: 100%;
            padding-bottom: 10px;
          }

          .value {
            .token-amt {
              color: #000000;
              font-size: 24px;
              font-weight: 700;
              letter-spacing: 0.3px;
              line-height: 100%;
              padding-bottom: 5px;
            }

            .dollar-amt {
              color: #00000080;
              font-size: 15px;
              font-weight: 700;
              line-height: 100%;
            }
          }
        }
      }
    }
  }

  .divider {
    border-top: 1px solid #14141414;
    margin: 24px 0;
  }
}
</style>
