<template>
  <div
    class="dashboard"
    :class="{ 'dashboard-dark': themetype }"
    v-if="equipment === 0"
  >
    <div class="dashboard-top">
      <div class="dashboard-title" :class="{ 'border-bottom': account !== '' }">
        <div class="name">Dashboard</div>
        <div class="global-market">
          <div class="stat">
            <div class="title">Total Supply</div>
            <div class="value">
              <span>
                $
                <countTo
                  :startVal="0"
                  :endVal="+ModularData.Tosupply"
                  :duration="autoplayf"
                  :decimals="2"
                  style="position: relative"
                ></countTo>
              </span>
              <ChipsRate :rate="10" />
            </div>
          </div>
          <div class="stat">
            <div class="title">Total Borrow</div>
            <div class="value">
              <span>
                $
                <countTo
                  :startVal="0"
                  :endVal="+ModularData.Toborrow"
                  :duration="autoplayf"
                  :decimals="2"
                  style="position: relative"
                ></countTo>
              </span>
              <ChipsRate :rate="-10" />
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-top-info" v-if="account !== ''">
        <div class="block">
          <div class="title">My Supplies</div>
          <div class="p2">
            <div class="p2-left">
              <div class="value">
                $
                <countTo
                  :startVal="0"
                  :endVal="+ModularData.supplies"
                  :duration="autoplayf"
                  :decimals="2"
                ></countTo>
              </div>
              <Chips theme="yellow" title="Available" value="$1390.57" />
            </div>
            <div class="p2-right">
              <div class="p2-right-item">
                <div class="p2-right-item-title">Net APY</div>
                <div class="p2-right-item-value">
                  <countTo
                    :startVal="0"
                    :endVal="+ModularData.Apy"
                    :duration="autoplayf"
                    :decimals="2"
                  ></countTo>
                  %
                </div>
              </div>
              <div class="p2-right-item">
                <div class="p2-right-item-title">Interest Earned</div>
                <div class="p2-right-item-value">
                  $
                  <countTo
                    :startVal="0"
                    :endVal="+ModularData.Earned"
                    :duration="autoplayf"
                    :decimals="2"
                  ></countTo>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="title">My Borrows</div>
          <div class="p2">
            <div class="p2-left">
              <div class="value">
                $
                <countTo
                  :startVal="0"
                  :endVal="+ModularData.Borrow"
                  :duration="autoplayf"
                  :decimals="2"
                ></countTo>
              </div>
              <Chips
                theme="pink"
                title="Available"
                :value="`$ ${toFixedNumber(
                  AllMyNumber.AllBorrowLimit,
                  2,
                  true
                )}`"
              />
            </div>
            <div class="p2-right">
              <div class="p2-right-item health-factor">
                <div class="p2-right-item-title">
                  Health factor
                  <a-tooltip :overlayStyle="{}">
                    <template #title>
                      <p style="padding: 5px; margin: 0px">
                        It is the ratio of the Collateral value multiplied by
                        the LTV, and the borrowed value. H.F. 1 is considered
                        unhealthy. So the collateral will be available for
                        liquidation.
                      </p>
                    </template>
                    <div
                      @mouseenter="iconHighlight3 = false"
                      @mouseleave="iconHighlight3 = true"
                    >
                      <img
                        src="../../../assets/image/icon/new/question-white.svg"
                        alt=""
                        width="16px"
                        height="16px"
                        style="cursor: help"
                        v-if="iconHighlight3"
                      />
                      <img
                        src="../../../assets/image/icon/question2.svg"
                        style="cursor: help"
                        width="16px"
                        height="16px"
                        alt=""
                        v-else
                      />
                    </div>
                  </a-tooltip>
                </div>
                <a-progress
                  type="circle"
                  :percent="+toFixedCutZero(ModularData.Factor, 0, false)"
                  :width="106"
                  :strokeColor="
                    +toFixedCutZero(ModularData.Factor, 0, false) > 75
                      ? 'red'
                      : '#FFCC48'
                  "
                  :strokeWidth="9"
                  trailColor="#14141414"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-null" v-if="account === ''">
      <div class="dashboard-wallet-connect">
        <div class="p1">Please Connect Your Wallet</div>
        <div class="p2">
          You need to connect your wallet, to see your supplies and borrowings.
        </div>
        <div class="b2">
          <Button showIcon size="md" :loading="loading" :handleClick="login"
            >Connect Wallet</Button
          >
        </div>
      </div>
    </div>
    <div class="dashboard-main" v-if="account !== ''">
      <a-tabs default-active-key="1" :tabBarGutter="35" type="card">
        <a-tab-pane key="1">
          <template #tab>
            <span class="tab-header">
              <img
                src="../../../assets/image/icon/new/CollateralList.svg"
                alt=""
                width="30px"
                height="30px"
                class="tab-header-icon"
              />
              <img
                src="../../../assets/image/icon/new/CollateralList2.svg"
                alt=""
                width="30px"
                height="30px"
                class="tab-header-icon-inactive"
              />
              Collateral List
            </span>
          </template>
          <div class="CollateralList dashboard-table">
            <div class="list-header">
              <div class="table-list-row">
                <div class="table-list-item w1">Assets</div>
                <div class="table-list-item w2">Chain</div>
                <div class="table-list-item w3">Oracle Price</div>
                <div class="table-list-item w5">My Collateral</div>
                <div class="table-list-item w6">Interest Earned</div>
                <div class="table-list-item w7">Supply APY</div>
                <div class="table-list-item w4">LTV</div>
                <div class="table-list-item w8">
                  <div class="n1">Actions</div>
                  <div class="nulldiv"></div>
                </div>
              </div>
            </div>
            <!-- <div class="table-list-row">
                <div class="table-list-item assets">
                  <div class="icon">
                    <img
                      src="../../../assets/image/icon/new/chain/eth.svg"
                      alt=""
                      width="30px"
                      height="30px"
                    />
                  </div>
                  <div class="name">ETH</div>
                </div>
                <div class="table-list-item chain">Polygon</div>
                <div class="table-list-item oracle-price">$2353.23</div>
                <div class="table-list-item ltv">80%</div>
                <div class="table-list-item my-collateral">
                  <div class="token">900.3245 ETH</div>
                  <div class="dollar">$400,000.00</div>
                </div>
                <div class="table-list-item interest">
                  <div class="token">0.0005 ETH</div>
                  <div class="dollar">$0.88</div>
                </div>
                <div class="table-list-item apy">2.23%</div>
                <div class="table-list-item actions">
                  <Button>Supply More</Button>
                  <Button variant="secondary">Withdraw</Button>
                </div>
              </div> -->

            <div class="spinner" v-if="isPageLoading">
              <img src="../../../assets/image/icon/new/spinner.svg" alt="" />
            </div>
            <div class="list-body" v-if="showCollateralList">
              <div
                class="table-list-row"
                v-for="(item, index) in CollateralData.filter(
                  (t) => t.CollateralToken > 0
                )"
                :key="index"
                @click="() => openTokenInfo(item)"
              >
                <div class="table-list-item assets">
                  <div class="icon">
                    <img :src="item.icon" alt="" width="30px" height="30px" />
                  </div>
                  <div class="name">{{ item.TokenName }}</div>
                </div>
                <div class="table-list-item chain">
                  {{ item.chain.name }}
                </div>
                <div class="table-list-item oracle-price">
                  $ {{ toFixedCutZero(item.OraclePrice, 2, true) }}
                </div>
                <div class="table-list-item my-collateral">
                  <div class="token">
                    {{ toFixedCutZero(item.CollateralToken, 2, true) }}
                  </div>
                  <div class="dollar">
                    $
                    {{ toFixedCutZero(item.Collateral, 2, true) }}
                  </div>
                </div>
                <div class="table-list-item interest">
                  <div class="token">
                    {{ toFixedCutZero(item.EarnedToken, 2, true) }}
                  </div>
                  <div class="dollar">
                    $
                    {{ toFixedCutZero(item.Earned, 2, true) }}
                  </div>
                </div>
                <div class="table-list-item apy">
                  {{ toFixedCutZero(item.SupplyApy, 2, false) }}%
                </div>
                <div class="table-list-item ltv">{{ item.LTV }}</div>
                <div class="table-list-item actions">
                  <Button :handleClick="() => openSupplyModal(item)"
                    >Supply More</Button
                  >
                  <Button
                    variant="secondary"
                    :handleClick="() => openWithdrawModal(item)"
                    >Withdraw</Button
                  >
                </div>
              </div>
            </div>
            <div class="empty-body" v-else>
              <h2>No Supplies yet</h2>
              <p>
                You can
                <Chips theme="yellow" textColor="black" value="lend $1,390" />
                in
                <Chips theme="yellow" textColor="black" value="120 assets" />
                across
                <Chips theme="yellow" textColor="black" value="12 networks" />
              </p>
              <Button showIcon size="md" :handleClick="openMarket"
                >Go to Market</Button
              >
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" force-render>
          <template #tab>
            <span class="tab-header">
              <img
                src="../../../assets/image/icon/new/BorrowList.svg"
                alt=""
                width="30px"
                height="30px"
                class="tab-header-icon"
              />
              <img
                src="../../../assets/image/icon/new/BorrowList2.svg"
                alt=""
                width="30px"
                height="30px"
                class="tab-header-icon-inactive"
              />
              Borrow List
            </span>
          </template>
          <div class="BorrowList dashboard-table">
            <div class="list-header">
              <div class="table-list-row">
                <div class="table-list-item w1">Assets</div>
                <div class="table-list-item w2">Chain</div>
                <div class="table-list-item w3">Oracle Price</div>
                <div class="table-list-item w5">My Borrowed</div>
                <div class="table-list-item w6">Interest Payable</div>
                <div class="table-list-item w7">Borrow APY</div>
                <div class="table-list-item w4">Liquidation Price</div>
                <div class="table-list-item w8">
                  <div class="n1">Actions</div>
                  <div class="nulldiv"></div>
                </div>
              </div>
            </div>
            <div class="spinner" v-if="isPageLoading">
              <img src="../../../assets/image/icon/new/spinner.svg" />
            </div>
            <div class="list-body" v-if="showBorrowList">
              <div
                class="table-list-row"
                v-for="(item, index) in CollateralData.filter(
                  (t) => t.BorrowedToken > 0
                )"
                :key="index"
                @click="() => openTokenInfo(item)"
              >
                <div class="table-list-item assets">
                  <div class="icon">
                    <img :src="item.icon" alt="" width="30px" height="30px" />
                  </div>
                  <div class="name">{{ item.TokenName }}</div>
                </div>
                <div class="table-list-item chain">{{ item.chain.name }}</div>
                <div class="table-list-item oracle-price">
                  ${{ toFixedCutZero(item.OraclePrice, 2, true) }}
                </div>
                <div class="table-list-item my-borrowed">
                  <div class="token">
                    {{ toFixedCutZero(item.BorrowedToken, 2, true) }}
                  </div>
                  <div class="dollar">
                    $ {{ toFixedCutZero(item.Borrowed, 2, true) }}
                  </div>
                </div>
                <div class="table-list-item interest">
                  <div class="token">
                    {{ toFixedCutZero(item.PayableToken, 2, true) }}
                  </div>
                  <div class="dollar">
                    $ {{ toFixedCutZero(item.Payable, 2, true) }}
                  </div>
                </div>
                <div class="table-list-item apy">
                  {{ toFixedCutZero(item.BorrowApy, 2, false) }}%
                </div>
                <div class="table-list-item liquidation-price">
                  $ {{ toFixedCutZero(item.liquidationPrice, 2, true) }}
                </div>
                <div class="table-list-item actions">
                  <Button :handleClick="() => openBorrowModal(item)">
                    Borrow More
                  </Button>
                  <Button
                    variant="secondary"
                    :handleClick="() => openRepayModal(item)"
                  >
                    Repay
                  </Button>
                </div>
              </div>
            </div>
            <div class="empty-body" v-else>
              <h2>No Borrows yet</h2>
              <p>
                You can
                <Chips theme="pink" textColor="black" value="borrow $1,390" />
                in
                <Chips theme="pink" textColor="black" value="120 assets" />
                across
                <Chips theme="pink" textColor="black" value="12 networks" />
              </p>
              <Button showIcon size="md" :handleClick="openMarket">
                Go to Market
              </Button>
            </div>
          </div>
        </a-tab-pane>
        <div slot="tabBarExtraContent">
          <div class="tab-right-content">
            <div class="actions">
              <div class="filter">
                <Dropdown>
                  <template slot="toggler">
                    <div class="btn-secondary">
                      <div class="dropdown-toggler-btn">
                        <div>
                          <div
                            v-if="
                              checkedChainList.length === networkList.length
                            "
                          >
                            All Chains
                          </div>

                          <div v-else>
                            Selected Chains
                            <span class="selected-chain-count">{{
                              checkedChainList.length
                            }}</span>
                          </div>
                        </div>
                        <img
                          src="../../../assets/image/icon/new/arrow-down-white.svg"
                          width="20px"
                          height="20px"
                          v-if="themetype"
                          alt="All Chains"
                        />
                        <img
                          src="../../../assets/image/icon/new/arrow-down.svg"
                          width="20px"
                          height="20px"
                          v-else
                          alt="All Chains"
                        />
                      </div>
                    </div>
                  </template>
                  <DropdownContent>
                    <div>
                      <img
                        src="../../../assets/image/icon/new/filter.svg"
                        alt=""
                        width="20px"
                        height="20px"
                      />
                      Chain Filter
                    </div>

                    <DropdownItem
                      v-for="(item, index) in networkList"
                      :key="index"
                      :handleClick="() => null"
                    >
                      <div class="chain-list-item">
                        <input
                          type="checkbox"
                          :value="item.id"
                          v-model="checkedChainList"
                          :id="item.id"
                        />
                        <img
                          :src="item.icon"
                          width="20px"
                          height="20px"
                          alt=""
                        />
                        <div>{{ item.name }}</div>
                      </div>
                    </DropdownItem>
                  </DropdownContent>
                </Dropdown>
              </div>
              <Button showIcon size="md" :handleClick="openMarket">
                Add from the Market</Button
              >
            </div>
          </div>
        </div>
      </a-tabs>
    </div>
    <WalletConnect v-if="showSignin" :setShow="setShowModal" />

    <Supply
      v-if="showSupplyModal"
      :setShow="setShowModal"
      :item="selectedItem"
      :AllMyNumber="AllMyNumber"
      :walletBalance="walletBalance"
    />
    <Borrow
      v-if="showBorrowModal"
      :setShow="setShowModal"
      :item="selectedItem"
      :AllMyNumber="AllMyNumber"
      :walletBalance="walletBalance"
    />
    <Withdraw
      v-if="showWithdrawModal"
      :setShow="setShowModal"
      :item="selectedItem"
      :AllMyNumber="AllMyNumber"
      :walletBalance="walletBalance"
    />
    <Repay
      v-if="showRepayModal"
      :setShow="setShowModal"
      :item="selectedItem"
      :AllMyNumber="AllMyNumber"
      :walletBalance="walletBalance"
    />
    <SwitchNetwork
      v-if="showSwitchNetwork"
      :setShow="setShowModal"
      :switchChainId="switchId"
    />
    <EnableToken
      v-if="showEnableToken"
      :setShow="setShowModal"
      :tokenInfo="EnableType"
    />
    <TokenInfo
      :setShow="setShowModal"
      v-if="showTokenInfo"
      :item="selectedItem"
      :openSupplyModal="openSupplyModal"
      :openBorrowModal="openBorrowModal"
    />
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import countTo from 'vue-count-to';
import { tokens } from '../../../SDK/config';
import { checkTransactionStatus } from '../../../SDK/checkHash';
import {
  getBalance,
  getPoolList,
  toFixedCutZero,
  toFixedNumber,
} from '../../../SDK';

import Chips from '../../../components/new/Chips';
import Repay from '../../../components/new/Repay';
import Borrow from '../../../components/new/Borrow';
import Supply from '../../../components/new/Supply';
import Footer from '../../../components/new/Footer';
import Button from '../../../components/new/Btn/index';
import Withdraw from '../../../components/new/Withdraw';
import ChipsRate from '../../../components/new/ChipsRate';
import TokenInfo from '../../../components/new/TokenInfo';
import Dropdown from '../../../components/new/Dropdown/index.vue';
import EnableToken from '../../../components/new/SignIn/EnableToken';
import WalletConnect from '../../../components/new/SignIn/WalletConnect';
import SwitchNetwork from '../../../components/new/SignIn/SwitchNetwork';
import DropdownItem from '../../../components/new/Dropdown/DropdownItem.vue';
import DropdownContent from '../../../components/new/Dropdown/DropdownContent.vue';

export default {
  name: 'index',

  components: {
    // MobileDashboard,
    Borrow,
    Repay,
    Supply,
    Withdraw,
    EnableToken,
    countTo,
    Button,
    Chips,
    ChipsRate,
    Dropdown,
    DropdownContent,
    DropdownItem,
    Footer,
    SwitchNetwork,
    TokenInfo,
    WalletConnect,
  },
  computed: {
    ...mapGetters([
      'account',
      'chainid',
      'borrowInfo',
      'themetype',
      'Numberautoplay',
      'equipment',
    ]),
  },
  data() {
    return {
      autoplayf: 1,
      Mobiles: false,
      showfactor: false,
      ismoneytype: 1,
      isPageLoading: true,
      toFixedNumber: toFixedNumber,
      toFixedCutZero: toFixedCutZero,
      showDashboard: true,
      showCollateralList: true,
      showBorrowList: true,
      showSignin: false,

      settime: null,
      tokenId: '',
      walletBalance: 0,
      switchnetwork: '',
      switchid: 0,
      networkList: [
        {
          icon: require('../../../assets/image/icon/FTM.svg'),
          name: 'Fantom Testnet',
          id: 4002,
        },
        {
          icon: require('../../../assets/image/icon/AVAX.svg'),
          name: 'Avalanche Testnet',
          id: 43113,
        },
        {
          icon: require('../../../assets/image/icon/polygon.svg'),
          name: 'Polygon Mumbai',
          id: 80001,
        },
        {
          icon: require('../../../assets/image/icon/Arbitrum.svg'),
          name: 'Arbitrum Testnet',
          id: 421613,
        },
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          name: 'Goerli Testnet',
          id: 5,
        },
      ],
      checkedChainList: [],
      iconHighlight3: true,
      networklists: [],
      TypeOperation: {},
      AllMyNumber: {
        AllMyBorrow: 0,
        AllSupply: 0,
        AllBorrowLimit: 0,
      },
      types: {},
      EnableType: {},
      ModularData: {
        Tosupply: 0,
        Toborrow: 0,
        supplies: 0,
        Earned: 0,
        Apy: 0,
        Borrow: 0,
        Factor: 0,
        BorrowLimit: 0,
      },
      CollateralData: [],
      showSupplyModal: false,
      showBorrowModal: false,
      showWithdrawModal: false,
      showRepayModal: false,
      showSwitchNetwork: false,
      showEnableToken: false,
      showTokenInfo: false,
    };
  },
  mounted() {},
  created() {
    this.UserInfo();
    if (this.Numberautoplay.includes('0')) {
      this.autoplayf = 1;
      setTimeout(() => {
        this.autoplayf = 1000;
      }, 10000);
    } else {
      this.autoplayf = 1000;
      this.$store.dispatch('user/setNumberautoplay', this.Numberautoplay + '0');
    }
    this.checkedChainList = this.networkList.map((it) => it.id);
    console.log('this.borrowInfo :>> ', this.borrowInfo);
  },
  watch: {
    account(news) {
      if (news !== '') {
        this.UserInfo();
      } else {
        this.showDashboard = false;
        this.CollateralData = [
          {
            Toborrow: 0,
            Tosupply: 0,
            isShow: true,
            icon: '',
            TokenName: 0,
            OraclePrice: 0,
            LTV: 0,
            CollateralToken: 1,
            Collateral: 0,
            EarnedToken: 0,
            Earned: 0,
            SupplyApy: 0,
            Liquidation: 0,
            BorrowedToken: 1,
            Borrowed: 0,
            PayableToken: 0,
            Payable: 0,
            BorrowApy: 0,
          },
          {
            Toborrow: 0,
            Tosupply: 0,
            isShow: true,
            icon: '',
            TokenName: 0,
            OraclePrice: 0,
            LTV: 0,
            CollateralToken: 1,
            Collateral: 0,
            EarnedToken: 0,
            Earned: 0,
            SupplyApy: 0,
            Liquidation: 0,
            BorrowedToken: 1,
            Borrowed: 0,
            PayableToken: 0,
            Payable: 0,
            BorrowApy: 0,
          },
        ];
      }
    },

    borrowInfo(values) {
      if (values) {
        this.UserInfo();
      } else {
        this.CollateralData = [
          {
            Toborrow: 0,
            Tosupply: 0,
            isShow: true,
            icon: '',
            TokenName: 0,
            OraclePrice: 0,
            LTV: 0,
            CollateralToken: 1,
            Collateral: 0,
            EarnedToken: 0,
            Earned: 0,
            SupplyApy: 0,
            Liquidation: 0,
            BorrowedToken: 1,
            Borrowed: 0,
            PayableToken: 0,
            Payable: 0,
            BorrowApy: 0,
          },
          {
            Toborrow: 0,
            Tosupply: 0,
            isShow: true,
            icon: '',
            TokenName: 0,
            OraclePrice: 0,
            LTV: 0,
            CollateralToken: 1,
            Collateral: 0,
            EarnedToken: 0,
            Earned: 0,
            SupplyApy: 0,
            Liquidation: 0,
            BorrowedToken: 1,
            Borrowed: 0,
            PayableToken: 0,
            Payable: 0,
            BorrowApy: 0,
          },
        ];
      }
    },

    checkedChainList() {
      this.UserInfo();
    },
  },
  methods: {
    UserInfo() {
      this.isPageLoading = true;
      let list = this.borrowInfo?.info ?? [];
      if (this.borrowInfo?.info) {
        this.ModularData.Factor = this.borrowInfo.healthFactor.factor * 100;
        this.AllMyNumber.AllSupply =
          this.borrowInfo.healthFactor.collateralValue;
        this.AllMyNumber.AllMyBorrow = this.borrowInfo.healthFactor.debtValue;
        this.ModularData.supplies =
          this.borrowInfo.healthFactor.collateralValue;
        this.ModularData.Borrow = this.borrowInfo.healthFactor.debtValue;
        let Risk = list[0].LTV;
        this.AllMyNumber.AllBorrowLimit =
          this.AllMyNumber.AllSupply * Risk - this.AllMyNumber.AllMyBorrow;
        this.ModularData.BorrowLimit =
          this.AllMyNumber.AllSupply * Risk - this.AllMyNumber.AllMyBorrow;
        this.CollateralData = list.map((item) => {
          this.isPageLoading = false;
          return {
            chain: this.getChainInfo(item.symbol),
            ceScaled: item.ceScaled,
            dtScaled: item.dtScaled,
            increasedDebt: item.increasedDebt,
            loan: item.loan,
            Toborrow: item.totalBorrow,
            Tosupply: item.totalSupply,
            liquidationPrice: item.liquidationPrice,
            name: item.name,
            withdrawable: item.withdrawable,
            myBorrow: +item.myBorrow,
            LTS: item.LTS,
            LTV: item.LTV,
            icon: require(`../../../assets/image/icon/${item.symbol}.svg`),
            TokenName: item.symbol,
            OraclePrice: item.price,
            CollateralToken: item.mySupply,
            Collateral: item.mySupply * +item.price,
            EarnedToken: item.interestEarn,
            Earned: item.interestEarn * +item.price,
            SupplyApy: item.supplyApy * 100,
            Liquidation: 0,
            BorrowedToken: item.myBorrow,
            Borrowed: item.myBorrow * +item.price,
            PayableToken: item.interestPay,
            Payable: item.interestPay * +item.price,
            BorrowApy: +item.borrowApy * 100,
          };
        });

        this.CollateralData = this.CollateralData.filter((item) => {
          return this.checkedChainList.includes(item.chain.id);
        });
        console.log('🚀 ~ list:', list);
        this.ModularData.Earned = 0;
        this.ModularData.Apy = 0;
        this.ModularData.Toborrow = 0;
        this.ModularData.Tosupply = 0;
        for (let i = 0; i < this.CollateralData.length; i++) {
          this.ModularData.Toborrow +=
            +this.CollateralData[i].Toborrow *
            this.CollateralData[i].OraclePrice;
          this.ModularData.Tosupply +=
            +this.CollateralData[i].Tosupply *
            this.CollateralData[i].OraclePrice;
          this.ModularData.Earned +=
            this.CollateralData[i].EarnedToken *
            this.CollateralData[i].OraclePrice;
          this.ModularData.Apy +=
            this.CollateralData[i].SupplyApy *
              this.CollateralData[i].Collateral -
            this.CollateralData[i].BorrowApy * this.CollateralData[i].Borrowed;
        }
        if (this.ModularData.Apy > 0) {
          this.ModularData.Apy =
            ((this.ModularData.Apy / this.AllMyNumber.AllSupply) * 100) / 100;
        } else {
          this.ModularData.Apy =
            ((this.ModularData.Apy / this.AllMyNumber.AllMyBorrow) * 100) / 100;
        }
        this.ModularData.Tosupply = this.toFixedNumber(
          this.ModularData.Tosupply,
          2
        );
        this.ModularData.Toborrow = this.toFixedNumber(
          this.ModularData.Toborrow,
          2
        );
        this.ModularData.Earned = this.toFixedNumber(
          this.ModularData.Earned,
          2
        );
        this.ModularData.supplies = this.toFixedNumber(
          this.ModularData.supplies,
          2
        );
        this.ModularData.Borrow = this.toFixedNumber(
          this.ModularData.Borrow,
          2
        );
        this.ModularData.Apy = this.toFixedCutZero(this.ModularData.Apy, 2);
        if (this.ModularData.Earned <= 0) {
          this.ModularData.Earned = 0;
        }
        this.showCollateralList =
          this.CollateralData.filter((b) => b.CollateralToken > 0).length > 0;
        this.showBorrowList =
          this.CollateralData.filter((b) => b.BorrowedToken > 0).length > 0;
      }
    },
    async login() {
      this.showSignin = !this.showSignin;
    },
    openMarket() {
      this.$router.push('/Market');
    },
    Facto() {
      if (this.ModularData.Factor < 33) {
        return {
          '0%': '#6CF78B',
          '50%': '#6CF78B',
        };
      } else if (this.ModularData.Factor < 66) {
        return {
          '0%': '#6CF78B',
          '50%': '#FFCF86',
        };
      } else {
        return {
          '0%': '#6CF78B',
          '50%': '#FFCF86',
          '100%': '#FF3F62',
        };
      }
    },
    openSupplyModal(item) {
      if (this.showTokenInfo) {
        this.showTokenInfo = false;
      }
      if (this.chainid === item.chain.id) {
        this.getWalletBalance(item);
        this.selectedItem = item;
        this.showSupplyModal = true;
      } else {
        const rootChainInfo = { id: item.chain.id };
        this.openSwitchNetwork(rootChainInfo);
      }
    },
    openBorrowModal(item) {
      if (this.showTokenInfo) {
        this.showTokenInfo = false;
      }
      if (this.chainid === 97) {
        this.getWalletBalance(item);
        this.selectedItem = item;
        this.showBorrowModal = true;
      } else {
        const rootChainInfo = { id: 97 };
        this.openSwitchNetwork(rootChainInfo);
      }
    },
    openWithdrawModal(item) {
      if (this.chainid === 97) {
        this.getWalletBalance(item);
        this.selectedItem = item;
        this.showWithdrawModal = true;
      } else {
        const rootChainInfo = { id: 97 };
        this.openSwitchNetwork(rootChainInfo);
      }
    },
    openRepayModal(item) {
      if (this.chainid === item.chain.id) {
        this.getWalletBalance(item);
        this.selectedItem = item;
        this.showRepayModal = true;
      } else {
        const rootChainInfo = { id: item.chain.id };
        this.openSwitchNetwork(rootChainInfo);
      }
    },
    openSwitchNetwork(item) {
      this.switchId = item.id;
      this.showSwitchNetwork = !this.showSwitchNetwork;
    },
    openEnableToken() {
      this.showEnableToken = true;
    },
    openTokenInfo(item) {
      this.selectedItem = item;
      this.showTokenInfo = true;
    },
    setShowModal(value) {
      this.showSignin = value;
      this.showSupplyModal = value;
      this.showBorrowModal = value;
      this.showWithdrawModal = value;
      this.showRepayModal = value;
      this.showSwitchNetwork = value;
      this.showEnableToken = value;
      this.showTokenInfo = value;
    },
    getWalletBalance(item) {
      let balance = getPoolList();
      let b = '';
      for (let i = 0; i < balance.length; i++) {
        if (
          this.chainid === balance[i].chainid &&
          item.TokenName === balance[i].symbol
        ) {
          this.types = balance[i];
          b = balance[i];
          break;
        }
      }
      if (b === '') {
        b = balance.find((it) => it.symbol === item.TokenName);
        this.types = b;
        this.showSwitch = !this.showSwitch;
        this.switchid = b.chainid;
        this.ismoneytype = 2;
        this.switchnetwork = b.chain;
      } else {
        checkTransactionStatus(this.types.id).then((type) => {
          if (type.code === 0) {
            this.$message.warn('Spending limit being set, please wait..');
          } else if (type.code === 1) {
            getBalance(this.types.id).then((a) => {
              if (+a.allowance <= 0) {
                this.EnableType = a;
                this.openEnableToken();
              } else {
                this.tokenId = this.types.id;
                this.walletBalance = +a.balance;
              }
            });
          }
        });
      }
    },
    getChainInfo(tokenSymbol) {
      const token = tokens.find((item) => item.symbol === tokenSymbol);
      return {
        id: token.chainid,
        name: token.chain,
      };
    },
    HistoryF() {
      this.$router.push('/Market/History');
      this.$bus.emit('changeInfo', 1);
    },
  },
  destroyed() {
    clearInterval(this.settime);
  },
};
</script>

<style scoped lang="scss">
.dashboard {
  position: relative;
  .dashboard-top {
    margin: 40px 80px;
    .dashboard-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;

      .name {
        font-size: 26px;
        font-weight: 700;
        line-height: 130%;
      }

      .global-market {
        display: flex;
        align-items: center;
        gap: 35px;
        .stat {
          display: flex;
          align-items: center;
          gap: 16px;
          .title {
            font-size: 15px;
            font-weight: 700;
            line-height: 100%;
            color: #ffffffcc;
          }
          .value {
            font-size: 15px;
            font-weight: 900;
            line-height: 16px;
            display: flex;
            align-items: center;
            gap: 16px;
          }
        }
      }
      &.border-bottom {
        padding-bottom: 25px;
        border-bottom: 1px solid #ffffff24;
      }
    }
    .dashboard-top-info {
      display: flex;
      margin-top: 32px;
      .block {
        width: 50%;
        .title {
          font-size: 18px;
          font-weight: 900;
          line-height: 24px; /* 133.333% */
          letter-spacing: -0.18px;
          color: #ffffff99;
        }
        .p2 {
          margin-top: 16px;
          display: flex;
          // justify-content: space-between;

          &-left {
            .value {
              font-size: 46px;
              font-weight: 500;
              line-height: normal;
              color: #ffffff;
              margin-bottom: 10px;
            }
          }
          &-right {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            gap: 30px;
            &-item {
              &-title {
                color: #ffffff99;
                font-size: 15px;
                font-weight: 700;
                line-height: 16px; /* 106.667% */
                margin-bottom: 10px;
                display: flex;
                gap: 10px;
                align-items: center;
              }
              &-value {
                color: #ffffff;
                font-size: 20px;
                font-weight: 900;
                line-height: normal;
              }
            }
          }
        }

        .health-factor {
          display: flex;
          align-items: start;
          justify-content: space-between;
          gap: 20px;

          ::v-deep .ant-progress-text {
            color: #ffffff;
            font-size: 20px;
            font-weight: 500;
            line-height: 100%;
          }
        }
      }
    }
  }

  .dashboard-null {
    background-image: url('../../../assets/image/dashboard/dashboard-bg.png');
    margin-top: 16px;
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 24px 24px 0 0;

    .dashboard-wallet-connect {
      top: 180px;
      left: 180px;
      position: relative;
      width: fit-content;

      .p1 {
        font-weight: 900;
        font-size: 32px;
        line-height: 36px;
        color: #141414;
      }

      .p2 {
        margin-top: 12px;
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: rgba(20, 20, 20, 0.5);
        width: 405.542px;
      }
    }
  }
  .dashboard-main {
    background-color: #faf7ff;
    border-radius: 24px 24px 0px 0px;
    padding: 54px 84px;

    ::v-deep .ant-tabs-bar {
      border-bottom: none;
      margin: 0;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;

      .ant-tabs-extra-content {
        float: none !important;
        flex-grow: 1;
        .tab-right-content {
          margin-left: 150px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          // gap: 84px;

          .stats {
            background: #ffffff;
            padding: 6px;
            padding-left: 18px;
            display: flex;
            align-items: center;
            gap: 18px;
            border-radius: 57px;

            .title {
              font-size: 15px;
              font-weight: 700;
              line-height: 100%;
              color: #14141480;
            }
            .value {
              font-size: 15px;
              font-weight: 900;
              line-height: 16px;
              color: #141414;
            }
          }
          .actions {
            display: flex;
            align-items: center;
            gap: 15px;
            .btn-secondary {
              font-size: 15px;
              font-weight: 700;
              line-height: 100%;
              text-align: center;
              border-radius: 48px;
              display: flex;
              gap: 6px;
              align-items: center;
              transition: all 0.1s linear;
              padding: 14px 24px;
              cursor: pointer;
              height: fit-content;
              border: 2px solid #141414;
              color: #141414;
              background: none;
            }
            .dropdown-toggler-btn {
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              transition: all 0.1s linear;
              // color: #141414;
              // background-color: transparent;
              // border: 1px solid #141414;
              .selected-chain-count {
                background-color: #aa68f0;
                border-radius: 100%;
                padding: 2px 6px;
                color: #ffffff;
                font-weight: 400;
              }
            }

            .dropdown-bg {
              position: absolute;
              right: 0;
              margin-top: 10px;
              padding: 6px;
              width: 250px;
              transform-origin: top right;
              background-color: rgba(255, 255, 255, 1);
              box-shadow: 0px 0px 0px 0px rgba(173, 165, 217, 0.1),
                1px 6px 12px 0px rgba(173, 165, 217, 0.1),
                4px 22px 23px 0px rgba(173, 165, 217, 0.09),
                9px 50px 31px 0px rgba(173, 165, 217, 0.05),
                17px 89px 36px 0px rgba(173, 165, 217, 0.01),
                26px 139px 40px 0px rgba(173, 165, 217, 0);
              backdrop-filter: blur(27.5px);
              border-radius: 14px;
              z-index: 999;
            }

            .chain-list-item {
              display: flex;
              align-items: center;
              gap: 12px;
              margin: 10px 0px;
              padding: 5px;
              border-radius: 12px;
              line-height: 100%;

              &:hover {
                background-color: #ffffff;
              }
            }
          }
        }
      }
      .tab-header {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .ant-tabs-tab,
      .ant-tabs-tab-active {
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        color: var(--tabheadercolor);
        border: none;
        background-color: transparent;
        padding: 0px;

        .tab-header-icon {
          display: none;
        }
      }

      .ant-tabs-tab-active {
        color: var(--tabheaderactivecolor);
        .tab-header-icon {
          display: block;
          &-inactive {
            display: none;
          }
        }
      }
    }

    .dashboard-table {
      .table-list-row {
        display: flex;
        align-items: center;
        gap: 2px;
        justify-content: space-between;
        padding: 16px;

        &:hover {
          border-radius: 20px;
          background-color: var(--tablehoverbg);
        }

        .table-list-item {
          &.assets {
            display: flex;
            align-items: center;
            gap: 12px;
            .name {
              color: var(--tabletext);
              font-size: 18px;
              font-weight: 700;
              line-height: 100%; /* 18px */
              letter-spacing: -0.18px;
              text-align: left;
            }
          }
          &.chain {
            color: var(--tabletext);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 133.333% */
            letter-spacing: -0.18px;
            text-align: center;
            opacity: 0.8;
          }

          &.oracle-price,
          &.liquidation-price,
          &.ltv,
          &.apy {
            color: var(--tabletext);
            font-size: 18px;
            font-weight: 500;
            line-height: 100%; /* 18px */
            letter-spacing: -0.18px;
            text-align: right;
          }

          &.my-borrowed,
          &.my-collateral,
          &.interest {
            text-align: right;
            .token {
              color: var(--tabletext);
              font-size: 18px;
              font-weight: 500;
              line-height: 24px; /* 133.333% */
              letter-spacing: -0.18px;
            }
            .dollar {
              color: var(--tabletext);
              font-size: 14px;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.28px;
              opacity: 0.5;
            }
          }

          &.actions {
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: flex-end;
          }

          &:nth-child(1) {
            width: 11%;
          }
          &:nth-child(2) {
            width: 12%;
            text-align: center;
          }
          &:nth-child(3) {
            width: 8%;
            text-align: right;
          }
          &:nth-child(4) {
            width: 12%;
            text-align: right;
          }
          &:nth-child(5) {
            width: 12%;
            text-align: right;
          }
          &:nth-child(6) {
            width: 8%;
            text-align: right;
          }
          &:nth-child(7) {
            width: 12%;
            text-align: right;
          }
          &:nth-child(8) {
            flex-grow: 1;
            text-align: center;
          }
        }
      }

      ::v-deep.list-header {
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;
        margin-top: 30px;
        border-radius: 20px;
        color: var(--tableheadertext);
        background: var(--tableheaderbg);
        box-shadow: 0px 0px 0px 0px rgba(217, 214, 255, 0.05),
          0px 5px 11px 0px rgba(217, 214, 255, 0.05),
          0px 19px 19px 0px rgba(217, 214, 255, 0.04),
          0px 44px 26px 0px rgba(217, 214, 255, 0.03),
          0px 78px 31px 0px rgba(217, 214, 255, 0.01),
          0px 121px 34px 0px rgba(217, 214, 255, 0);
      }
      .list-header {
        .table-list-row {
          padding: 30px 16px;
        }
      }

      .list-body {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.18px;
        color: var(--tableheadertext);
        min-height: 40vh;
      }

      .empty-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding: 24px;
        margin: 64px auto;
        border-radius: 20px;
        background-color: var(--tableheaderbg);
        width: fit-content;
        color: var(--tableheadertext);

        h2 {
          color: var(--tableheadertext);
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
        }
        p {
          display: flex;
          align-items: center;
          padding: 0 32px;

          .chips {
            margin: 0 6px;
          }
        }
      }
    }
  }

  &.dashboard-dark {
    .dashboard-top {
      .dashboard-title {
        &.border-bottom {
          padding-bottom: 25px;
          border-bottom: 1px solid #e9eaf224;
        }
      }
    }
    .dashboard-null {
      background-image: url('../../../assets/image/dashboard/dashboard-bg-dark.png');
      .dashboard-wallet-connect {
        .p1 {
          color: white;
        }

        .p2 {
          color: white;
        }
      }
    }
    .dashboard-main {
      background-color: #1a1b1f;
    }
  }
}

.tooltip.ant-tooltip.ant-tooltip-placement-top {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: #ffffff;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding: 8px 16px;
      border-radius: 8px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
