<template>
  <div class="market">
    <div class="market-top">
      <div class="market-top-info">
        <div class="market-top-info-section">
          <div class="header">
            <div class="title">Total Supply</div>
            <ChipsRate :rate="5" />
          </div>
          <div class="value">
            $
            <countTo
              :startVal="0"
              :endVal="+supplies"
              :duration="autoplayf"
              :decimals="2"
            ></countTo>
          </div>
          <!-- TODO Add Graph -->
          <div class="graph"></div>
        </div>
        <div class="market-top-info-section">
          <div class="header">
            <div class="title">Total Borrow</div>
            <ChipsRate :rate="-2" />
          </div>
          <div class="value">
            $
            <countTo
              :startVal="0"
              :endVal="+borrow"
              :duration="autoplayf"
              :decimals="2"
            ></countTo>
          </div>
          <!-- TODO Add Graph -->
          <div class="graph"></div>
        </div>
      </div>
    </div>

    <div class="market-main">
      <div class="main-header">
        <div class="title">
          <img
            src="../../../assets/image/icon/new/market-dark.svg"
            alt=""
            width="30px"
            height="30px"
            class="tab-header-icon"
            v-if="themetype"
          />
          <img
            src="../../../assets/image/icon/new/market.svg"
            alt=""
            width="30px"
            height="30px"
            class="tab-header-icon"
            v-else
          />
          Market
        </div>
        <div class="filter">
          <Dropdown>
            <template slot="toggler">
              <div class="btn-secondary">
                <div class="dropdown-toggler-btn">
                  <div>
                    <div v-if="checkedChainList.length === networkList.length">
                      All Chains
                    </div>

                    <div v-else>
                      Selected Chains
                      <span class="selected-chain-count">{{
                        checkedChainList.length
                      }}</span>
                    </div>
                  </div>
                  <img
                    src="../../../assets/image/icon/new/arrow-down-white.svg"
                    width="20px"
                    height="20px"
                    v-if="themetype"
                    alt="All Chains"
                  />
                  <img
                    src="../../../assets/image/icon/new/arrow-down.svg"
                    width="20px"
                    height="20px"
                    v-else
                    alt="All Chains"
                  />
                </div>
              </div>
            </template>
            <DropdownContent>
              <div>
                <img
                  src="../../../assets/image/icon/new/filter.svg"
                  alt=""
                  width="20px"
                  height="20px"
                />
                Chain Filter
              </div>

              <DropdownItem
                v-for="(item, index) in networkList"
                :key="index"
                :handleClick="() => null"
              >
                <div class="chain-list-item">
                  <input
                    type="checkbox"
                    :value="item.id"
                    v-model="checkedChainList"
                    :id="item.id"
                  />
                  <img :src="item.icon" width="20px" height="20px" alt="" />
                  <div>{{ item.name }}</div>
                </div>
              </DropdownItem>
            </DropdownContent>
          </Dropdown>
        </div>
      </div>

      <div class="market-table">
        <div class="list-header">
          <div class="table-list-row">
            <div class="table-list-item w1">Assets</div>
            <div class="table-list-item w2">Chain</div>
            <div class="table-list-item w3">Total Supply</div>
            <div class="table-list-item w4">Supply APY</div>
            <div class="table-list-item w5">Total Borrow</div>
            <div class="table-list-item w6">Borrow APY</div>
            <div class="table-list-item w7">
              <div class="n1">Actions</div>
              <div class="nulldiv"></div>
            </div>
          </div>
        </div>
        <div class="list-body">
          <!-- <div class="table-list-row">
            <div class="table-list-item assets">
              <div class="icon">
                <img
                  src="../../../assets/image/icon/new/chain/eth.svg"
                  alt=""
                  width="30px"
                  height="30px"
                />
              </div>
              <div class="name">ETH</div>
            </div>
            <div class="table-list-item chain">Polygon</div>
            <div class="table-list-item total">
              <div class="token">900.3245 ETH</div>
              <div class="dollar">$400,000.00</div>
            </div>

            <div class="table-list-item apy">2.23%</div>
            <div class="table-list-item total">
              <div class="token">900.3245 ETH</div>
              <div class="dollar">$400,000.00</div>
            </div>

            <div class="table-list-item apy">2.23%</div>
            <div class="table-list-item actions">
              <Button :handleClick="showSupply">Supply</Button>
              <Button showIcon>Borrow</Button>
            </div>
          </div> -->
          <div class="spinner" v-if="isPageLoading">
            <img src="../../../assets/image/icon/new/spinner.svg" alt="" />
          </div>
          <div
            class="table-list-row"
            v-for="(item, index) in list2"
            :key="index"
            @click="() => openTokenInfo(item)"
            v-else
          >
            <div class="table-list-item assets">
              <div class="icon">
                <img :src="item.icon" alt="" width="30px" height="30px" />
              </div>
              <div class="name">{{ item.TokenName }}</div>
            </div>
            <div class="table-list-item chain">{{ item.chain.name }}</div>
            <div class="table-list-item total">
              <div class="token">
                {{ toFixedCutZero(item.TotalSupply, 2, true) }}
              </div>
              <div class="dollar">
                ${{ toFixedNumber(item.TotalSupplyMoney, 2, true) }}
              </div>
            </div>

            <div class="table-list-item apy">
              {{ toFixedCutZero(item.SupplyApy, 2, false) }}%
            </div>
            <div class="table-list-item total">
              <div class="token">
                {{ toFixedCutZero(item.TotalBorrow, 2, true) }}
              </div>
              <div class="dollar">
                ${{ toFixedNumber(item.TotalBorrowMoney, 2, true) }}
              </div>
            </div>

            <div class="table-list-item apy">
              {{ toFixedCutZero(item.BorrowApy, 2, false) }}%
            </div>
            <div class="table-list-item actions">
              <Button
                :handleClick="() => openSupplyModal(item)"
                :isLoading="isPageLoading"
                >Supply</Button
              >
              <Button
                :handleClick="() => openBorrowModal(item)"
                showIcon
                :isLoading="isPageLoading"
                >Borrow</Button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Supply
      v-if="showSupplyModal"
      :setShow="setShowModal"
      :item="selectedItem"
      :AllMyNumber="AllMyNumber"
      :walletBalance="walletBalance"
    />

    <Borrow
      v-if="showBorrowModal"
      :setShow="setShowModal"
      :item="selectedItem"
      :AllMyNumber="AllMyNumber"
      :walletBalance="walletBalance"
    />

    <Footer />
    <SwitchNetwork
      v-if="showSwitchNetwork"
      :setShow="setShowModal"
      :switchChainId="switchId"
    />
    <EnableToken
      v-if="showEnableToken"
      :setShow="setShowModal"
      :tokenInfo="EnableType"
    />
    <TokenInfo
      :setShow="setShowModal"
      v-if="showTokenInfo"
      :item="selectedItem"
      :openSupplyModal="openSupplyModal"
      :openBorrowModal="openBorrowModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getBalance,
  getPoolList,
  toFixedCutZero,
  toFixedNumber,
} from '../../../SDK';
import { checkTransactionStatus } from '../../../SDK/checkHash';

import countTo from 'vue-count-to';
import ChipsRate from '../../../components/new/ChipsRate/index.vue';
import Button from '../../../components/new/Btn/index.vue';
import Dropdown from '../../../components/new/Dropdown/index.vue';
import DropdownContent from '../../../components/new/Dropdown/DropdownContent.vue';
import DropdownItem from '../../../components/new/Dropdown/DropdownItem.vue';
import Supply from '../../../components/new/Supply';
import Borrow from '../../../components/new/Borrow';
import Footer from '../../../components/new/Footer';
import SwitchNetwork from '../../../components/new/SignIn/SwitchNetwork';
import EnableToken from '../../../components/new/SignIn/EnableToken';
import TokenInfo from '../../../components/new/TokenInfo';

export default {
  name: 'index',
  components: {
    Borrow,
    Button,
    ChipsRate,
    countTo,
    Dropdown,
    DropdownContent,
    DropdownItem,
    EnableToken,
    Footer,
    Supply,
    SwitchNetwork,
    TokenInfo,
  },
  data() {
    return {
      selectedItem: null,
      showSupplyModal: false,
      showBorrowModal: false,
      showSwitchNetwork: false,
      showEnableToken: false,
      showTokenInfo: false,
      switchId: 0,
      tokenId: 0,
      autoplayf: 1,
      isPageLoading: true,
      toFixedNumber: toFixedNumber,
      toFixedCutZero: toFixedCutZero,
      bottomRight: 'bottomRight',
      loading: true,
      Mobile: false,
      showlist: true,
      settime: null,
      iconHighlight3: true,
      supplies: 0,
      borrow: 0,
      EnableType: {},
      TypeOperation: {},
      walletBalance: 0,
      checkedChainList: [],
      AllMyNumber: {
        AllMyBorrow: 0,
        AllSupply: 0,
      },
      networkList: [
        {
          icon: require('../../../assets/image/icon/FTM.svg'),
          name: 'Fantom Testnet',
          id: 4002,
        },
        {
          icon: require('../../../assets/image/icon/AVAX.svg'),
          name: 'Avalanche Testnet',
          id: 43113,
        },
        {
          icon: require('../../../assets/image/icon/polygon.svg'),
          name: 'Polygon Mumbai',
          id: 80001,
        },
        {
          icon: require('../../../assets/image/icon/Arbitrum.svg'),
          name: 'Arbitrum Testnet',
          id: 421613,
        },
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          name: 'Goerli Testnet',
          id: 5,
        },
      ],
      networklist2: [],
      destroyedlist: {},

      list2: [
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'testUSDC',
          TotalSupply: 105.23,
          TotalSupplyMoney: 45.59,
          SupplyApy: 2.36,
          TotalBorrow: 105.23,
          TotalBorrowMoney: 42.53,
          BorrowApy: 2.85,
        },
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'testUSDC',
          TotalSupply: 105.23,
          TotalSupplyMoney: 45.59,
          SupplyApy: 2.36,
          TotalBorrow: 105.23,
          TotalBorrowMoney: 42.53,
          BorrowApy: 2.85,
        },
      ],
      tokenlist: [
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'testUSDC',
          TotalSupply: 105.23,
          TotalSupplyMoney: 45.59,
          SupplyApy: 2.36,
          TotalBorrow: 105.23,
          TotalBorrowMoney: 42.53,
          BorrowApy: 2.85,
        },
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'testUSDC',
          TotalSupply: 105.23,
          TotalSupplyMoney: 45.59,
          SupplyApy: 2.36,
          TotalBorrow: 105.23,
          TotalBorrowMoney: 42.53,
          BorrowApy: 2.85,
        },
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          TokenName: 'testUSDC',
          TotalSupply: 105.23,
          TotalSupplyMoney: 45.59,
          SupplyApy: 2.36,
          TotalBorrow: 105.23,
          TotalBorrowMoney: 42.53,
          BorrowApy: 2.85,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'account',
      'btnshow',
      'isLogin',
      'iswidth',
      'borrowInfo',
      'themetype',
      'chainid',
      'Numberautoplay',
      'equipment',
    ]),
  },

  methods: {
    MarketInfo() {
      this.isPageLoading = true;

      if (this.borrowInfo) {
        console.log('this.borrowInfo :>> ', this.borrowInfo);
        let list = this.borrowInfo?.info ?? [];
        if (list.length > 0) {
          this.tokenlist = list.map((item) => {
            this.showlist = true;
            this.isPageLoading = false;

            return {
              BorrowApy: +item.borrowApy * 100,
              Borrowed: item.myBorrow * +item.price,
              BorrowedToken: item.myBorrow,
              ceScaled: item.ceScaled,
              chain: this.getChainInfo(item.symbol),
              CollateralToken: item.mySupply,
              Collateral: item.mySupply * +item.price,
              dtScaled: item.dtScaled,
              Earned: item.interestEarn * +item.price,
              EarnedToken: item.interestEarn,
              enable: item.enable,
              icon: require(`../../../assets/image/icon/${item.symbol}.svg`),
              increasedDebt: item.increasedDebt,
              loan: item.loan,
              liquidationPrice: item.liquidationPrice,
              Liquidation: 0,
              LTS: item.LTS,
              LTV: item.LTV,
              myBorrow: +item.myBorrow,
              name: item.name,
              OraclePrice: item.price,
              PayableToken: item.interestPay,
              Payable: item.interestPay * +item.price,
              SupplyApy: item.supplyApy * 100,
              TokenName: item.symbol,
              TotalBorrow: item.totalBorrow,
              TotalBorrowMoney: item.totalBorrow * +item.price,
              TotalSupply: item.totalSupply,
              TotalSupplyMoney: item.totalSupply * +item.price,
              withdrawable: item.withdrawable,
            };
          });
          this.AllMyNumber.AllSupply =
            this.borrowInfo.healthFactor.collateralValue;
          this.AllMyNumber.AllMyBorrow = this.borrowInfo.healthFactor.debtValue;
        } else {
          this.showlist = false;
        }
      }
    },
    screenlist() {
      this.networklist2 = getPoolList().filter((value) =>
        this.checkedChainList.includes(value.chainid)
      );
      this.list2 = this.tokenlist.filter((value) => {
        return (
          this.networklist2.findIndex((it) => {
            return (
              it.symbol === value.TokenName &&
              this.checkedChainList.includes(it.chainid)
            );
          }) !== -1
        );
      });
      this.supplies = 0;
      this.borrow = 0;
      for (let i = 0; i < this.list2.length; i++) {
        this.supplies += this.list2[i].TotalSupplyMoney;
        this.borrow += this.list2[i].TotalBorrowMoney;
      }
      this.supplies = this.toFixedNumber(this.supplies, 2);
      this.borrow = this.toFixedNumber(this.borrow, 2);
    },
    getWalletBalance(item) {
      let balance = getPoolList();
      let b = '';
      for (let i = 0; i < balance.length; i++) {
        if (
          this.chainid === balance[i].chainid &&
          item.TokenName === balance[i].symbol
        ) {
          this.types = balance[i];
          b = balance[i];
          break;
        }
      }
      if (b === '') {
        b = balance.find((it) => it.symbol === item.TokenName);
        this.types = b;
        this.showSwitch = !this.showSwitch;
        this.switchid = b.chainid;
        this.ismoneytype = 2;
        this.switchnetwork = b.chain;
      } else {
        checkTransactionStatus(this.types.id).then((type) => {
          if (type.code === 0) {
            this.$message.warn('Spending limit being set, please wait..');
          } else if (type.code === 1) {
            getBalance(this.types.id).then((a) => {
              if (+a.allowance <= 0) {
                this.EnableType = a;
                this.openEnableToken(a.token);
              } else {
                this.tokenId = this.types.id;
                this.walletBalance = +a.balance;
              }
            });
          }
        });
      }
    },
    getChainInfo(tokenName) {
      const token = this.networklist2.find((it) => it.symbol === tokenName);
      if (token) {
        return {
          id: token.chainid,
          name: token.chain,
        };
      }
      return {};
    },

    openSupplyModal(item) {
      if (this.showTokenInfo) {
        this.showTokenInfo = false;
      }
      if (this.chainid === item.chain.id) {
        this.getWalletBalance(item);
        this.selectedItem = item;
        this.showSupplyModal = true;
      } else {
        const rootChainInfo = { id: item.chain.id };
        this.openSwitchNetwork(rootChainInfo);
      }
    },
    openBorrowModal(item) {
      if (this.showTokenInfo) {
        this.showTokenInfo = false;
      }
      if (this.chainid === 97) {
        this.getWalletBalance(item);
        this.selectedItem = item;
        this.showBorrowModal = true;
      } else {
        const rootChainInfo = { id: 97 };
        this.openSwitchNetwork(rootChainInfo);
      }
    },
    openSwitchNetwork(item) {
      console.log('item :>> ', item);
      this.switchId = item.id;
      this.showSwitchNetwork = true;
    },
    openEnableToken(item) {
      console.log('item :>> ', item);
      this.tokenId = item.id;
      this.showEnableToken = true;
    },
    openTokenInfo(item) {
      console.log('item :>> ', item);
      this.selectedItem = item;
      this.showTokenInfo = true;
    },

    setShowModal(value) {
      this.showSupplyModal = value;
      this.showBorrowModal = value;
      this.showSwitchNetwork = value;
      this.showEnableToken = value;
      this.showTokenInfo = value;
    },
    themetypeF() {
      if (this.themetype) {
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdownbg', `#27202E`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdowncolor', `#FFFFFF`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdowncolorhover', `#AA68F0`);
      } else {
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdownbg', `#ffffff`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdowncolor', `#000000`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdowncolorhover', `#AA68F0`);
      }
    },
  },
  created() {
    if (this.Numberautoplay.includes('1')) {
      this.autoplayf = 1;
      setTimeout(() => {
        this.autoplayf = 1000;
      }, 10000);
    } else {
      this.autoplayf = 1000;
      this.$store.dispatch('user/setNumberautoplay', this.Numberautoplay + '1');
    }

    this.isPageLoading = true;
    this.checkedChainList = this.networkList.map((it) => it.id);
    this.networklist2 = getPoolList().filter((value) =>
      this.checkedChainList.includes(value.chainid)
    );
    this.MarketInfo();
    this.screenlist();
    localStorage.removeItem('selectChainIndex');
  },
  watch: {
    account(news) {
      if (news !== '') {
        this.MarketInfo();
        this.screenlist();
      } else {
        this.isPageLoading = true;
        (this.supplies = 0),
          (this.borrow = 0),
          (this.list2 = [
            {
              icon: require('../../../assets/image/icon/ETHicon.svg'),
              TokenName: 'testUSDC',
              TotalSupply: 105.23,
              TotalSupplyMoney: 45.59,
              SupplyApy: 2.36,
              TotalBorrow: 105.23,
              TotalBorrowMoney: 42.53,
              BorrowApy: 2.85,
            },
            {
              icon: require('../../../assets/image/icon/ETHicon.svg'),
              TokenName: 'testUSDC',
              TotalSupply: 105.23,
              TotalSupplyMoney: 45.59,
              SupplyApy: 2.36,
              TotalBorrow: 105.23,
              TotalBorrowMoney: 42.53,
              BorrowApy: 2.85,
            },
            {
              icon: require('../../../assets/image/icon/ETHicon.svg'),
              TokenName: 'testUSDC',
              TotalSupply: 105.23,
              TotalSupplyMoney: 45.59,
              SupplyApy: 2.36,
              TotalBorrow: 105.23,
              TotalBorrowMoney: 42.53,
              BorrowApy: 2.85,
            },
          ]);
        this.tokenlist = [
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'testUSDC',
            TotalSupply: 105.23,
            TotalSupplyMoney: 45.59,
            SupplyApy: 2.36,
            TotalBorrow: 105.23,
            TotalBorrowMoney: 42.53,
            BorrowApy: 2.85,
          },
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'testUSDC',
            TotalSupply: 105.23,
            TotalSupplyMoney: 45.59,
            SupplyApy: 2.36,
            TotalBorrow: 105.23,
            TotalBorrowMoney: 42.53,
            BorrowApy: 2.85,
          },
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'testUSDC',
            TotalSupply: 105.23,
            TotalSupplyMoney: 45.59,
            SupplyApy: 2.36,
            TotalBorrow: 105.23,
            TotalBorrowMoney: 42.53,
            BorrowApy: 2.85,
          },
        ];
        this.screenlist();
      }
    },

    chainid() {
      this.screenlist();
    },
    borrowInfo(values) {
      if (values) {
        this.MarketInfo();
        this.screenlist();
      } else {
        this.isPageLoading = true;
        this.list2 = [
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'testUSDC',
            TotalSupply: 105.23,
            TotalSupplyMoney: 45.59,
            SupplyApy: 2.36,
            TotalBorrow: 105.23,
            TotalBorrowMoney: 42.53,
            BorrowApy: 2.85,
          },
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'testUSDC',
            TotalSupply: 105.23,
            TotalSupplyMoney: 45.59,
            SupplyApy: 2.36,
            TotalBorrow: 105.23,
            TotalBorrowMoney: 42.53,
            BorrowApy: 2.85,
          },
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'testUSDC',
            TotalSupply: 105.23,
            TotalSupplyMoney: 45.59,
            SupplyApy: 2.36,
            TotalBorrow: 105.23,
            TotalBorrowMoney: 42.53,
            BorrowApy: 2.85,
          },
        ];
        this.tokenlist = [
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'testUSDC',
            TotalSupply: 105.23,
            TotalSupplyMoney: 45.59,
            SupplyApy: 2.36,
            TotalBorrow: 105.23,
            TotalBorrowMoney: 42.53,
            BorrowApy: 2.85,
          },
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'testUSDC',
            TotalSupply: 105.23,
            TotalSupplyMoney: 45.59,
            SupplyApy: 2.36,
            TotalBorrow: 105.23,
            TotalBorrowMoney: 42.53,
            BorrowApy: 2.85,
          },
          {
            icon: require('../../../assets/image/icon/ETHicon.svg'),
            TokenName: 'testUSDC',
            TotalSupply: 105.23,
            TotalSupplyMoney: 45.59,
            SupplyApy: 2.36,
            TotalBorrow: 105.23,
            TotalBorrowMoney: 42.53,
            BorrowApy: 2.85,
          },
        ];
      }
    },
    themetype() {
      this.themetypeF();
    },
    checkedChainList() {
      this.screenlist();
    },
  },
};
</script>

<style scoped lang="scss">
.market {
  .market-top {
    margin: 40px 80px;
    .market-top-info {
      display: flex;
      flex-wrap: wrap;
      .market-top-info-section {
        flex: 0 0 50%;
        .header {
          display: flex;
          align-items: center;
          gap: 16px;

          .title {
            font-size: 18px;
            font-weight: 900;
            letter-spacing: -0.18px;
            line-height: 24px;
            color: #ffffff99;
            margin-bottom: 8px;
          }
        }
        .value {
          font-size: 46px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -2px;
          color: #ffffff;
        }
        // .graph {
        // }
      }
    }
  }

  .market-main {
    background-color: #faf7ff;
    border-radius: 24px 24px 0px 0px;
    padding: 54px 84px 100px;

    .main-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        color: #141414;
      }

      .filter {
        .btn-secondary {
          font-size: 15px;
          font-weight: 700;
          line-height: 100%;
          text-align: center;
          border-radius: 48px;
          display: flex;
          gap: 6px;
          align-items: center;
          transition: all 0.1s linear;
          padding: 14px 24px;
          cursor: pointer;
          height: fit-content;
          border: 2px solid #141414;
          color: #141414;
          background: none;
        }
        .dropdown-toggler-btn {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          transition: all 0.1s linear;
          // color: #141414;
          // background-color: transparent;
          // border: 1px solid #141414;

          .selected-chain-count {
            background-color: #aa68f0;
            border-radius: 100%;
            padding: 2px 6px;
            color: #ffffff;
            font-weight: 400;
          }
        }

        .dropdown-bg {
          position: absolute;
          right: 0;
          margin-top: 10px;
          padding: 6px;
          width: 250px;
          transform-origin: top right;
          background-color: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 0px 0px 0px rgba(173, 165, 217, 0.1),
            1px 6px 12px 0px rgba(173, 165, 217, 0.1),
            4px 22px 23px 0px rgba(173, 165, 217, 0.09),
            9px 50px 31px 0px rgba(173, 165, 217, 0.05),
            17px 89px 36px 0px rgba(173, 165, 217, 0.01),
            26px 139px 40px 0px rgba(173, 165, 217, 0);
          backdrop-filter: blur(27.5px);
          border-radius: 14px;
          z-index: 999;
        }

        .chain-list-item {
          display: flex;
          align-items: center;
          gap: 12px;
          margin: 10px 0px;
          padding: 5px;
          border-radius: 12px;
          line-height: 100%;

          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }
    .market-table {
      .table-list-row {
        display: flex;
        align-items: center;
        gap: 2px;
        justify-content: space-between;
        padding: 16px;

        &:hover {
          border-radius: 20px;
          background-color: var(--tablehoverbg);
        }

        .table-list-item {
          &.assets {
            display: flex;
            align-items: center;
            gap: 12px;
            .name {
              color: var(--tabletext);
              font-size: 18px;
              font-weight: 700;
              line-height: 100%; /* 18px */
              letter-spacing: -0.18px;
              text-align: left;
            }
          }
          &.chain {
            color: var(--tabletext);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 133.333% */
            letter-spacing: -0.18px;
            text-align: center;
            opacity: 0.8;
          }

          &.apy {
            color: var(--tabletext);
            font-size: 18px;
            font-weight: 500;
            line-height: 100%; /* 18px */
            letter-spacing: -0.18px;
            text-align: right;
          }

          &.total {
            text-align: right;
            .token {
              color: var(--tabletext);
              font-size: 18px;
              font-weight: 500;
              line-height: 24px; /* 133.333% */
              letter-spacing: -0.18px;
            }
            .dollar {
              color: var(--tabletext);
              font-size: 14px;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.28px;
              opacity: 0.5;
            }
          }

          &.actions {
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: flex-end;
          }

          &:nth-child(1) {
            width: 150px;
            margin-right: 28px;
          }
          &:nth-child(2) {
            width: 80px;
            text-align: center;
            margin-right: 28px;
          }
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 150px;
            text-align: right;
            margin-right: 28px;
          }

          &:nth-child(7) {
            flex-grow: 1;
            text-align: center;
          }
        }
      }

      ::v-deep.list-header {
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;
        margin-top: 30px;
        border-radius: 20px;
        color: var(--tableheadertext);
        background: var(--tableheaderbg);
        box-shadow: 0px 0px 0px 0px rgba(217, 214, 255, 0.05),
          0px 5px 11px 0px rgba(217, 214, 255, 0.05),
          0px 19px 19px 0px rgba(217, 214, 255, 0.04),
          0px 44px 26px 0px rgba(217, 214, 255, 0.03),
          0px 78px 31px 0px rgba(217, 214, 255, 0.01),
          0px 121px 34px 0px rgba(217, 214, 255, 0);
      }
      .list-header {
        .table-list-row {
          padding: 30px 16px;
        }
      }

      .list-body {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.18px;
        color: var(--tableheadertext);
      }
    }
  }
}
</style>
