<template>
  <div
    :class="themetype === true ? 'Details-dark' : 'Details'"
    v-if="equipment === 0"
  >
    <div class="Detalis-main">
      <div class="title">
        <div class="back" @click="backbtn()">
          <div
            class="btn"
            @mouseenter="iconHighlight = false"
            @mouseleave="iconHighlight = true"
            v-if="themetype"
          >
            <img
              src="../../../assets/image/dark/detail-back3.svg"
              v-if="iconHighlight"
            />
            <img src="../../../assets/image/icon/detail-back2.svg" v-else />
            <div class="b1" v-if="iconHighlight">Back</div>
            <div class="b2" v-else>Back</div>
          </div>
          <div
            class="btn"
            @mouseenter="iconHighlight = false"
            @mouseleave="iconHighlight = true"
            v-else
          >
            <img
              src="../../../assets/image/icon/detail-back.svg"
              v-if="iconHighlight"
            />
            <img src="../../../assets/image/icon/detail-back2.svg" v-else />
            <div class="b1" v-if="iconHighlight">Back</div>
            <div class="b2" v-else>Back</div>
          </div>
        </div>
        <div class="network" v-if="loadingpage">
          <div class="l1 load1"></div>
        </div>
        <div class="network" v-else>
          <img :src="icon(title.NetWorkIcon)" />
          <div>{{ title.NetWork }}</div>
        </div>
      </div>
      <div class="title2">
        <div class="d2" v-if="loadingpage">
          <div class="l1 load1"></div>
        </div>
        <div class="d2" v-else>
          <img :src="icon(title.TokenIcon)" />
          <div>
            <div>{{ title.TokenName }}</div>
            <div>{{ title.TokenName2 }}</div>
          </div>
        </div>
        <div class="d1" v-if="loadingpage">
          <div class="l1 load1"></div>
        </div>
        <div class="d1" v-if="loadingpage">
          <div class="l1 load1"></div>
        </div>
        <div class="d1" v-else>
          <div>Oracle Price</div>
          <div>${{ toFixedNumber(title.OraclePrice, 2, true) }}</div>
        </div>
        <div class="d1" v-if="loadingpage">
          <div class="l1 load1"></div>
        </div>
        <div class="d1" v-else>
          <div>Available Liquidity</div>
          <div>${{ toFixedNumber(title.AvailableLiquidity, 2, true) }}</div>
        </div>
        <div class="d1" v-if="loadingpage">
          <div class="l1 load1"></div>
        </div>
        <div class="d1" v-else>
          <div>
            <span>LTV</span>
            <a-popover :title="false" placement="top">
              <template #content>
                <p
                  style="
                    font-weight: 400;
                    font-size: 10px;
                    color: #8f9092;
                    font-family: 'GT America Trial Ext Rg';
                    padding-top: 15px;
                    width: 169px;
                  "
                >
                  The maximum percentage of the collateral value available to be
                  borrowed.<br />
                </p>
              </template>
              <div
                @mouseenter="iconHighlight2 = false"
                @mouseleave="iconHighlight2 = true"
                v-if="themetype"
              >
                <img
                  src="../../../assets/image/dark/question3.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-if="iconHighlight2"
                />
                <img
                  src="../../../assets/image/icon/question2.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-else
                />
              </div>
              <div
                @mouseenter="iconHighlight3 = false"
                @mouseleave="iconHighlight3 = true"
                v-else
              >
                <img
                  src="../../../assets/image/icon/question.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-if="iconHighlight3"
                />
                <img
                  src="../../../assets/image/icon/question2.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-else
                />
              </div>
            </a-popover>
          </div>
          <div>{{ toFixedCutZero(title.LTV * 100, 2, false) }}%</div>
        </div>
        <div class="d1" v-if="loadingpage">
          <div class="l1 load1"></div>
        </div>
        <div class="d1" v-else>
          <div>
            <span>Liquidation Threshold</span>
            <a-popover :title="false" placement="top">
              <template #content>
                <p
                  style="
                    font-weight: 400;
                    font-size: 10px;
                    color: #8f9092;
                    font-family: 'GT America Trial Ext Rg';
                    padding-top: 15px;
                    width: 169px;
                  "
                >
                  The maximum percentage of the collateral value beyond which a
                  borrow position is considered uncollateralized. The collateral
                  will then be available for liquidation.<br />
                </p>
              </template>
              <div
                @mouseenter="iconHighlight2 = false"
                @mouseleave="iconHighlight2 = true"
                v-if="themetype"
              >
                <img
                  src="../../../assets/image/dark/question3.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-if="iconHighlight2"
                />
                <img
                  src="../../../assets/image/icon/question2.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-else
                />
              </div>
              <div
                @mouseenter="iconHighlight1 = false"
                @mouseleave="iconHighlight1 = true"
                v-else
              >
                <img
                  src="../../../assets/image/icon/question.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-if="iconHighlight1"
                />
                <img
                  src="../../../assets/image/icon/question2.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-else
                />
              </div>
            </a-popover>
          </div>
          <div>{{ toFixedCutZero(title.Threshold * 100, 2, false) }}%</div>
        </div>
        <div class="d1" v-if="loadingpage">
          <div class="l1 load1"></div>
        </div>
        <div class="d1" v-else>
          <div>
            <span>Liquidation Bonus</span>
            <a-popover :title="false" placement="top">
              <template #content>
                <p
                  style="
                    font-weight: 400;
                    font-size: 10px;
                    color: #8f9092;
                    font-family: 'GT America Trial Ext Rg';
                    padding-top: 15px;
                    width: 169px;
                  "
                >
                  The reward/incentive given to the liquidator to liquidate a
                  position when its H.F. 1. Expressed in percentage.<br />
                </p>
              </template>
              <div
                @mouseenter="iconHighlight2 = false"
                @mouseleave="iconHighlight2 = true"
                v-if="themetype"
              >
                <img
                  src="../../../assets/image/dark/question3.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-if="iconHighlight2"
                />
                <img
                  src="../../../assets/image/icon/question2.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-else
                />
              </div>
              <div
                @mouseenter="iconHighlight2 = false"
                @mouseleave="iconHighlight2 = true"
                v-else
              >
                <img
                  src="../../../assets/image/icon/question.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-if="iconHighlight2"
                />
                <img
                  src="../../../assets/image/icon/question2.svg"
                  style="cursor: pointer; width: 12px; height: 12px"
                  v-else
                />
              </div>
            </a-popover>
          </div>
          <div>{{ toFixedCutZero(title.Bonus, 2, false) }}%</div>
        </div>
        <div class="d3" v-if="loadingpage">
          <div class="l1 load1"></div>
        </div>
        <div
          class="d2"
          style="width: 256px; justify-content: space-between"
          v-else
        >
          <div class="b1">
            <a-button
              type="primary"
              :disabled="true"
              class="disabledtrue"
              v-if="false"
            >
              Supply
            </a-button>
            <a-button type="primary" class="btn2" v-else @click="supplyF()">
              Supply
            </a-button>
          </div>
          <div class="b2">
            <a-button
              type="primary"
              :disabled="true"
              class="disabledtrue"
              v-if="false"
            >
              Borrow
            </a-button>
            <a-button type="primary" class="btn2" v-else @click="Borrowf()">
              Borrow
            </a-button>
          </div>
        </div>
      </div>

      <div class="info">
        <div class="left">
          <div class="t3">Supply Info</div>
          <div class="t1">
            <div class="p1">
              <div>Total Supply</div>
              <div class="li load1" v-if="loadingpage"></div>
              <div v-else>{{ toFixedCutZero(Supplyinfo.Total, 2, false) }}</div>
            </div>
            <div class="p1">
              <div>APY</div>
              <div class="li load1" v-if="loadingpage"></div>
              <div v-else>
                {{ toFixedCutZero(+Supplyinfo.Apy * 100, 2, false) }}%
              </div>
            </div>
            <div class="p1">
              <div>Collateral Usage</div>
              <div class="li load1" v-if="loadingpage"></div>
              <div v-else>
                <img
                  v-if="Supplyinfo.Usage"
                  src="../../../assets/image/icon/yes.png"
                  width="17px"
                  height="12px"
                />
                <div v-else>-</div>
              </div>
            </div>
          </div>
          <div class="li load1" v-if="loadingpage"></div>
          <div class="t2" v-else>
            ${{ toFixedNumber(Supplyinfo.TotalMoeny, 2, true) }}
          </div>
          <div class="line"></div>

          <div class="t4">
            <div class="legend">
              <div></div>
              <div>Supply APY</div>
            </div>
            <div class="date">
              <div v-for="(item, index) in date" :key="index" class="date-main">
                <div
                  class="date1"
                  v-if="item.type"
                  @click="ChooseDate(item.name)"
                >
                  {{ item.name }}
                </div>
                <div class="date2" v-else @click="ChooseDate(item.name)">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <SupplyEchart :supplyinfof="supplyinfof"></SupplyEchart>
        </div>
        <div class="right">
          <div class="t3">Borrow Info</div>
          <div class="t1">
            <div class="p1">
              <div>Total Borrow</div>
              <div class="li load1" v-if="loadingpage"></div>
              <div v-else>{{ toFixedCutZero(Borrowinfo.Total, 2, false) }}</div>
            </div>
            <div class="p1">
              <div>APY</div>
              <div class="li load1" v-if="loadingpage"></div>
              <div v-else>
                {{ toFixedCutZero(Borrowinfo.Apy * 100, 2, false) }}%
              </div>
            </div>
            <div class="p1">
              <div style="color: transparent">Collateral Usage</div>
            </div>
          </div>
          <div class="li load1" v-if="loadingpage"></div>
          <div class="t2" v-else>
            ${{ toFixedNumber(Borrowinfo.TotalMoeny, 2, true) }}
          </div>
          <div class="line"></div>
          <div class="t4">
            <div class="legend2">
              <div></div>
              <div>Borrow APY</div>
            </div>
            <div class="date">
              <div
                v-for="(item, index) in date2"
                :key="index"
                class="date-main"
              >
                <div
                  class="date1"
                  v-if="item.type"
                  @click="ChooseDate2(item.name)"
                >
                  {{ item.name }}
                </div>
                <div class="date2" v-else @click="ChooseDate2(item.name)">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <BorrowEchart :borrowinfof="borrowinfof"></BorrowEchart>
        </div>
      </div>
    </div>

    <switchs
      v-if="showSwitch"
      :setShow="setShowModal"
      :switchnetwork="switchnetwork"
      :switchid="switchid"
      :ismoneytype="ismoneytype"
    ></switchs>
    <supply
      v-if="showSupplyF"
      :setShow="setShowModal"
      :TypeOperation="TypeOperation"
      :AllMyNumber="AllMyNumber"
      :wallectbanlance="wallectbanlance"
      :tokenid="tokenid"
    ></supply>
    <borrow
      v-if="showborrow"
      :setShow="setShowModal"
      :TypeOperation="TypeOperation"
      :AllMyNumber="AllMyNumber"
      :networklists="networklists"
    ></borrow>
    <Enable
      v-if="showEnable"
      :setShow="setShowModal"
      :EnableType="EnableType"
    ></Enable>
    <Cedrobottom></Cedrobottom>
  </div>
  <MoblieDetails v-else></MoblieDetails>
</template>

<script>
import SupplyEchart from './SupplyEchart';
import BorrowEchart from './BorrowEchart';
import Cedrobottom from '../../../components/bottomF';
import {
  getBalance,
  getChart,
  getPoolList,
  getUserInfo,
  toFixedCutZero,
  toFixedNumber,
} from '../../../SDK';
import Supply from '../../Dashboard/components/Supply';
import Borrow from '../../Dashboard/components/borrow';
import Switchs from '../../../components/Signin/Switchs';
import Enable from '../../../components/Signin/Enable';
import { mapGetters } from 'vuex';
import { chainIdDict } from '../../../SDK/config';
import MoblieDetails from '../Moblie/componets/MoblieDetails';
import { checkTransactionStatus } from '../../../SDK/checkHash';
export default {
  name: 'MarketDetails',
  components: {
    MoblieDetails,
    Enable,
    Switchs,
    Borrow,
    Supply,
    BorrowEchart,
    SupplyEchart,
    Cedrobottom,
  },
  computed: {
    ...mapGetters(['account', 'chainid', 'chain', 'themetype', 'equipment']),
  },
  data() {
    return {
      Moblie: false,
      supplyinfof: {
        x: [],
        y: [],
      },
      borrowinfof: {
        x: [],
        y: [],
      },
      ismoneytype: 1,
      iconHighlight: true,
      loadingpage: false,
      toFixedNumber: toFixedNumber,
      toFixedCutZero: toFixedCutZero,
      iconHighlight1: true,
      iconHighlight2: true,
      iconHighlight3: true,
      showSupplyF: false,
      showborrow: false,
      tokenid: '',
      showEnable: false,
      showSwitch: false,
      tokenlist: {},
      switchnetwork: '',
      switchid: 0,
      wallectbanlance: 0,
      title: {},
      EnableType: {},
      networklists: [],
      TypeOperation: {},
      ChartTotalData: {},
      typeinfo: {},
      AllMyNumber: {
        AllMyborrow: 0,
        AllSupply: 0,
      },
      date: [
        {
          name: 'Year',
          type: true,
        },
        {
          name: 'Month',
          type: false,
        },
        {
          name: 'Week',
          type: false,
        },
        {
          name: 'Day',
          type: false,
        },
      ],
      types: {},
      date2: [
        {
          name: 'Year',
          type: true,
        },
        {
          name: 'Month',
          type: false,
        },
        {
          name: 'Week',
          type: false,
        },
        {
          name: 'Day',
          type: false,
        },
      ],
      Supplyinfo: {
        Total: 23.45,
        TotalMoeny: 23.45,
        Apy: 2.58,
        Usage: '-',
      },
      Borrowinfo: {
        Total: 23.45,
        TotalMoeny: 23.45,
        Apy: 2.58,
      },
      quesesticon: '',
    };
  },
  watch: {
    account(news) {
      if (news !== '') {
        this.DetailInfo();
      }
    },
  },
  created() {
    this.DetailInfo();
    this.EachartInfo();
  },
  mounted() {},
  methods: {
    async EachartInfo() {
      this.ChartTotalData = await getChart(this.$route.query.token);
      this.ChooseDate('Year');
      this.ChooseDate2('Year');
    },
    icon(name) {
      if (name === 'testUSDC') {
        return require('../../../assets/image/icon/USDC.svg');
      } else if (name === 'testAVAX') {
        return require('../../../assets/image/icon/AVAX.svg');
      } else if (name === 'testFTM') {
        return require('../../../assets/image/icon/FTM.svg');
      } else if (name === 'Fantom Testnet') {
        return require('../../../assets/image/icon/FTM.svg');
      } else if (name === 'USDC') {
        return require('../../../assets/image/icon/USDC.svg');
      } else if (name === 'Avalanche Testnet') {
        return require('../../../assets/image/icon/AVAX.svg');
      } else if (name === 'Polygon Mumbai') {
        return require('../../../assets/image/icon/polygon.svg');
      } else if (name === 'testMATIC') {
        return require('../../../assets/image/icon/polygon.svg');
      } else if (name === 'testETH') {
        return require('../../../assets/image/icon/ETHicon.svg');
      } else if (name === 'Goerli Testnet') {
        return require('../../../assets/image/icon/ETHicon.svg');
      } else if (name === 'Arbitrum Testnet') {
        return require('../../../assets/image/icon/Arbitrum.svg');
      } else {
        return require('../../../assets/image/icon/unknown.svg');
      }
    },
    async DetailInfo() {
      this.loadingpage = true;
      if (this.account != '') {
        let _list = await getUserInfo();
        let list = _list?.info ?? [];
        if (list.length > 0) {
          this.loadingpage = false;
          let chains = this.$route.query.chain;
          let token = this.$route.query.token;
          let title2 = list.find((item) => item.symbol === token);
          this.tokenlist = list.map((item) => {
            return {
              ceScaled: item.ceScaled,
              dtScaled: item.dtScaled,
              increasedDebt: item.increasedDebt,
              loan: item.loan,
              liquidationPrice: item.liquidationPrice,
              name: item.name,
              withdrawable: item.withdrawable,
              myBorrow: +item.myBorrow,
              LTS: item.LTS,
              LTV: item.LTV,
              icon: require(`../../../assets/image/icon/${item.symbol}.svg`),
              TokenName: item.symbol,
              OraclePrice: item.price,
              CollateralToken: item.mySupply,
              Collateral: item.mySupply * +item.price,
              EarnedToken: item.interestEarn,
              Earned: item.interestEarn * +item.price,
              SupplyApy: item.supplyApy * 100,
              Liquidation: 0,
              BorrowedToken: item.myBorrow,
              Borrowed: item.myBorrow * +item.price,
              PayableToken: item.interestPay,
              Payable: item.interestPay * +item.price,
              BorrowApy: +item.borrowApy * 100,
            };
          });
          this.AllMyNumber.AllSupply = _list.healthFactor.collateralValue;
          this.AllMyNumber.AllMyborrow = _list.healthFactor.debtValue;
          let Risk = list[0].LTV;
          this.AllMyNumber.AllBorrowLimt =
            this.AllMyNumber.AllSupply * Risk - this.AllMyNumber.AllMyborrow;
          this.title = {
            NetWork: chains,
            NetWorkIcon: chains,
            TokenName: title2?.symbol,
            TokenName2: title2?.name,
            TokenIcon: title2?.symbol,
            OraclePrice: title2?.price,
            AvailableLiquidity:
              (title2?.totalSupply - title2?.totalBorrow) * title2?.price,
            LTV: title2?.LTV,
            Threshold: title2?.LTS,
            Bonus: title2?.ltBonus * 100,
          };
          (this.Supplyinfo = {
            Total: title2?.totalSupply,
            TotalMoeny: title2?.totalSupply * +title2?.price,
            Apy: title2?.supplyApy,
            Usage: title2?.enable,
          }),
            (this.Borrowinfo = {
              Total: title2?.totalBorrow,
              TotalMoeny: title2?.totalBorrow * +title2?.price,
              Apy: title2?.borrowApy,
            });
        } else {
          this.loadingpage = false;
        }
      } else {
        this.loadingpage = false;
      }
    },
    supplyF() {
      let balance = getPoolList();
      let b = '';
      for (let i = 0; i < balance.length; i++) {
        if (
          this.$route.query.chain === balance[i].chain &&
          this.$route.query.token === balance[i].symbol
        ) {
          this.types = balance[i];
          b = balance[i];
          break;
        }
      }
      if (b.chainid !== this.chainid) {
        this.ismoneytype = 2;
        this.showSwitch = !this.showSwitch;
        this.switchid = b.chainid;
        this.switchnetwork = b.chain;
      } else {
        checkTransactionStatus(this.types.id).then((type) => {
          if (type.code === 0) {
            this.$message.warn('Spending limit being set, please wait..');
          } else if (type.code === 1) {
            getBalance(this.types.id).then((a) => {
              if (+a.allowance <= 0) {
                this.showEnable = !this.showEnable;
                this.EnableType = a;
              } else {
                this.tokenid = this.types.id;
                this.wallectbanlance = +a.balance;
                this.TypeOperation = this.tokenlist.find(
                  (item) => item.TokenName === this.$route.query.token
                );
                this.showSupplyF = !this.showSupplyF;
              }
            });
          }
        });
      }
    },
    Borrowf() {
      if (this.chainid === 97) {
        this.networklists = getPoolList().filter(
          (item) => item.symbol === this.title.TokenName
        );
        this.TypeOperation = this.tokenlist.find(
          (item) => item.TokenName === this.$route.query.token
        );
        this.showborrow = !this.showborrow;
      } else {
        this.switchnetwork = chainIdDict[97];
        this.switchid = 97;
        this.ismoneytype = 1;
        this.showSwitch = !this.showSwitch;
      }
    },
    setShowModal(value) {
      this.showborrow = value;
      this.showSupplyF = value;
      this.showEnable = value;
      this.showSwitch = value;
    },
    ChooseDate(name) {
      this.date = this.date.map((item) => {
        return {
          type: item.name === name,
          name: item.name,
        };
      });
      if (name === 'Year') {
        this.supplyinfof = {
          x: this.ChartTotalData.y.x,
          y: this.ChartTotalData.y.yd,
        };
      } else if (name === 'Month') {
        this.supplyinfof = {
          x: this.ChartTotalData.m.x,
          y: this.ChartTotalData.m.yd,
        };
      } else if (name === 'Week') {
        this.supplyinfof = {
          x: this.ChartTotalData.w.x,
          y: this.ChartTotalData.w.yd,
        };
      } else if (name === 'Day') {
        this.supplyinfof = {
          x: this.ChartTotalData.d.x,
          y: this.ChartTotalData.d.yd,
        };
      }
    },
    ChooseDate2(name) {
      this.date2 = this.date2.map((item) => {
        return {
          type: item.name === name,
          name: item.name,
        };
      });
      if (name === 'Year') {
        this.borrowinfof = {
          x: this.ChartTotalData.y.x,
          y: this.ChartTotalData.y.yb,
        };
      } else if (name === 'Month') {
        this.borrowinfof = {
          x: this.ChartTotalData.m.x,
          y: this.ChartTotalData.m.yb,
        };
      } else if (name === 'Week') {
        this.borrowinfof = {
          x: this.ChartTotalData.w.x,
          y: this.ChartTotalData.w.yb,
        };
      } else if (name === 'Day') {
        this.borrowinfof = {
          x: this.ChartTotalData.d.x,
          y: this.ChartTotalData.d.yb,
        };
      }
    },
    backbtn() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.Details {
  background: #f9f4ff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .Detalis-main {
    min-height: calc(100vh - 180px);
    width: calc(100% - 160px);
    padding: 156px 0px 90px 0px;
    max-width: 1280px;

    .title {
      margin-bottom: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .back {
        .btn {
          margin-right: 14px;
          cursor: pointer;
          padding: 10px 23px;
          background: #ffffff;
          border-radius: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .b1 {
            font-family: 'GT America Trial Ext Md';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            //line-height: 42px;
            color: black;
          }
          .b2 {
            font-family: 'GT America Trial Ext Md';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            //line-height: 42px;
            color: #808080;
          }
        }
      }
      .network {
        margin-left: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          margin-right: 6px;
          height: 24px;
          width: 24px;
        }
        div {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 42px;
          color: #000000;
        }
        .l1 {
          border-radius: 4px;
          height: 30px;
          //width: 65%;
          width: 100px;
          line-height: 24px;
        }
      }
    }
    .title2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .d1 {
        div:nth-child(1) {
          display: flex;
          align-items: center;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 17px;
          color: #000000;
          span {
            margin-right: 5px;
            font-family: 'GT America Trial Ext Rg';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;
            color: #000000;
          }
        }
        div:nth-child(2) {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: #000000;
        }
        .l1 {
          border-radius: 4px;
          height: 30px;
          width: 80px;
          line-height: 24px;
        }
      }
      .d2 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .l1 {
          border-radius: 4px;
          height: 30px;
          //width: 65%;
          width: 100px;
          line-height: 24px;
        }
        div:nth-child(1) {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: #000000;
        }
        div:nth-child(2) {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 17px;
          color: #000000;
        }
        img {
          margin-right: 10px;
          width: 40px;
          height: 40px;
        }

        .b1 {
          ::v-deep.disabledtrue.ant-btn-disabled,
          .ant-btn.disabled,
          .ant-btn[disabled],
          .ant-btn-disabled:hover,
          .ant-btn.disabled:hover,
          .ant-btn[disabled]:hover,
          .ant-btn-disabled:focus,
          .ant-btn.disabled:focus,
          .ant-btn[disabled]:focus,
          .ant-btn-disabled:active,
          .ant-btn.disabled:active,
          .ant-btn[disabled]:active,
          .ant-btn-disabled.active,
          .ant-btn.disabled.active,
          .ant-btn[disabled].active {
            font-family: 'GT America Trial Ext Md';
            color: white;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 48px;
            border-color: transparent;
            height: 50px;
            width: 120px;
            text-shadow: none;
            box-shadow: none;
          }
          ::v-deep .ant-btn-primary.btn1 {
            width: 120px;
            font-family: 'GT America Trial Ext Md';
            color: #aa68f0;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            background: #ffffff;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #aa68f0;
            border-radius: 48px;
          }
          ::v-deep .ant-btn-primary.btn1:hover,
          .ant-btn-primary.btn1:focus {
            border: 1px solid transparent;
            background: rgba(170, 104, 240, 0.12) !important;
            color: #aa68f0 !important;
          }
          ::v-deep .ant-btn-primary.btn2 {
            font-family: 'GT America Trial Ext Md';
            border: 1px solid transparent;
            border-radius: 48px;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #aa68f0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }

          ::v-deep .btn2.ant-btn-primary:hover,
          .ant-btn-primary:focus {
            border: 1px solid transparent;
            color: #ffffff !important;
            background-color: #aa68f0cc !important;
          }
        }
        .b2 {
          ::v-deep.disabledtrue.ant-btn-disabled,
          .ant-btn.disabled,
          .ant-btn[disabled],
          .ant-btn-disabled:hover,
          .ant-btn.disabled:hover,
          .ant-btn[disabled]:hover,
          .ant-btn-disabled:focus,
          .ant-btn.disabled:focus,
          .ant-btn[disabled]:focus,
          .ant-btn-disabled:active,
          .ant-btn.disabled:active,
          .ant-btn[disabled]:active,
          .ant-btn-disabled.active,
          .ant-btn.disabled.active,
          .ant-btn[disabled].active {
            font-family: 'GT America Trial Ext Md';
            color: white;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 48px;
            border-color: transparent;
            height: 50px;
            width: 120px;
            text-shadow: none;
            box-shadow: none;
          }
          ::v-deep .ant-btn-primary.btn1 {
            font-family: 'GT America Trial Ext Md';
            color: #9368f0;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #ffffff;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #9368f0;
            border-radius: 48px;
          }
          ::v-deep .ant-btn-primary.btn1:hover,
          .ant-btn-primary.btn1:focus {
            border: 1px solid transparent;
            background: rgba(170, 104, 240, 0.12) !important;
            color: #9368f0 !important;
          }
          ::v-deep .ant-btn-primary.btn2 {
            font-family: 'GT America Trial Ext Md';
            border: 1px solid transparent;
            border-radius: 48px;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #9368f0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }

          ::v-deep .btn2.ant-btn-primary:hover,
          .ant-btn-primary:focus {
            border: 1px solid transparent;
            color: #ffffff !important;
            background-color: #9368f0cc !important;
          }
        }
      }
      .d3 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .l1 {
          border-radius: 4px;
          height: 30px;
          //width: 65%;
          width: 150px;
          line-height: 24px;
        }
        div:nth-child(1) {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: #000000;
        }
        div:nth-child(2) {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 17px;
          color: #000000;
        }
        img {
          margin-right: 10px;
          width: 40px;
          height: 40px;
        }

        .b1 {
          ::v-deep.disabledtrue.ant-btn-disabled,
          .ant-btn.disabled,
          .ant-btn[disabled],
          .ant-btn-disabled:hover,
          .ant-btn.disabled:hover,
          .ant-btn[disabled]:hover,
          .ant-btn-disabled:focus,
          .ant-btn.disabled:focus,
          .ant-btn[disabled]:focus,
          .ant-btn-disabled:active,
          .ant-btn.disabled:active,
          .ant-btn[disabled]:active,
          .ant-btn-disabled.active,
          .ant-btn.disabled.active,
          .ant-btn[disabled].active {
            font-family: 'GT America Trial Ext Md';
            color: white;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 48px;
            border-color: transparent;
            height: 50px;
            width: 120px;
            text-shadow: none;
            box-shadow: none;
          }
          ::v-deep .ant-btn-primary.btn1 {
            width: 120px;
            font-family: 'GT America Trial Ext Md';
            color: #aa68f0;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            background: #ffffff;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #aa68f0;
            border-radius: 48px;
          }
          ::v-deep .ant-btn-primary.btn1:hover,
          .ant-btn-primary.btn1:focus {
            border: 1px solid transparent;
            background: rgba(170, 104, 240, 0.12) !important;
            color: #aa68f0 !important;
          }
          ::v-deep .ant-btn-primary.btn2 {
            font-family: 'GT America Trial Ext Md';
            border: 1px solid transparent;
            border-radius: 48px;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #aa68f0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }

          ::v-deep .btn2.ant-btn-primary:hover,
          .ant-btn-primary:focus {
            border: 1px solid transparent;
            color: #ffffff !important;
            background-color: #aa68f0cc !important;
          }
        }
        .b2 {
          ::v-deep.disabledtrue.ant-btn-disabled,
          .ant-btn.disabled,
          .ant-btn[disabled],
          .ant-btn-disabled:hover,
          .ant-btn.disabled:hover,
          .ant-btn[disabled]:hover,
          .ant-btn-disabled:focus,
          .ant-btn.disabled:focus,
          .ant-btn[disabled]:focus,
          .ant-btn-disabled:active,
          .ant-btn.disabled:active,
          .ant-btn[disabled]:active,
          .ant-btn-disabled.active,
          .ant-btn.disabled.active,
          .ant-btn[disabled].active {
            font-family: 'GT America Trial Ext Md';
            color: white;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 48px;
            border-color: transparent;
            height: 50px;
            width: 120px;
            text-shadow: none;
            box-shadow: none;
          }
          ::v-deep .ant-btn-primary.btn1 {
            font-family: 'GT America Trial Ext Md';
            color: #9368f0;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #ffffff;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #9368f0;
            border-radius: 48px;
          }
          ::v-deep .ant-btn-primary.btn1:hover,
          .ant-btn-primary.btn1:focus {
            border: 1px solid transparent;
            background: rgba(170, 104, 240, 0.12) !important;
            color: #9368f0 !important;
          }
          ::v-deep .ant-btn-primary.btn2 {
            font-family: 'GT America Trial Ext Md';
            border: 1px solid transparent;
            border-radius: 48px;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #9368f0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }

          ::v-deep .btn2.ant-btn-primary:hover,
          .ant-btn-primary:focus {
            border: 1px solid transparent;
            color: #ffffff !important;
            background-color: #9368f0cc !important;
          }
        }
      }
    }

    .info {
      margin-top: 39px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        border: 1px solid rgba(0, 0, 0, 0.08);
        padding: 32px;
        background: #ffffff;
        border-radius: 16px;
        width: calc(50% - 10px);
        max-width: 628px;

        .t3 {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #000000;
          margin-bottom: 16px;
        }
        .t1 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .p1 {
            .li {
              width: 80px;
              border-radius: 4px;
              height: 16px;
              line-height: 24px;
            }
            div:nth-child(1) {
              font-family: 'GT America Trial Ext Rg';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.5);
            }
            div:nth-child(2) {
              font-family: 'GT America Trial Ext Bd';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 32px;
              letter-spacing: -0.01em;
              color: #000000;
            }
          }
        }
        .t2 {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 32px;
          letter-spacing: -0.01em;
          color: #000000;
        }
        .l1 {
          border-radius: 4px;
          height: 16px;
          width: 65%;
          line-height: 24px;
        }
        .t4 {
          margin-top: 34px;
          margin-bottom: 51px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .legend {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div:nth-child(1) {
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background: #aa68f0;
            }
            div:nth-child(2) {
              margin-left: 6px;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 22px;
              color: #000000;
              font-family: 'GT America Trial Ext Md';
            }
          }

          .date {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .date-main {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .date1 {
                cursor: pointer;
                border: 1px solid transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 26px;
                background: #aa68f0;
                border-radius: 19px;
                color: #ffffff;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                font-family: 'GT America Trial Ext Md';
              }
              .date2 {
                cursor: pointer;
                border: 1px solid transparent;
                font-family: 'GT America Trial Ext Md';
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.02);
                border-radius: 19px;
                width: 60px;
                height: 26px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
        .line {
          margin-top: 32px;
          border: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
      .right {
        border: 1px solid rgba(0, 0, 0, 0.08);
        padding: 32px;
        background: #ffffff;
        border-radius: 16px;
        width: calc(50% - 10px);
        max-width: 628px;

        .t3 {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #000000;
          margin-bottom: 16px;
        }
        .t1 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .p1 {
            .li {
              width: 80px;
              border-radius: 4px;
              height: 16px;
              line-height: 24px;
            }
            div:nth-child(1) {
              font-family: 'GT America Trial Ext Rg';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.5);
            }
            div:nth-child(2) {
              font-family: 'GT America Trial Ext Bd';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 32px;
              letter-spacing: -0.01em;
              color: #000000;
            }
          }
        }
        .t2 {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 32px;
          letter-spacing: -0.01em;
          color: #000000;
        }
        .t4 {
          margin-top: 34px;
          margin-bottom: 51px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .date {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .date-main {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .date1 {
                font-family: 'GT America Trial Ext Md';
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 26px;
                background: #9368f0;
                border-radius: 19px;
                color: #ffffff;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
              }
              .date2 {
                font-family: 'GT America Trial Ext Md';
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.02);
                border-radius: 19px;
                width: 60px;
                height: 26px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }
          .legend2 {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div:nth-child(1) {
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background: #9368f0;
            }
            div:nth-child(2) {
              margin-left: 6px;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 22px;
              color: #000000;
              font-family: 'GT America Trial Ext Md';
            }
          }
        }
        .line {
          margin-top: 32px;
          border: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
}
.Details-dark {
  background: #140a20;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .Detalis-main {
    min-height: calc(100vh - 180px);
    width: calc(100% - 160px);
    padding: 156px 0px 90px 0px;
    max-width: 1280px;

    .title {
      margin-bottom: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .back {
        .btn {
          margin-right: 14px;
          cursor: pointer;
          padding: 10px 23px;
          background: #27202e;
          border-radius: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .b1 {
            font-family: 'GT America Trial Ext Md';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            //line-height: 42px;
            color: white;
          }
          .b2 {
            font-family: 'GT America Trial Ext Md';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            //line-height: 42px;
            color: #808080;
          }
        }
      }
      .network {
        margin-left: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          margin-right: 6px;
          height: 24px;
          width: 24px;
        }
        div {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 42px;
          color: white;
        }
        .l1 {
          border-radius: 4px;
          height: 30px;
          //width: 65%;
          width: 100px;
          line-height: 24px;
        }
      }
    }
    .title2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .d1 {
        div:nth-child(1) {
          display: flex;
          align-items: center;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 17px;
          color: white;
          span {
            margin-right: 5px;
            font-family: 'GT America Trial Ext Rg';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;
            color: white;
          }
        }
        div:nth-child(2) {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: white;
        }
        .l1 {
          border-radius: 4px;
          height: 30px;
          width: 80px;
          line-height: 24px;
        }
      }
      .d2 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .l1 {
          border-radius: 4px;
          height: 30px;
          //width: 65%;
          width: 100px;
          line-height: 24px;
        }
        div:nth-child(1) {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: white;
        }
        div:nth-child(2) {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 17px;
          color: white;
        }
        img {
          margin-right: 10px;
          width: 40px;
          height: 40px;
        }

        .b1 {
          ::v-deep.disabledtrue.ant-btn-disabled,
          .ant-btn.disabled,
          .ant-btn[disabled],
          .ant-btn-disabled:hover,
          .ant-btn.disabled:hover,
          .ant-btn[disabled]:hover,
          .ant-btn-disabled:focus,
          .ant-btn.disabled:focus,
          .ant-btn[disabled]:focus,
          .ant-btn-disabled:active,
          .ant-btn.disabled:active,
          .ant-btn[disabled]:active,
          .ant-btn-disabled.active,
          .ant-btn.disabled.active,
          .ant-btn[disabled].active {
            font-family: 'GT America Trial Ext Md';
            color: white;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 48px;
            border-color: transparent;
            height: 50px;
            width: 120px;
            text-shadow: none;
            box-shadow: none;
          }
          ::v-deep .ant-btn-primary.btn1 {
            width: 120px;
            font-family: 'GT America Trial Ext Md';
            color: #aa68f0;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            background: transparent;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #aa68f0;
            border-radius: 48px;
          }
          ::v-deep .ant-btn-primary.btn1:hover,
          .ant-btn-primary.btn1:focus {
            border: 1px solid transparent;
            background: rgba(170, 104, 240, 0.12) !important;
            color: #aa68f0 !important;
          }
          ::v-deep .ant-btn-primary.btn2 {
            font-family: 'GT America Trial Ext Md';
            border: 1px solid transparent;
            border-radius: 48px;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #aa68f0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }

          ::v-deep .btn2.ant-btn-primary:hover,
          .ant-btn-primary:focus {
            border: 1px solid transparent;
            color: #ffffff !important;
            background-color: #aa68f0cc !important;
          }
        }
        .b2 {
          ::v-deep.disabledtrue.ant-btn-disabled,
          .ant-btn.disabled,
          .ant-btn[disabled],
          .ant-btn-disabled:hover,
          .ant-btn.disabled:hover,
          .ant-btn[disabled]:hover,
          .ant-btn-disabled:focus,
          .ant-btn.disabled:focus,
          .ant-btn[disabled]:focus,
          .ant-btn-disabled:active,
          .ant-btn.disabled:active,
          .ant-btn[disabled]:active,
          .ant-btn-disabled.active,
          .ant-btn.disabled.active,
          .ant-btn[disabled].active {
            font-family: 'GT America Trial Ext Md';
            color: white;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 48px;
            border-color: transparent;
            height: 50px;
            width: 120px;
            text-shadow: none;
            box-shadow: none;
          }
          ::v-deep .ant-btn-primary.btn1 {
            font-family: 'GT America Trial Ext Md';
            color: #9368f0;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: transparent;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #9368f0;
            border-radius: 48px;
          }
          ::v-deep .ant-btn-primary.btn1:hover,
          .ant-btn-primary.btn1:focus {
            border: 1px solid transparent;
            background: rgba(170, 104, 240, 0.12) !important;
            color: #9368f0 !important;
          }
          ::v-deep .ant-btn-primary.btn2 {
            font-family: 'GT America Trial Ext Md';
            border: 1px solid transparent;
            border-radius: 48px;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #9368f0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }

          ::v-deep .btn2.ant-btn-primary:hover,
          .ant-btn-primary:focus {
            border: 1px solid transparent;
            color: #ffffff !important;
            background-color: #9368f0cc !important;
          }
        }
      }
      .d3 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .l1 {
          border-radius: 4px;
          height: 30px;
          //width: 65%;
          width: 150px;
          line-height: 24px;
        }
        div:nth-child(1) {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: white;
        }
        div:nth-child(2) {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 17px;
          color: white;
        }
        img {
          margin-right: 10px;
          width: 40px;
          height: 40px;
        }

        .b1 {
          ::v-deep.disabledtrue.ant-btn-disabled,
          .ant-btn.disabled,
          .ant-btn[disabled],
          .ant-btn-disabled:hover,
          .ant-btn.disabled:hover,
          .ant-btn[disabled]:hover,
          .ant-btn-disabled:focus,
          .ant-btn.disabled:focus,
          .ant-btn[disabled]:focus,
          .ant-btn-disabled:active,
          .ant-btn.disabled:active,
          .ant-btn[disabled]:active,
          .ant-btn-disabled.active,
          .ant-btn.disabled.active,
          .ant-btn[disabled].active {
            font-family: 'GT America Trial Ext Md';
            color: white;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 48px;
            border-color: transparent;
            height: 50px;
            width: 120px;
            text-shadow: none;
            box-shadow: none;
          }
          ::v-deep .ant-btn-primary.btn1 {
            width: 120px;
            font-family: 'GT America Trial Ext Md';
            color: #aa68f0;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            background: transparent;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #aa68f0;
            border-radius: 48px;
          }
          ::v-deep .ant-btn-primary.btn1:hover,
          .ant-btn-primary.btn1:focus {
            border: 1px solid transparent;
            background: rgba(170, 104, 240, 0.12) !important;
            color: #aa68f0 !important;
          }
          ::v-deep .ant-btn-primary.btn2 {
            font-family: 'GT America Trial Ext Md';
            border: 1px solid transparent;
            border-radius: 48px;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #aa68f0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }

          ::v-deep .btn2.ant-btn-primary:hover,
          .ant-btn-primary:focus {
            border: 1px solid transparent;
            color: #ffffff !important;
            background-color: #aa68f0cc !important;
          }
        }
        .b2 {
          ::v-deep.disabledtrue.ant-btn-disabled,
          .ant-btn.disabled,
          .ant-btn[disabled],
          .ant-btn-disabled:hover,
          .ant-btn.disabled:hover,
          .ant-btn[disabled]:hover,
          .ant-btn-disabled:focus,
          .ant-btn.disabled:focus,
          .ant-btn[disabled]:focus,
          .ant-btn-disabled:active,
          .ant-btn.disabled:active,
          .ant-btn[disabled]:active,
          .ant-btn-disabled.active,
          .ant-btn.disabled.active,
          .ant-btn[disabled].active {
            font-family: 'GT America Trial Ext Md';
            color: white;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 48px;
            border-color: transparent;
            height: 50px;
            width: 120px;
            text-shadow: none;
            box-shadow: none;
          }
          ::v-deep .ant-btn-primary.btn1 {
            font-family: 'GT America Trial Ext Md';
            color: #9368f0;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: transparent;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #9368f0;
            border-radius: 48px;
          }
          ::v-deep .ant-btn-primary.btn1:hover,
          .ant-btn-primary.btn1:focus {
            border: 1px solid transparent;
            background: rgba(170, 104, 240, 0.12) !important;
            color: #9368f0 !important;
          }
          ::v-deep .ant-btn-primary.btn2 {
            font-family: 'GT America Trial Ext Md';
            border: 1px solid transparent;
            border-radius: 48px;
            text-shadow: none;
            box-shadow: none;
            height: 50px;
            width: 120px;
            background: #9368f0;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }

          ::v-deep .btn2.ant-btn-primary:hover,
          .ant-btn-primary:focus {
            border: 1px solid transparent;
            color: #ffffff !important;
            background-color: #9368f0cc !important;
          }
        }
      }
    }

    .info {
      margin-top: 39px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        border: 1px solid rgba(255, 255, 255, 0.08);
        padding: 32px;
        background: #27202e;
        border-radius: 16px;
        width: calc(50% - 10px);
        max-width: 628px;

        .t3 {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 16px;
        }
        .t1 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .p1 {
            .li {
              width: 80px;
              border-radius: 4px;
              height: 16px;
              line-height: 24px;
            }
            div:nth-child(1) {
              font-family: 'GT America Trial Ext Rg';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: rgba(255, 255, 255, 0.5);
            }
            div:nth-child(2) {
              font-family: 'GT America Trial Ext Bd';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 32px;
              letter-spacing: -0.01em;
              color: white;
            }
          }
        }
        .t2 {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 32px;
          letter-spacing: -0.01em;
          color: white;
        }
        .l1 {
          border-radius: 4px;
          height: 16px;
          width: 65%;
          line-height: 24px;
        }
        .t4 {
          margin-top: 34px;
          margin-bottom: 51px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .legend {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div:nth-child(1) {
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background: #aa68f0;
            }
            div:nth-child(2) {
              margin-left: 6px;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 22px;
              color: white;
              font-family: 'GT America Trial Ext Md';
            }
          }

          .date {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .date-main {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .date1 {
                cursor: pointer;
                border: 1px solid transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 26px;
                background: #aa68f0;
                border-radius: 19px;
                color: #ffffff;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                font-family: 'GT America Trial Ext Md';
              }
              .date2 {
                cursor: pointer;
                border: 1px solid transparent;
                font-family: 'GT America Trial Ext Md';
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(255, 255, 255, 0.02);
                border-radius: 19px;
                width: 60px;
                height: 26px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                color: rgba(255, 255, 255, 0.3);
              }
            }
          }
        }
        .line {
          margin-top: 32px;
          border: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
      .right {
        border: 1px solid rgba(255, 255, 255, 0.08);
        padding: 32px;
        background: #27202e;
        border-radius: 16px;
        width: calc(50% - 10px);
        max-width: 628px;

        .t3 {
          font-family: 'GT America Trial Ext Bd';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: white;
          margin-bottom: 16px;
        }
        .t1 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .p1 {
            .li {
              width: 80px;
              border-radius: 4px;
              height: 16px;
              line-height: 24px;
            }
            div:nth-child(1) {
              font-family: 'GT America Trial Ext Rg';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: rgba(255, 255, 255, 0.5);
            }
            div:nth-child(2) {
              font-family: 'GT America Trial Ext Bd';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 32px;
              letter-spacing: -0.01em;
              color: white;
            }
          }
        }
        .t2 {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 32px;
          letter-spacing: -0.01em;
          color: white;
        }
        .t4 {
          margin-top: 34px;
          margin-bottom: 51px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .date {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .date-main {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .date1 {
                font-family: 'GT America Trial Ext Md';
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 26px;
                background: #9368f0;
                border-radius: 19px;
                color: #ffffff;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
              }
              .date2 {
                font-family: 'GT America Trial Ext Md';
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(255, 255, 255, 0.02);
                border-radius: 19px;
                width: 60px;
                height: 26px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                color: rgba(255, 255, 255, 0.3);
              }
            }
          }
          .legend2 {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div:nth-child(1) {
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background: #9368f0;
            }
            div:nth-child(2) {
              margin-left: 6px;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 22px;
              color: white;
              font-family: 'GT America Trial Ext Md';
            }
          }
        }
        .line {
          margin-top: 32px;
          border: 1px solid rgba(255, 255, 255, 0.03);
        }
      }
    }
  }
}
</style>
