<template>
  <a-modal
    v-model="visible"
    :footer="false"
    width="343px"
    wrapClassName="loading"
    :afterClose="closemodel"
    :centered="true"
  >
    <template slot="closeIcon">
      <img
        src="../../../assets/image/icon/Close_L2.svg"
        style="margin-right: 18px; margin-top: 18px"
      />
    </template>
    <div class="loading-title">
      <div>Transaction in progress...</div>
      <div class="longtime" v-if="loadingtimetype">
        This transaction might take a little longer <br />because of Messenger
        issues
      </div>
      <div class="shorttime" v-else>
        This transaction is expected to take<br />
        {{ timetext === 0 ? '-' : timetext.toFixed() }} min to complete
      </div>
    </div>

    <div class="progress-percent">
      {{ toFixedCutZero(timeinfo, 2, false) }}%
    </div>
    <a-progress
      :percent="timeinfo"
      :showInfo="false"
      :strokeWidth="4"
      strokeColor="#FFFFFF"
    />
    <div class="loading-hash" v-if="showhash">
      <div>Generating Tx Hash...</div>
    </div>
    <div class="loading-list" v-else>
      <div>Tx Hash</div>
      <div @click="openhash(hash)">
        <span>
          {{
            hash === '' ? '' : hash.slice(0, 6) + '...' + hash.slice(-4)
          }}</span
        >
        <div
          @mouseenter="iconHighlight = false"
          @mouseleave="iconHighlight = true"
          v-if="themetype"
        >
          <img
            src="../../../assets/image/wallect/hashicon.svg"
            style="cursor: pointer"
            v-if="iconHighlight"
          />
          <img
            src="../../../assets/image/dark/hashicon3.svg"
            style="cursor: pointer"
            v-else
          />
        </div>
        <div
          @mouseenter="iconHighlight = false"
          @mouseleave="iconHighlight = true"
          v-else
        >
          <img
            src="../../../assets/image/wallect/hashicon.svg"
            style="cursor: pointer"
            v-if="iconHighlight"
          />
          <img
            src="../../../assets/image/wallect/hashicon3.svg"
            style="cursor: pointer"
            v-else
          />
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getHashStatus, getSlider, toFixedCutZero } from '../../../SDK';
import { mapGetters } from 'vuex';

export default {
  name: 'MoblieLoading',
  props: {
    hash: String,
  },
  data() {
    return {
      showhash: true,
      toFixedCutZero: toFixedCutZero,
      timetext: 0,
      timeinfo: 0,
      iconHighlight: true,
      visible: true,
      loadingtimetype: false,
    };
  },
  computed: {
    ...mapGetters(['themetype']),
  },
  created() {
    this.loadingprp();
  },
  methods: {
    loadingprp() {
      getSlider(this.hash, (a, b) => {
        if (a > 0) {
          this.showhash = false;
        }
        this.timeinfo = a;
        this.timetext = b / 60;
        if (this.timetext > 10) {
          this.loadingtimetype = true;
        }
      });
    },

    closemodel() {
      this.visible = false;

      if (this.setShow) {
        this.setShow(false);
      }
    },
    openhash(index) {
      getHashStatus(index).then((i) => {
        if (i[0]?.url) {
          window.open(i[0].url);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-progress-inner {
  height: 4px;
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 100px;
}
::v-deep .ant-modal-body {
  padding: 32px !important;
}
::v-deep .ant-modal-content {
  border-radius: 25px;
}
::v-deep .ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  background: #aa68f0;
  border-radius: 20px;
}
.loading {
  .loading-title {
    text-align: center;
    div:nth-child(1) {
      font-family: 'GT America Trial Ext Bd';
      margin-top: 27px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 24px;
    }
    .longtime {
      font-family: 'GT America Trial Ext Rg';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #ffffff;
    }
    .shorttime {
      font-family: 'GT America Trial Ext Rg';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #ffffff;
    }
  }

  .Underline {
    border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .progress-percent {
    font-family: 'GT America Trial Bd';
    margin-top: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
  }
  .loading-hash {
    margin-top: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
    div {
      font-family: 'GT America Trial Exp Bd';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }
  }
  .loading-list {
    margin-top: 16px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    div:nth-child(1) {
      font-family: 'GT America Trial Exp Bd';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }
    div:nth-child(2) {
      font-family: 'GT America Trial Ext Rg';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #ffffff;

      span {
        cursor: pointer;
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #ffffff;
      }
      span:hover {
        cursor: pointer;
        color: #9468f0;
      }
      img {
        margin-left: 11px;
      }
    }
  }
}
</style>
