<template>
  <div>
    <a-modal
      v-model="visible"
      :footer="false"
      width="480px"
      :wrapClassName="themetype === true ? 'Switch-dark' : 'Switch'"
      :afterClose="closemodel"
      :centered="true"
      :style="{ '--bg': bg }"
    >
      <template slot="closeIcon">
        <img
          src="../../assets/image/dark/Close_L3.svg"
          style="margin-right: 18px; margin-top: 18px"
          v-if="themetype"
        />
        <img
          src="../../assets/image/icon/Close_L.svg"
          style="margin-right: 18px; margin-top: 18px"
          v-else
        />
      </template>
      <div class="t1">Switch Network</div>
      <div class="t2" v-if="ismoneytype === 3">
        <div style="width: 88%; margin-top: 32px">
          Your current network is
          <span
            style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ chain }}</span
          >
          please switch to
          <span style="color: #AA68F0;font-family: 'GT America Trial Ext Md">{{
            this.SwtoNetworkName
          }}</span>
        </div>
      </div>

      <div class="t2" v-if="ismoneytype === 2">
        <div style="width: 100%; margin-top: 32px">
          Your asset is in
          <span style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ this.SwtoNetworkName }}<br
          /></span>
          and you're currently in
          <span style="color: #AA68F0;font-family: 'GT America Trial Ext Md">{{
            chain
          }}</span
          >. You need to switch to
          <span
            style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ this.SwtoNetworkName }}</span
          ><br />
          for Supply and Repay.
        </div>
      </div>

      <div class="t2" v-if="ismoneytype === 1">
        <div style="width: 89%; margin-top: 32px">
          Borrow, Withdraw, and Liquidate originates in the
          <span
            style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ this.SwtoNetworkName }}</span
          >. You're currently on
          <span style="color: #AA68F0;font-family: 'GT America Trial Ext Md">{{
            chain
          }}</span
          >. Please switch to
          <span
            style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ this.SwtoNetworkName }}</span
          >
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 12px 0px 32px 0px;
        "
      >
        <img :src="fromPath" style="width: 68px; height: 68px" />
        <img
          src="../../assets/image/dark/right-icon.svg"
          style="margin: 0px 20px"
          v-if="themetype"
        />
        <img
          src="../../assets/image/wallect/right-icon.svg"
          style="margin: 0px 20px"
          v-else
        />
        <img :src="toPath" style="width: 68px; height: 68px" />
      </div>
      <div class="btn">
        <a-button type="primary" @click="switchwork()" :loading="btnloading">
          Switch
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { changeMetamaskChain } from '../../SDK';
import { chainIdDict } from '../../SDK/config';
export default {
  name: 'Switchs',
  computed: {
    ...mapGetters(['chainid', 'chain', 'themetype']),
  },
  props: {
    ismoneytype: Number,
    setShow: Function,
    switchnetwork: String,
    switchid: Number,
  },
  data() {
    return {
      bg: '#ffffff',
      btnloading: false,
      visible: true,
      fromPath: require('../../assets/image/icon/unknown.svg'),
      toPath: require('../../assets/image/icon/unknown.svg'),
      polygonLogo: require('../../assets/image/wallect/ProtocolIcon.svg'),
      fantomLogo: require('../../assets/image/wallect/fantom.svg'),
      unkonwnLogo: require('../../assets/image/icon/unknown.svg'),
      SwtoNetworkName: '',
    };
  },
  created() {
    this.themetypeF();
    this.SwtoNetworkName = chainIdDict[this.switchid];
    this.fromPath = this.getIcon(this.chain);
    this.toPath = this.getIcon(this.switchnetwork);
  },
  methods: {
    themetypeF() {
      if (this.themetype) {
        this.bg = `#1D1426`;
      } else {
        this.bg = `#ffffff`;
      }
    },
    getIcon(name) {
      if (name === 'Fantom Testnet') {
        return require('../../assets/image/icon/FTM.svg');
      } else if (name === 'Avalanche Testnet') {
        return require('../../assets/image/icon/AVAX.svg');
      } else if (name === 'Bsc Testnet(Root Chain)') {
        return require('../../assets/image/icon/BNB.svg');
      } else if (name === 'Polygon Mumbai') {
        return require('../../assets/image/icon/polygon.svg');
      } else if (name === 'Arbitrum Testnet') {
        return require('../../assets/image/icon/Arbitrum.svg');
      } else if (name === 'Goerli Testnet') {
        return require('../../assets/image/icon/ETHicon.svg');
      } else {
        return require('../../assets/image/icon/unknown.svg');
      }
    },
    closemodel() {
      if (this.setShow) {
        this.setShow(false);
      }
      this.visible = false;
    },
    async switchwork() {
      this.btnloading = true;
      await changeMetamaskChain(this.switchid);
      this.btnloading = false;
      this.closemodel();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-modal-content {
  border-radius: 40px;
}
::v-deep .ant-modal-body {
  border-radius: 36px;
  background: var(--bg);
  padding: 32px !important;
}

.Switch {
  .t1 {
    font-family: 'GT America Trial Ext Bd';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
  .t2 {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      font-family: 'GT America Trial Ext Rg';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      color: #000000;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep.ant-btn-primary {
      width: 100%;
      font-family: Inter;
      font-size: 16px;
      font-weight: 700;
      margin-right: 12px;
      height: 64px;
      border-radius: 20px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }
    ::v-deep .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      border: 1px solid transparent;
      background: #aa68f0cc !important;
      color: #ffffff !important;
    }
  }
}
.Switch-dark {
  .t1 {
    font-family: 'GT America Trial Ext Bd';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: white;
  }
  .t2 {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      font-family: 'GT America Trial Ext Rg';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      color: white;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep.ant-btn-primary {
      width: 100%;
      font-family: Inter;
      font-size: 16px;
      font-weight: 700;
      margin-right: 12px;
      height: 64px;
      border-radius: 20px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }
    ::v-deep .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      border: 1px solid transparent;
      background: #aa68f0cc !important;
      color: #ffffff !important;
    }
  }
}
</style>
