<template>
  <div>
    <a-modal
      v-model="visible"
      :footer="false"
      width="480px"
      :wrapClassName="themetype === true ? 'Enable-dark' : 'Enable'"
      :afterClose="closemodel"
      :centered="true"
      :style="{ '--bg': bg }"
    >
      <template slot="closeIcon">
        <img
          src="../../assets/image/dark/Close_L3.svg"
          style="margin-right: 18px; margin-top: 18px"
          v-if="themetype"
        />
        <img
          src="../../assets/image/icon/Close_L.svg"
          style="margin-right: 18px; margin-top: 18px"
          v-else
        />
      </template>
      <div class="title">Insufficient allowance</div>
      <div class="icon">
        <img :src="icons(EnableType.token.symbol)" width="80px" height="80px" />
      </div>
      <div class="text">Please set the spending limit in metamask</div>
      <div class="btn">
        <a-button
          type="primary"
          class="btn1"
          @click="EnableF()"
          :loading="btnloading"
        >
          Set now
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { approveToken } from '../../SDK';
import { mapGetters } from 'vuex';
import { setHash } from '../../SDK/checkHash';

export default {
  name: 'Allowance',
  props: {
    setShow: Function,
    EnableType: Object,
  },
  computed: {
    ...mapGetters(['themetype']),
  },
  data() {
    return {
      bg: '#ffffff',
      btnloading: false,
      visible: true,
    };
  },
  created() {
    this.themetypeF();
  },
  methods: {
    themetypeF() {
      if (this.themetype) {
        this.bg = `#1D1426`;
      } else {
        this.bg = `#ffffff`;
      }
    },
    icons(name) {
      if (name === 'testUSDC') {
        return require('../../assets/image/icon/USDC.svg');
      } else if (name === 'testAVAX') {
        return require('../../assets/image/icon/AVAX.svg');
      } else if (name === 'testFTM') {
        return require('../../assets/image/icon/FTM.svg');
      } else if (name === 'testMATIC') {
        return require('../../assets/image/icon/polygon.svg');
      } else if (name === 'testETH') {
        return require('../../assets/image/icon/ETHicon.svg');
      } else if (name === 'Bsc Testnet(Root Chain)') {
        return require('../../assets/image/icon/BNB.svg');
      } else if (name === 'testBNB') {
        return require('../../assets/image/icon/BNB.svg');
      } else {
        return require('../../assets/image/icon/unknown.svg');
      }
    },

    closemodel() {
      this.visible = false;
      if (this.setShow) {
        this.setShow(false);
      }
    },
    EnableF() {
      this.btnloading = true;
      approveToken(this.EnableType.token.id, (code, hash) => {
        if (code === 1) {
          this.btnloading = false;
          this.visible = false;
        } else if (code > 1) {
          this.btnloading = false;
        } else if (code === 0) {
          setHash(this.EnableType.token.id, hash);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-modal-body {
  border-radius: 18px;
  background: var(--bg);
  padding: 32px !important;
}
::v-deep .ant-modal-content {
  border-radius: 20px;
}
.Enable {
  .title {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #000000;
  }
  .icon {
    margin: 32px 0px 52px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      //image-rendering:-moz-crisp-edges;
      //image-rendering:-o-crisp-edges;
      //image-rendering:-webkit-optimize-contrast;
      //image-rendering: crisp-edges;
      //-ms-interpolation-mode:nearest-neighbor;
    }
  }
  .text {
    margin-bottom: 48px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep.ant-btn-primary {
      width: 100%;
      font-family: 'GT America Trial Ext Md';
      font-size: 16px;
      font-weight: 700;
      margin-right: 12px;
      height: 64px;
      border-radius: 20px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }
    ::v-deep .ant-btn-primary:hover,
    .ant-btn-primary.btn1:focus {
      border: 1px solid transparent;
      background: #aa68f0cc !important;
      color: #ffffff !important;
    }
    ::v-deep.disabledtrue.ant-btn-disabled,
    .ant-btn.disabled,
    .ant-btn[disabled],
    .ant-btn-disabled:hover,
    .ant-btn.disabled:hover,
    .ant-btn[disabled]:hover,
    .ant-btn-disabled:focus,
    .ant-btn.disabled:focus,
    .ant-btn[disabled]:focus,
    .ant-btn-disabled:active,
    .ant-btn.disabled:active,
    .ant-btn[disabled]:active,
    .ant-btn-disabled.active,
    .ant-btn.disabled.active,
    .ant-btn[disabled].active {
      font-family: 'GT America Trial Ext Md';
      color: white;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
      height: 64px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.Enable-dark {
  .title {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: white;
  }
  .icon {
    margin: 32px 0px 52px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      //image-rendering:-moz-crisp-edges;
      //image-rendering:-o-crisp-edges;
      //image-rendering:-webkit-optimize-contrast;
      //image-rendering: crisp-edges;
      //-ms-interpolation-mode:nearest-neighbor;
    }
  }
  .text {
    margin-bottom: 48px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep.ant-btn-primary {
      width: 100%;
      font-family: 'GT America Trial Ext Md';
      font-size: 16px;
      font-weight: 700;
      margin-right: 12px;
      height: 64px;
      border-radius: 20px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }
    ::v-deep .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      border: 1px solid transparent;
      background: #aa68f0cc !important;
      color: #ffffff !important;
    }
    ::v-deep.disabledtrue.ant-btn-disabled,
    .ant-btn.disabled,
    .ant-btn[disabled],
    .ant-btn-disabled:hover,
    .ant-btn.disabled:hover,
    .ant-btn[disabled]:hover,
    .ant-btn-disabled:focus,
    .ant-btn.disabled:focus,
    .ant-btn[disabled]:focus,
    .ant-btn-disabled:active,
    .ant-btn.disabled:active,
    .ant-btn[disabled]:active,
    .ant-btn-disabled.active,
    .ant-btn.disabled.active,
    .ant-btn[disabled].active {
      font-family: 'GT America Trial Ext Md';
      color: white;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
      height: 64px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
</style>
