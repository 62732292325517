<template>
  <footer class="footer" :class="{ 'footer-dark': themetype }">
    <div class="footer-flex">
      <div class="footer-left">© 2023 Cedro Labs All rights reserved</div>

      <div class="footer-right">
        <div
          @mouseenter="iconHighlight = false"
          @mouseleave="iconHighlight = true"
          @click="openTwitter"
        >
          <img
            src="../../../assets/image/bottom/new/twitter.svg"
            v-if="iconHighlight"
          />
          <img src="../../../assets/image/bottom/twitterhover.svg" v-else />
        </div>
        <div
          @mouseenter="iconHighlight2 = false"
          @mouseleave="iconHighlight2 = true"
          @click="openTelegram"
        >
          <img
            src="../../../assets/image/bottom/new/telegram.svg"
            v-if="iconHighlight2"
          />
          <img src="../../../assets/image/bottom/telegrmhover.svg" v-else />
        </div>
        <div
          @mouseenter="iconHighlight3 = false"
          @mouseleave="iconHighlight3 = true"
          @click="openDiscord"
        >
          <img
            src="../../../assets/image/bottom/new/discord.svg"
            v-if="iconHighlight3"
          />
          <img src="../../../assets/image/bottom/Grouphover.svg" v-else />
        </div>
        <div
          @mouseenter="iconHighlight4 = false"
          @mouseleave="iconHighlight4 = true"
          @click="openMedium"
        >
          <img
            src="../../../assets/image/bottom/new/medium.svg"
            v-if="iconHighlight4"
          />
          <img src="../../../assets/image/bottom/medium2.svg" v-else />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters(['themetype']),
  },
  data() {
    return {
      iconHighlight: true,
      iconHighlight2: true,
      iconHighlight3: true,
      iconHighlight4: true,
    };
  },
  methods: {
    openTelegram() {
      window.open('https://t.me/cedrofinance');
    },
    openTwitter() {
      window.open('https://twitter.com/cedro_finance');
    },
    openDiscord() {
      window.open('https://discord.gg/R2KmG7bSEm');
    },
    openMedium() {
      window.open('https://medium.com/@cedrolabs');
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 28px 55px;
  background-color: rgba(255, 255, 255, 0.15);
  position: absolute;
  bottom: 0;
  width: 100%;
  .footer-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footer-left {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: #141414;
    }
    .footer-right {
      display: flex;
      align-items: center;
      gap: 21px;

      img {
        cursor: pointer;
      }
    }
  }

  &-dark {
    background-color: transparent;
    .footer-flex {
      .footer-left {
        color: #abacb3;
      }
      .footer-right {
        img {
          filter: invert(75%);
          &:hover {
            filter: invert(0%);
          }
        }
      }
    }
  }
}
</style>
