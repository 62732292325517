<template>
<div>
  <a-modal v-model="visible" :footer="false" width="480px" wrapClassName="Complete" :afterClose="closemodel" :centered="true">
    <template slot="closeIcon">
      <img src="../assets/image/icon/Close_L2.svg" style="margin-right: 18px;margin-top: 18px" >
    </template>
    <div class="Complete-title">
      <div>{{Complete.title}}!</div>
    </div>
    <div class="Complete-list" >
      <div v-if="Complete.settype===1">
        <div class="d1">
          <div class="p1">Supplied</div>
          <div class="p2">{{Complete.input}} {{Complete.tokenname}}</div>
        </div>
        <div class="d1">
          <div  class="p1">Supply  APY</div>
          <div  class="p2">{{Complete.apy}} % </div>
        </div>
        <div class="d1">
          <div class="p1">You Received </div>
          <div class="p2">{{Complete.Received}} {{Complete.tokenname}}</div>
        </div>

        <div class="d1" style="border-bottom: none">
          <div class="p1">Tx Hash</div>
          <div  class="p2" >
            <span>{{ Complete.hash}}</span>
            <div  @mouseenter="iconHighlight=false" @mouseleave="iconHighlight=true">
              <img src="../assets/image/wallect/hashicon.svg" v-if="iconHighlight">
              <img src="../assets/image/wallect/hashicon2.svg" v-else>
            </div>
          </div>
        </div>
      </div>
      <div v-if="Complete.settype===2" >
        <div class="d1">
          <div  class="p1">Borrowed</div>
          <div  class="p2">{{Complete.input}} {{Complete.tokenname}}</div>
        </div>
        <div class="d1">
          <div  class="p1">Borrow APY</div>
          <div  class="p2">{{Complete.Utilization}} %</div>
        </div>
        <div class="d1">
          <div  class="p1">You Received</div>
          <div  class="p2">{{Complete.apy}} %</div>
        </div>

        <div class="d1" style="border-bottom: none">
          <div  class="p1">Tx Hash</div>
          <div  class="p2" >
            <span>{{ Complete.hash === "" ? "": Complete.hash.slice(0, 6) + "..." + Complete.hash.slice(-4) }}</span>
            <div  @mouseenter="iconHighlight=false" @mouseleave="iconHighlight=true">
              <img src="../assets/image/wallect/hashicon.svg" v-if="iconHighlight">
              <img src="../assets/image/wallect/hashicon2.svg" v-else >
            </div>
          </div>
        </div>
      </div>
      <div v-if="Complete.settype===3 ">
        <div class="d1">
          <div  class="p1">Withdrawn Collateral</div>
          <div  class="p2">{{Complete.input}} {{Complete.tokenname}}</div>
        </div>
        <div class="d1">
          <div  class="p1">Burned</div>
          <div  class="p2">{{toPrecision(Complete.Utilization,2)}} %</div>
        </div>
        <div class="d1" style="border-bottom: none">
          <div  class="p1">Tx Hash</div>
          <div  class="p2" >
            <span>{{ Complete.hash === "" ? "": Complete.hash.slice(0, 6) + "..." + Complete.hash.slice(-4) }}</span>
            <div  @mouseenter="iconHighlight=false" @mouseleave="iconHighlight=true">
              <img src="../assets/image/wallect/hashicon.svg" v-if="iconHighlight">
              <img src="../assets/image/wallect/hashicon2.svg" v-else>
            </div>
          </div>
        </div>
      </div>
      <div v-if="Complete.settype===4">
        <div class="d1">
          <div  class="p1">Repaid Amount</div>
          <div  class="p2">{{Complete.input}} {{Complete.tokenname}}</div>
        </div>
        <div class="d1">
          <div  class="p1">Burned</div>
          <div  class="p2">{{Complete.Utilization}} %</div>
        </div>
        <div class="d1" style="border-bottom: none">
          <div  class="p1">Tx Hash</div>
          <div  class="p2">
            <span>{{ Complete.hash === "" ? "": Complete.hash.slice(0, 6) + "..." + Complete.hash.slice(-4) }}</span>
            <div  @mouseenter="iconHighlight=false" @mouseleave="iconHighlight=true">
              <img src="../assets/image/wallect/hashicon.svg" v-if="iconHighlight">
              <img src="../assets/image/wallect/hashicon2.svg" v-else>
            </div>
          </div>
        </div>
      </div>
      <a-button type="primary" @click="closemodel" class="btn1">
        Confirm
      </a-button>
    </div>

  </a-modal>
</div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Complete",
  props:{
    settype:Number,
    Complete:Object


  },
  computed: {
    ...mapGetters(["account",]),
  },
  data(){
    return{
      iconHighlight:true,
      type:1,
      visible:true,
    }
  },
  watch:{

  },
  created() {
    this.$bus.emit("changeInfo",1)

    },
  methods:{
    // openhash(index){
    //   // let url= getScanUrl(index)
    //   // window.open(url)
    // },
    closemodel(){
      this.visible=false

      if(this.setShow){
        this.setShow(false);
      }

    },
  }
}
</script>

<style scoped lang="scss">
.Complete{

  .Complete-title{
    text-align: center;
    div{
      margin-top: 4px;
      font-weight: 700;
      font-size: 22px;
      color: #ffffff;
    }

  }
  .Complete-list{

    margin-top: 32px;
    .d1{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2) ;
      padding-bottom: 20px;
      margin-bottom: 14px;
      .p1{
        font-family: "GT America Trial Exp Bd";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
      }
      .p2{
        font-family: "GT America Trial Ext Rg";
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #FFFFFF;

        span{
          font-family: "GT America Trial Ext Rg";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #FFFFFF;
        }
      }
    }
  }


}
::v-deep.ant-btn-primary.btn1{
  width: 100%;
  font-family: "GT America Trial Ext bd";
  font-size: 18px;
  font-weight: 600;
  margin-right: 12px;
  height: 64px;
  border-radius: 20px;
  color: #000000;
  background: #ffffff;
  border-color: transparent;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
::v-deep .btn1.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #AA68F0 !important;
  background: #F4ECFD !important;
}
::v-deep .ant-modal-body {
  padding: 32px !important;
}
::v-deep .ant-modal-content {
  border-radius: 20px;
}
::v-deep .ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  background: #AA68F0;
  border-radius: 20px;
}
</style>
