<template>
  <div class="liquidation-main" v-if="account !== ''">
    <a-tabs default-active-key="1" :tabBarGutter="35" type="card">
      <a-tab-pane key="1">
        <template #tab>
          <span class="tab-header">
            <img
              src="../../../assets/image/icon/new/CollateralList.svg"
              alt=""
              width="30px"
              height="30px"
              class="tab-header-icon"
            />
            <img
              src="../../../assets/image/icon/new/CollateralList2.svg"
              alt=""
              width="30px"
              height="30px"
              class="tab-header-icon-inactive"
            />
            To Be Liquidated
          </span>
        </template>
        <div class="LiquidationList liquidation-table">
          <div class="list-header">
            <div class="table-list-row">
              <div class="table-list-item w1">Address</div>
              <div class="table-list-item w2">Collateral</div>
              <div class="table-list-item w3">Liquidator Gets</div>
            </div>
          </div>
          <div class="list-body" v-if="true">
            <!-- TODO Remove This Start -->
            <div
              class="table-list-row"
              v-for="(item, index) in [1, 2, 3, 4, 5]"
              :key="index"
            >
              <div class="table-list-item address">
                <div class="name">0x63c6c7...EFfb350</div>
                <div
                  class="clipboard"
                  v-clipboard:success="copSuccess"
                  v-clipboard:copy="item.toString()"
                >
                  <img
                    src="../../../assets/image/wallect/headercopy.svg"
                    height="24px"
                    width="24px"
                    v-if="!themetype"
                    alt=""
                    class="clipboard-icon"
                  />
                  <img
                    src="../../../assets/image/dark/copy4.svg"
                    height="24px"
                    width="24px"
                    v-if="themetype"
                    alt=""
                    class="clipboard-icon"
                  />
                  <img
                    src="../../../assets/image/wallect/headercopy2.svg"
                    height="24px"
                    width="24px"
                    v-else
                    alt=""
                    class="clipboard-icon-hover"
                  />
                </div>
              </div>
              <div class="table-list-item collateral">
                <div class="token">1,000 ETH</div>
                <div class="dollar">$400,000</div>
              </div>
              <div class="table-list-item liquidator-gets">
                <div class="token">900 BNB</div>
                <div class="dollar">$400,000</div>
              </div>
            </div>

            <!-- TODO Remove This Ends -->

            <div
              class="table-list-row"
              v-for="(item, index) in liquidated"
              :key="index"
            >
              <div class="table-list-item address">
                <div class="name">
                  {{
                    item.address === ''
                      ? ''
                      : item.address.slice(0, 3) +
                        '...' +
                        item.address.slice(-4)
                  }}
                </div>
                <div
                  class="clipboard"
                  v-clipboard:success="copSuccess"
                  v-clipboard:copy="item.address"
                >
                  <img
                    src="../../../assets/image/wallect/headercopy.svg"
                    height="24px"
                    width="24px"
                    v-if="!themetype"
                    alt=""
                    class="clipboard-icon"
                  />
                  <img
                    src="../../../assets/image/dark/copy4.svg"
                    height="24px"
                    width="24px"
                    v-if="themetype"
                    alt=""
                    class="clipboard-icon"
                  />
                  <img
                    src="../../../assets/image/wallect/headercopy2.svg"
                    height="24px"
                    width="24px"
                    v-else
                    alt=""
                    class="clipboard-icon-hover"
                  />
                </div>
              </div>
              <div class="table-list-item collateral">
                <div class="token">{{ item.collateral }} ETH</div>
                <div class="dollar">$ {{ item.CollateralPrice }}</div>
              </div>
              <div class="table-list-item liquidator-gets">
                <div class="token">{{ item.get }} {{ item.getname }}</div>
                <div class="dollar">${{ item.getprice }}</div>
              </div>
            </div>
            <!-- <div
              class="table-list-row"
              v-for="(item, index) in CollateralData.filter(
                (t) => t.CollateralToken > 0
              )"
              :key="index"
            >
              <div class="table-list-item address">
                <div class="icon">
                  <img :src="item.icon" alt="" width="30px" height="30px" />
                </div>
                <div class="name">{{ item.TokenName }}</div>
              </div>
              <div class="table-list-item collateral">
                <div class="token">
                  {{ toFixedCutZero(item.CollateralToken, 2, true) }}
                  {{ item.TokenName }}
                </div>
                <div class="dollar">
                  $
                  {{ toFixedCutZero(item.Collateral, 2, true) }}
                </div>
              </div>
              <div class="table-list-item liquidator-gets">
                <div class="token">
                  {{ toFixedCutZero(item.CollateralToken, 2, true) }}
                  {{ item.TokenName }}
                </div>
                <div class="dollar">
                  $
                  {{ toFixedCutZero(item.Collateral, 2, true) }}
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" force-render>
        <template #tab>
          <span class="tab-header">
            <img
              src="../../../assets/image/icon/new/BorrowList.svg"
              alt=""
              width="30px"
              height="30px"
              class="tab-header-icon"
            />
            <img
              src="../../../assets/image/icon/new/BorrowList2.svg"
              alt=""
              width="30px"
              height="30px"
              class="tab-header-icon-inactive"
            />
            History
          </span>
        </template>
        <div class="LiquidationHistory liquidation-table">
          <div class="list-header">
            <div class="table-list-row">
              <div class="table-list-item w1">Date</div>
              <div class="table-list-item w2">Address</div>
              <div class="table-list-item w3">Liquidated</div>
              <div class="table-list-item w4">Liquidation Cost</div>
              <div class="table-list-item w5">Profit</div>
            </div>
          </div>
          <div class="list-body" v-if="showlist2">
            <div
              class="table-list-row"
              v-for="(item, index) in [1, 2, 3, 4, 5]"
              :key="index"
            >
              <div class="table-list-item date">December 28, 2022 16:00</div>
              <div class="table-list-item address">
                <div class="name">0x63c6c7...EFfb350</div>
                <div
                  class="clipboard"
                  v-clipboard:success="copSuccess"
                  v-clipboard:copy="item.toString()"
                >
                  <img
                    src="../../../assets/image/wallect/headercopy.svg"
                    height="24px"
                    width="24px"
                    v-if="!themetype"
                    alt=""
                    class="clipboard-icon"
                  />
                  <img
                    src="../../../assets/image/dark/copy4.svg"
                    height="24px"
                    width="24px"
                    v-if="themetype"
                    alt=""
                    class="clipboard-icon"
                  />
                  <img
                    src="../../../assets/image/wallect/headercopy2.svg"
                    height="24px"
                    width="24px"
                    v-else
                    alt=""
                    class="clipboard-icon-hover"
                  />
                </div>
              </div>

              <div class="table-list-item liquidated">
                <div class="token">1,000 ETH</div>
                <div class="dollar">$400,000</div>
              </div>

              <div class="table-list-item liquidation-cost">
                <div class="token">3,500,000 ETH</div>
                <div class="dollar">$3,500,000</div>
              </div>

              <div class="table-list-item profit">$500,000</div>
            </div>
            <!-- <div
              class="table-list-row"
              v-for="(item, index) in CollateralData.filter(
                (t) => t.BorrowedToken > 0
              )"
              :key="index"
            >
              <div class="table-list-item date">
                <div class="icon">
                  <img :src="item.icon" alt="" width="30px" height="30px" />
                </div>
                <div class="name">{{ item.TokenName }}</div>
              </div>
              <div class="table-list-item address">{{ item.chain.name }}</div>

              <div class="table-list-item liquidated">
                $
                <div class="token">
                  {{ toFixedCutZero(item.BorrowedToken, 2, true) }}
                  {{ item.TokenName }}
                </div>
                <div class="dollar">
                  $ {{ toFixedCutZero(item.Borrowed, 2, true) }}
                </div>
              </div>

              <div class="table-list-item liquidation-cost">
                <div class="token">
                  {{ toFixedCutZero(item.BorrowedToken, 2, true) }}
                  {{ item.TokenName }}
                </div>
                <div class="dollar">
                  $ {{ toFixedCutZero(item.Borrowed, 2, true) }}
                </div>
              </div>

              <div class="table-list-item profit">
                <div class="token">
                  {{ toFixedCutZero(item.BorrowedToken, 2, true) }}
                  {{ item.TokenName }}
                </div>
                <div class="dollar">
                  $ {{ toFixedCutZero(item.Borrowed, 2, true) }}
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getScanUrl,
  getUserLiquidated,
  toFixedCutZero,
  toFixedNumber,
} from '../../../SDK';

export default {
  name: 'Liquidations',
  components: {},
  computed: {
    ...mapGetters(['account', 'themetype', 'equipment']),
  },
  data() {
    return {
      toFixedNumber: toFixedNumber,
      toFixedCutZero: toFixedCutZero,
      showlist: true,
      showlist2: true,
      loadingpage: true,
      liquidated: [
        {
          address: '',
          collateral: 0,
          collateralname: '-',
          CollateralPrice: 0,
          get: 0,
          getname: '-',
          getprice: 0,
          iconHighlight: true,
          iconHighlight2: true,
        },
        {
          address: '',
          collateral: 0,
          collateralname: '-',
          CollateralPrice: 0,
          get: 0,
          getname: '-',
          getprice: 0,
          iconHighlight: true,
          iconHighlight2: true,
        },
        {
          address: '',
          collateral: 0,
          collateralname: '-',
          CollateralPrice: 0,
          get: 0,
          getname: '-',
          getprice: 0,
          iconHighlight: true,
          iconHighlight2: true,
        },
      ],
      History: [
        {
          iconHighlight: true,
          iconHighlight2: true,
          date: 'December 28, 2022 16:00',
          address: '0x63c6c7...EFfb350',
          Liquidated: '10,000',
          LiquidatedName: 'FTM',
          LiquidatedPrice: '4,000,000',
          Cost: '3,500,000',
          CostName: 'ETH',
          Costprice: '3,500,000',
          profit: '500,000',
        },
      ],
    };
  },
  created() {
    this.liquidatedInfo();
  },
  methods: {
    openurl(index) {
      let url = getScanUrl(index, 80001);
      window.open(url);
    },

    async liquidatedInfo() {
      this.loadingpage = true;
      let list = await getUserLiquidated();
      if (list.length > 0) {
        this.showlist = true;
        this.liquidated = list.map((item) => {
          this.loadingpage = false;
          return {
            address: item.user,
            collateral: +item.collateral,
            collateralname: +item.collateral,
            CollateralPrice: item.collateralValue,
            get: item.gates,
            getname: item.symbol,
            getprice: item.gatesValue,
            iconHighlight: true,
            iconHighlight2: true,
          };
        });
      } else {
        this.showlist = false;
      }
    },
    openhash(index) {
      let url = getScanUrl(index);
      window.open(url);
    },
    copSuccess() {
      this.$message.success('copy success');
    },
  },
};
</script>

<style scoped lang="scss">
.liquidation-main {
  background-color: #faf7ff;
  border-radius: 24px 24px 0px 0px;
  padding: 54px 84px;

  ::v-deep .ant-tabs-bar {
    border-bottom: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tab-header {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .ant-tabs-tab,
    .ant-tabs-tab-active {
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      color: var(--tabheadercolor);
      border: none;
      background-color: transparent;
      padding: 0px;

      .tab-header-icon {
        display: none;
      }
    }

    .ant-tabs-tab-active {
      color: var(--tabheaderactivecolor);
      .tab-header-icon {
        display: block;
        &-inactive {
          display: none;
        }
      }
    }
  }

  .liquidation-table {
    .table-list-row {
      display: flex;
      align-items: center;
      gap: 2px;
      justify-content: space-between;
      padding: 16px;

      &:hover {
        border-radius: 20px;
        background-color: var(--tablehoverbg);
      }

      .table-list-item {
        &.address {
          display: flex;
          align-items: center;
          gap: 12px;
          .name {
            color: var(--tabletext);
            font-size: 18px;
            font-weight: 700;
            line-height: 100%; /* 18px */
            letter-spacing: -0.18px;
            text-align: left;
          }

          .clipboard {
            cursor: pointer;
            .clipboard-icon {
              display: block;
            }
            .clipboard-icon-hover {
              display: none;
            }
            &:hover {
              .clipboard-icon {
                display: none;
              }
              .clipboard-icon-hover {
                display: block;
              }
            }
          }
        }
        &.date {
          color: var(--tabletext);
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 133.333% */
          letter-spacing: -0.18px;
          text-align: center;
          opacity: 0.8;
        }

        &.profit {
          color: var(--tabletext);
          font-size: 18px;
          font-weight: 500;
          line-height: 100%; /* 18px */
          letter-spacing: -0.18px;
          text-align: right;
        }

        &.collateral,
        &.liquidated,
        &.liquidator-gets,
        &.liquidation-cost {
          text-align: right;
          .token {
            color: var(--tabletext);
            font-size: 18px;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
            letter-spacing: -0.18px;
          }
          .dollar {
            color: var(--tabletext);
            font-size: 14px;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.28px;
            opacity: 0.8;
          }
        }
      }
    }

    ::v-deep.list-header {
      font-size: 15px;
      font-weight: 700;
      line-height: 100%;
      margin-top: 30px;
      border-radius: 20px;
      color: var(--tableheadertext);
      background: var(--tableheaderbg);
      box-shadow: 0px 0px 0px 0px rgba(217, 214, 255, 0.05),
        0px 5px 11px 0px rgba(217, 214, 255, 0.05),
        0px 19px 19px 0px rgba(217, 214, 255, 0.04),
        0px 44px 26px 0px rgba(217, 214, 255, 0.03),
        0px 78px 31px 0px rgba(217, 214, 255, 0.01),
        0px 121px 34px 0px rgba(217, 214, 255, 0);
    }
    .list-header {
      .table-list-row {
        padding: 30px 16px;
      }
    }

    .list-body {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
      letter-spacing: -0.18px;
      color: var(--tableheadertext);
      min-height: 40vh;
    }

    .empty-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding: 24px;
      margin: 64px auto;
      border-radius: 20px;
      background-color: var(--tableheaderbg);
      width: fit-content;
      color: var(--tableheadertext);

      h2 {
        color: var(--tableheadertext);
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
      p {
        display: flex;
        align-items: center;
        padding: 0 32px;

        .chips {
          margin: 0 6px;
        }
      }
    }

    &.LiquidationList {
      .table-list-row {
        .table-list-item {
          &:nth-child(1) {
            width: 33%;
          }
          &:nth-child(2) {
            width: 33%;
            text-align: right;
          }
          &:nth-child(3) {
            width: 33%;
            text-align: right;
          }
        }
      }
    }

    &.LiquidationHistory {
      .table-list-row {
        .table-list-item {
          &:nth-child(1) {
            width: 25%;
            text-align: left;
          }
          &:nth-child(2) {
            width: 20%;
            text-align: left;
          }
          &:nth-child(3) {
            width: 20%;
            text-align: right;
          }
          &:nth-child(4) {
            width: 20%;
            text-align: right;
          }
          &:nth-child(5) {
            width: 15%;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
