<template>
  <Modal @close-modal="closeModal()" modalWidth="500px">
    <div class="txn-complete">
      <template slot="closeIcon">
        <div class="close-icon-wrapper">
          <img src="../../../assets/image/dark/Close_L3.svg" v-if="themetype" />
          <img src="../../../assets/image/icon/Close_L.svg" v-else />
        </div>
      </template>

      <div class="modal-content">
        <div class="modal-header">
          <div class="title">
            <div class="header-img">
              <img
                src="../../../assets/image/icon/new/modal/txnComplete.svg"
                alt="Transaction Complete"
                width="24px"
                height="24px"
              />
            </div>
            <div class="">Complete</div>
          </div>
        </div>

        <div class="modal-body">
          <table class="txn-complete-table">
            <tr v-for="item in tableHeaders[modalType]" :key="item.title">
              <td class="title">{{ item.title }}</td>
              <td class="value">{{ values[item.value] }}</td>
            </tr>

            <tr>
              <td class="title">TxHash</td>
              <td class="txn-hash-value">
                {{ hash.slice(0, 3) + '...' + hash.slice(-4) }}
              </td>
            </tr>
          </table>
        </div>

        <div class="modal-footer">
          <Button showIcon :handleClick="closeModal">Done</Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { getHashStatus, toFixedCutZero } from '../../../SDK';

import Modal from '../../../components/new/Modal/index';
import Button from '../../../components/new/Btn/index';

export default {
  name: 'TxnComplete',
  components: { Modal, Button },
  computed: {
    ...mapGetters(['themetype']),
  },
  data() {
    return {
      showHash: false,
      toFixedCutZero: toFixedCutZero,

      tableHeaders: {
        supply: [
          { title: 'Supplied', value: 'supplied' },
          { title: 'Supply APY', value: 'supplyAPY' },
          { title: 'You Received', value: 'received' },
        ],
        borrow: [
          { title: 'Borrowed', value: 'borrowed' },
          { title: 'Borrow APY', value: 'borrowAPY' },
          { title: 'You Received', value: 'received' },
        ],
        withdraw: [
          { title: 'Withdrawn Collateral', value: 'withdrawnCollateral' },
          { title: 'Burned', value: 'burned' },
        ],
        repay: [
          { title: 'Repaid Amount', value: 'repaidAmount' },
          { title: 'Burned', value: 'burned' },
        ],
      },
    };
  },
  props: {
    setShow: Function,
    modalType: String,
    values: Object,
    hash: String,
  },
  created() {},
  methods: {
    closeModal() {
      if (this.setShow) {
        this.setShow(false);
      }
    },
    openhash(index) {
      getHashStatus(index).then((i) => {
        if (i[0]?.url) {
          window.open(i[0].url);
        }
      });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.txn-complete {
  .modal-content {
    margin: 20px 15px;

    .modal-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 32px;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 18px;
        position: relative;
        z-index: 3;
        font-size: 26px;
        font-weight: 700;
        color: #141414;
        line-height: 100%;

        .header-img {
          padding: 9px;
          border-radius: 112.5px;
          border: 1.125px solid rgba(255, 255, 255, 0.17);
          background: linear-gradient(212deg, #9bff93 10.56%, #e8ffb7 93.19%);
          box-shadow: 0px 0px 0px 0px rgba(210, 157, 193, 0.06),
            0px 1px 3px 0px rgba(210, 157, 193, 0.06),
            1px 5px 5px 0px rgba(210, 157, 193, 0.05),
            3px 10px 7px 0px rgba(210, 157, 193, 0.03),
            6px 19px 8px 0px rgba(210, 157, 193, 0.01),
            9px 29px 9px 0px rgba(210, 157, 193, 0);
        }
      }
    }

    .modal-body {
      .txn-complete-table {
        width: 100%;
        tr {
          td {
            padding-bottom: 16px;

            &.title {
              font-size: 15px;
              font-weight: 700;
              line-height: 100%;
              color: #14141480;
              text-align: left;
            }
            &.value {
              text-align: left;
              font-size: 15px;
              font-weight: 700;
              line-height: 100%;
              color: #141414;
              text-align: right;
            }

            &.txn-hash-value {
              font-size: 15px;
              font-style: normal;
              font-weight: 900;
              line-height: 16px;
              color: #7268ff;
              text-align: right;
            }
          }
        }
      }

      .txn-hash {
        display: flex;
        justify-content: space-between;

        .txn-hash-title {
          font-size: 15px;
          font-weight: 700;
          line-height: 100%;
          color: #141414;
        }
        .txn-hash-value {
          font-size: 15px;
          font-style: normal;
          font-weight: 900;
          line-height: 16px;
          color: #7268ff;
        }
      }

      .txn-hash-loading {
        text-align: center;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
</style>
