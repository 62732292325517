<template>
  <div>
    <div v-if="showmodal">
      <a-modal
        v-model="visible"
        :footer="false"
        width="480px"
        :wrapClassName="themetype === true ? 'borrow-dark' : 'borrow'"
        :afterClose="closemodel"
        :centered="true"
        :style="{ '--bg': bg }"
      >
        <template slot="closeIcon">
          <img
            src="../../../assets/image/dark/Close_L3.svg"
            style="margin-right: 18px; margin-top: 18px"
            v-if="themetype"
          />
          <img
            src="../../../assets/image/icon/Close_L.svg"
            style="margin-right: 18px; margin-top: 18px"
            v-else
          />
        </template>
        <div class="title">Borrow</div>

        <div class="header-input">
          <div
            @mouseenter="iconHighlight = false"
            @mouseleave="iconHighlight = true"
          >
            <img
              src="../../../assets/image/icon/maxG.svg"
              class="max"
              v-if="iconHighlight"
            />
            <img
              src="../../../assets/image/icon/max.svg"
              class="max"
              v-else
              @click="maxinput"
            />
          </div>
          <div
            :style="
              'position: relative;display: flex;justify-content: space-between;align-items:' +
              align
            "
            v-if="themetype"
          >
            <div
              :style="
                'position: relative;right: 0;bottom: -7px;font-style: normal;font-weight: 700;font-size: 34px;line-height:-' +
                textheight +
                'px;color: #ffffff;font-family: GT America Trial Ext Bd'
              "
            >
              {{ money }}
            </div>
            <!--            <a-input v-model="inputNumber" placeholder="0" :style="'width:'+widthinput+'px;'+'zoom:'+inputScale" @change="inputmax" type="number"   min="-999999999" max="9999999999" :step="0.0000001" />-->
            <div class="9999" style="text-align: center; position: relative">
              <div
                :style="
                  'position: relative;display: inline-block;background: transparent;height:' +
                  inputheight +
                  'px;text-align: left;'
                "
              >
                <a-input
                  :style="
                    'position: absolute;width: 100%;background: transparent;padding: 0;zoom:' +
                    inputScale
                  "
                  v-model="inputNumber"
                  placeholder="0"
                  @change="inputmax"
                  type="text"
                ></a-input>
                <span
                  :style="
                    'color: transparent;font-size: 48px;font-weight: 700;font-family: GT America Trial Ext Bd; display: block;min-width: 45px;zoom:' +
                    inputScale
                  "
                  >{{ inputNumber }}</span
                >
              </div>
            </div>
            <div
              :style="
                'position: relative;right: 0;bottom: -7px;font-style: normal;font-weight: 700;font-size: 30px;line-height:-' +
                textheight +
                'px;color: #ffffff;margin-left:' +
                tokennamemargin +
                'px'
              "
            >
              {{ token1 }}
            </div>
          </div>
          <div
            :style="
              'position: relative;display: flex;justify-content: space-between;align-items:' +
              align
            "
            v-else
          >
            <div
              :style="
                'position: relative;right: 0;bottom: -7px;font-style: normal;font-weight: 700;font-size: 34px;line-height:-' +
                textheight +
                'px;color: #000000;font-family: GT America Trial Ext Bd'
              "
            >
              {{ money }}
            </div>
            <!--            <a-input v-model="inputNumber" placeholder="0" :style="'width:'+widthinput+'px;'+'zoom:'+inputScale" @change="inputmax" type="number"   min="-999999999" max="9999999999" :step="0.0000001" />-->
            <div class="9999" style="text-align: center; position: relative">
              <div
                :style="
                  'position: relative;display: inline-block;background: transparent;height:' +
                  inputheight +
                  'px;text-align: left;'
                "
              >
                <a-input
                  :style="
                    'position: absolute;width: 100%;background: transparent;padding: 0;zoom:' +
                    inputScale
                  "
                  v-model="inputNumber"
                  placeholder="0"
                  @change="inputmax"
                  type="text"
                ></a-input>
                <span
                  :style="
                    'color: transparent;font-size: 48px;font-weight: 700;font-family: GT America Trial Ext Bd; display: block;min-width: 45px;zoom:' +
                    inputScale
                  "
                  >{{ inputNumber }}</span
                >
              </div>
            </div>
            <div
              :style="
                'position: relative;right: 0;bottom: -7px;font-style: normal;font-weight: 700;font-size: 30px;line-height:-' +
                textheight +
                'px;color: #000000;margin-left:' +
                tokennamemargin +
                'px'
              "
            >
              {{ token1 }}
            </div>
          </div>
          <div
            @mouseenter="iconHighlight2 = false"
            @mouseleave="iconHighlight2 = true"
          >
            <img
              src="../../../assets/image/icon/switchG.svg"
              class="sw"
              v-if="iconHighlight2"
            />
            <img
              src="../../../assets/image/icon/switchG2.svg"
              class="sw"
              v-else
              @click="Switchupdown()"
            />
          </div>
        </div>
        <!--        <div class="price">${{borrow.price}}</div>-->
        <div class="price">
          {{ money2 }}{{ toFixedNumber(borrow.price, 2, true) }}{{ token2 }}
        </div>
        <div class="list">
          <div class="d1">
            <div>New Borrow limit</div>
            <div>
              <div class="s1">${{ toFixedNumber(borrow.limit2, 2, true) }}</div>
              <img
                src="../../../assets/image/dark/SmallArrow2.svg"
                width="12px"
                height="12px"
                v-if="themetype"
              />
              <img
                src="../../../assets/image/icon/SmallArrow.svg"
                width="12px"
                height="12px"
                v-else
              />
              <div class="s2">${{ toFixedNumber(borrow.limit, 2, true) }}</div>
            </div>
          </div>
          <div class="d1">
            <div>Borrow APY</div>
            <div>{{ toFixedCutZero(borrow.apy, 2, false) }}%</div>
          </div>
          <div class="d1">
            <div>You Will Receive</div>
            <div>
              {{ borrow.receive }} {{ borrow.token.replace('test', 'debt') }}
            </div>
          </div>
          <div class="d1">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <span>Transaction Fee</span>
              <a-popover :title="false" placement="top">
                <template #content>
                  <p
                    style="
                      font-weight: 400;
                      font-size: 10px;
                      color: #8f9092;
                      font-family: 'GT America Trial Ext Rg';
                      padding-top: 15px;
                      width: 169px;
                    "
                  >
                    The cost required to use layerzero for cross-chain,related
                    to dest chain coin price.<br />
                  </p>
                </template>
                <div
                  @mouseenter="iconHighlight5 = false"
                  @mouseleave="iconHighlight5 = true"
                  v-if="themetype"
                >
                  <img
                    src="../../../assets/image/dark/question3.svg"
                    style="cursor: pointer"
                    v-if="iconHighlight5"
                  />
                  <img
                    src="../../../assets/image/icon/question2.svg"
                    style="cursor: pointer"
                    v-else
                  />
                </div>
                <div
                  @mouseenter="iconHighlight5 = false"
                  @mouseleave="iconHighlight5 = true"
                  v-else
                >
                  <img
                    src="../../../assets/image/icon/question.svg"
                    style="cursor: pointer"
                    v-if="iconHighlight5"
                  />
                  <img
                    src="../../../assets/image/icon/question2.svg"
                    style="cursor: pointer"
                    v-else
                  />
                </div>
              </a-popover>
            </div>
            <div>{{ borrow.fee }} {{ feetoken() }}</div>
          </div>
        </div>
        <div>
          <div class="title3">
            <span>Health Factor</span>
            <a-popover :title="false" placement="top">
              <template #content>
                <p
                  style="
                    font-weight: 400;
                    font-size: 10px;
                    color: #8f9092;
                    font-family: 'GT America Trial Ext Rg';
                    padding-top: 15px;
                    width: 169px;
                  "
                >
                  It is the ratio of the Collateral value multiplied by the LTV,
                  and the borrowed value. H.F. 1 is considered unhealthy. So the
                  collateral will be available for liquidation.<br />
                </p>
              </template>
              <div
                @mouseenter="iconHighlight3 = false"
                @mouseleave="iconHighlight3 = true"
                v-if="themetype"
              >
                <img
                  src="../../../assets/image/dark/question3.svg"
                  style="cursor: pointer"
                  v-if="iconHighlight3"
                />
                <img
                  src="../../../assets/image/icon/question2.svg"
                  style="cursor: pointer"
                  v-else
                />
              </div>
              <div
                @mouseenter="iconHighlight3 = false"
                @mouseleave="iconHighlight3 = true"
                v-else
              >
                <img
                  src="../../../assets/image/icon/question.svg"
                  style="cursor: pointer"
                  v-if="iconHighlight3"
                />
                <img
                  src="../../../assets/image/icon/question2.svg"
                  style="cursor: pointer"
                  v-else
                />
              </div>
            </a-popover>
          </div>
        </div>
        <div class="slidernubmer">
          <!--          <div>{{toFixedCutZero(borrow.Health,2,false)}}%</div>-->
          <!--          <a-input v-model="borrow.Health"  @change="SliderInput" type="number"/>-->
          <div class="9999" style="text-align: center; position: relative">
            <div
              :style="
                'position: relative;display: inline-block;background: transparent;height:' +
                inputheight2 +
                'px;text-align: left;'
              "
              @mouseenter="iconHighlight4 = false"
              @mouseleave="iconHighlight4 = true"
            >
              <a-input
                :style="'position: absolute;width: 100%;background: transparent;padding: 0'"
                v-model="borrow.Health"
                placeholder="0"
                @change="SliderInput"
                type="number"
                min="0"
                max="9999999999"
                :step="0.0000000000000000000001"
                v-if="iconHighlight4"
              ></a-input>
              <a-input
                :style="'position: absolute;width: 100%;background: transparent;border: #8F9092 solid 0.5px;padding: 0'"
                v-model="borrow.Health"
                placeholder="0"
                @change="SliderInput"
                type="number"
                min="0"
                max="9999999999"
                :step="0.0000000000000000000001"
                v-else
              ></a-input>
              <span
                :style="'color: transparent;font-size: 10px;font-weight: 400;font-family: GT America Trial Ext Bd; display: block;min-width: 18px;padding: 0px 3px;'"
                >{{ borrow.Health }}</span
              >
            </div>
          </div>
          <div class="p1" :style="'height:' + inputheight2">%</div>
        </div>
        <!--        :value="borrow.Health"-->
        <a-slider
          :default-value="1"
          v-model="borrow.Health"
          @change="slide"
          :class="'slider' + showslider"
          :tooltipVisible="false"
        />
        <div class="title4">
          <div>Safer</div>
          <div>Riskier</div>
        </div>
        <div class="title5">
          <div class="l">Current Chain</div>

          <a-dropdown :trigger="['click']" :placement="bottomRight">
            <a-menu slot="overlay">
              <a-menu-item
                v-for="(itme, index) in networklist"
                :key="index"
                @click="choosework(itme)"
              >
                <img
                  :src="itme.icon"
                  style="margin-right: 12px"
                  width="24px"
                  height="24px"
                />
                <div>{{ itme.name }}</div>
              </a-menu-item>
            </a-menu>
            <div class="r">
              <img :src="NetworkName.icon" />
              <div>{{ NetworkName.name }}</div>
              <img
                src="../../../assets/image/dark/dropdownicon2.svg"
                style="width: 13px; height: 8px; margin-left: 5px"
                v-if="themetype"
              />
              <img
                src="../../../assets/image/icon/dropdownicon.svg"
                style="width: 13px; height: 8px; margin-left: 5px"
                v-else
              />
            </div>
          </a-dropdown>
        </div>
        <div class="btn">
          <a-button
            type="primary"
            class="disabledtrue"
            :disabled="true"
            v-if="btndis"
          >
            Borrow
          </a-button>
          <a-button
            type="primary"
            class="btn1"
            @click="borrowF()"
            :loading="btnloading"
            v-else
          >
            Borrow
          </a-button>
        </div>
      </a-modal>
    </div>
    <loading v-if="showloading" :hash="hash"></loading>
    <Complete v-if="shouwcomplete" :Complete="Complete"></Complete>
    <failed
      v-if="showfailed"
      :setShow="setShowModal"
      :failedinfo="failedinfo"
    ></failed>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '../../../components/loadingF';
import Complete from '../../../components/CompleteF';
import {
  borrow,
  estimateFees,
  getLiquidityValue,
  setTransaction,
  toFixedCutZero,
  toFixedNumber,
} from '../../../SDK';
import Failed from '../../../components/failed';

export default {
  name: 'borrow',
  components: { Failed, Loading, Complete },
  props: {
    setShow: Function,
    networklists: Array,
    TypeOperation: Object,
    AllMyNumber: Object,
  },
  computed: {
    ...mapGetters(['account', 'chainid', 'themetype']),
  },
  data() {
    return {
      bg: '#ffffff',
      inputcolor: '#ffffff',
      sliderbg: '#ffffff',
      toFixedNumber: toFixedNumber,
      toFixedCutZero: toFixedCutZero,
      btnloading: false,
      bottomRight: 'bottomLeft',
      showfailed: false,
      showslider: 1,
      maxToken: 0,
      rate: 1 * 0.5,
      failedinfo: {
        balance: 0,
        token: '',
      },
      iconHighlight: true,
      iconHighlight2: true,
      iconHighlight3: true,
      iconHighlight4: true,
      iconHighlight5: true,
      inputheight: 60,
      inputheight2: 24,
      align: 'center',
      align2: 'center',
      tokennamemargin: 10,
      textheight: 68,
      textheight2: 68,
      money: '$',
      money2: '$',
      btndis: true,
      inputtype: true,
      token1: 'ETH',
      token2: '',
      showloading: false,
      shouwcomplete: false,
      showmodal: true,
      widthinput: 64,
      inputScale: 1,
      inputNumber: 0,
      max2: 0,
      visible: true,
      hash: '',
      NetworkName: {},
      letFactor: 0,
      networklist: [
        {
          chainid: 0,
          icon: require('../../../assets/image/icon/FTM.svg'),
          name: 'Fantom',
          id: 1,
        },
        {
          chainid: 0,
          icon: require('../../../assets/image/icon/AVAX.svg'),
          name: 'Avalanche',
          id: 2,
        },
      ],
      setoutid: 0,
      Complete: {
        title: '',
        settype: 0,
        input: 0,
        tokenname: '',
        Utilization: 0,
        hash: '',
        apy: 0,
      },
      poolLimit: 0,
      borrow: {
        token: ' ',
        price: 0,
        limit: 0,
        limit2: 0,
        utilization: 0,
        apy: 0,
        Health: 0,
        Health2: 0,
        receive: 0,
        fee: 0,
      },
    };
  },
  created() {
    this.themetypeF();
    this.BorrowInfo();
    this.inputhelth();
    this.inputhelth2();
    this.maxinput2();
    this.money = '';
    this.money2 = '$';
    this.token1 = this.borrow.token;
    this.token2 = '';
    this.networklist = this.networklists.map((item) => {
      return {
        icon: this.icons(item.chain),
        name: item.chain,
        id: item.chainid,
        chainid: item.chainid,
      };
    });
    this.choosework(this.networklist[0]);
  },
  watch: {
    inputNumber(val) {
      if (!/^(-)?[0-9.]+$/g.test(val.toString())) {
        this.inputNumber = '0';
      }
      if (+this.inputNumber > 0) {
        this.btndis = false;
        if (this.inputNumber.includes('.')) {
          let index = this.inputNumber.indexOf('.');
          this.inputNumber = this.inputNumber.slice(0, index + 8 + 1);
        }
      } else {
        this.btndis = true;
      }
      this.inputNumber = this.toCutFrontZero(this.inputNumber);
    },
  },
  methods: {
    toCutFrontZero(num) {
      let str = num + '';
      if (str.startsWith('.')) {
        str = '0' + str;
      }
      while (str.length >= 2 && str.startsWith('0') && str[1] !== '.') {
        str = str.slice(1);
      }
      return str;
    },
    themetypeF() {
      if (this.themetype) {
        this.bg = `#1D1426`;
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--inputcolor', `#ffffff`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--sliderbg', `#42374D`);
      } else {
        this.bg = `#ffffff`;
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--inputcolor', `#1D1426`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--sliderbg', `#f5f5f5`);
      }
    },
    SliderInput() {
      this.borrow.Health = +this.borrow.Health;
      clearTimeout(this.setoutid);
      this.setoutid = setTimeout(() => {
        this.borrow.Health =
          +this.borrow.Health < this.letFactor
            ? this.letFactor
            : +this.borrow.Health;
        this.slide(+this.borrow.Health);
      }, 1500);
      this.upwidth2();
    },
    Healthsilde() {
      if (this.borrow.Health < 33) {
        this.showslider = 1;
      } else if (this.borrow.Health < 66) {
        this.showslider = 2;
      } else {
        this.showslider = 3;
      }
    },
    inputhelth() {
      let risk = this.TypeOperation.LTS;
      let a = this.AllMyNumber.AllSupply * risk;
      let b = 0;
      if (this.inputtype) {
        b =
          this.AllMyNumber.AllMyborrow +
          this.inputNumber * this.TypeOperation.OraclePrice;
      } else {
        b = this.AllMyNumber.AllMyborrow + +this.inputNumber;
      }
      let c = b / a;
      this.borrow.Health = c * 100;
      this.borrow.Health = this.toFixedCutZero(this.borrow.Health, 2, false);
      this.borrow.Health = +this.borrow.Health;
      this.Healthsilde();
    },
    inputhelth2() {
      let risk = this.TypeOperation.LTS;
      let a = this.AllMyNumber.AllSupply * risk;
      let b = 0;
      if (this.inputtype) {
        b =
          this.AllMyNumber.AllMyborrow +
          this.inputNumber * this.TypeOperation.OraclePrice;
      } else {
        b = this.AllMyNumber.AllMyborrow + +this.inputNumber;
      }
      let c = b / a;
      this.borrow.Health = c * 100;
      this.letFactor = this.borrow.Health;
      this.borrow.Health = this.toFixedCutZero(this.borrow.Health, 2, false);
      this.borrow.Health = +this.borrow.Health;
      this.Healthsilde();
    },
    // icons(name) {
    //   if (name === "Fantom Testnet") {
    //     return require("../../../assets/image/icon/FTM.svg")
    //   } else if (name === "Avalanche Testnet") {
    //     return require("../../../assets/image/icon/AVAX.svg")
    //   } else if (name === "Mumbai") {
    //     return require("../../../assets/image/icon/polygon.svg")
    //   } else {
    //     return require("../../../assets/image/icon/unknown.svg")
    //   }
    // },
    icons(name) {
      if (name === 'Fantom Testnet') {
        return require('../../../assets/image/icon/FTM.svg');
      } else if (name === 'Avalanche Testnet') {
        return require('../../../assets/image/icon/AVAX.svg');
      } else if (name === 'Polygon Mumbai') {
        return require('../../../assets/image/icon/polygon.svg');
      } else if (name === 'Goerli Testnet') {
        return require('../../../assets/image/icon/ETHicon.svg');
      } else if (name === 'Arbitrum Testnet') {
        return require('../../../assets/image/icon/Arbitrum.svg');
      } else {
        return require('../../../assets/image/wallect/unknown.webp');
      }
    },
    async choosework(vlaue) {
      this.NetworkName = vlaue;
      let et = await estimateFees('borrow', vlaue.chainid); //43113
      this.borrow.fee = et;
    },
    maxinput() {
      let risk = this.TypeOperation.LTV;
      if (this.inputtype) {
        this.inputNumber =
          (this.AllMyNumber.AllSupply * risk - this.AllMyNumber.AllMyborrow) /
          this.TypeOperation.OraclePrice;
        this.max2 = this.inputNumber;
      } else {
        this.inputNumber =
          this.AllMyNumber.AllSupply * risk - this.AllMyNumber.AllMyborrow;
        this.max2 = this.inputNumber;
      }
      this.inputmax();
      this.upwidth();
    },
    maxinput2() {
      let risk = this.TypeOperation.LTV;
      if (this.inputtype) {
        this.max2 =
          (this.AllMyNumber.AllSupply * risk - this.AllMyNumber.AllMyborrow) /
          this.TypeOperation.OraclePrice;
      } else {
        this.max2 =
          this.AllMyNumber.AllSupply * risk - this.AllMyNumber.AllMyborrow;
      }
      this.inputmax();
      this.upwidth();
    },
    slide(value) {
      value = value < this.letFactor ? this.letFactor : value;
      let health = value / 100;
      let risk = this.TypeOperation.LTS;
      if (this.inputtype) {
        this.inputNumber =
          (this.AllMyNumber.AllSupply * (risk * health) -
            this.AllMyNumber.AllMyborrow) /
          this.TypeOperation.OraclePrice;
        this.inputmax();
      } else {
        this.inputNumber =
          this.AllMyNumber.AllSupply * (risk * health) -
          this.AllMyNumber.AllMyborrow;
        this.inputmax();
      }
    },
    setShowModal(value) {
      this.showfailed = value;
    },
    Switchupdown() {
      this.inputtype = !this.inputtype;
      if (this.inputtype) {
        this.money = '';
        this.money2 = '$';
        this.token1 = this.borrow.token;
        this.token2 = '';
      } else {
        this.money = '$';
        this.money2 = '';
        this.token1 = '';
        this.token2 = ' ' + this.borrow.token;
      }
      [this.inputNumber, this.borrow.price] = [
        this.borrow.price,
        this.inputNumber,
      ];
      this.inputNumber = '' + this.inputNumber;
      this.upwidth();
    },
    feetoken() {
      if (this.chainid === 4002) {
        return 'FTM';
      } else if (this.chainid === 97) {
        return 'BNB';
      } else if (this.chainid === 43113) {
        return 'AVAX';
      }
    },
    inputmax() {
      if (!/^(-)?[0-9.]+$/g.test(this.inputNumber.toString())) {
        this.inputNumber = '0';
      }
      if (this.inputtype) {
        if (+this.inputNumber > this.max2) {
          this.inputNumber = this.max2;
        }
      } else {
        if (+this.inputNumber > this.max2 / this.TypeOperation.OraclePrice) {
          this.inputNumber = this.max2 / this.TypeOperation.OraclePrice;
        }
      }
      if (this.inputNumber === ' ') {
        this.inputNumber = '0';
      }
      if (this.inputtype) {
        this.borrow.receive = this.inputNumber / this.TypeOperation.dtScaled;
        this.borrow.price = +this.inputNumber * +this.TypeOperation.OraclePrice;
        this.borrow.limit =
          this.AllMyNumber.AllBorrowLimt -
          this.inputNumber * this.TypeOperation.OraclePrice;
      } else {
        this.borrow.receive =
          this.inputNumber /
          +this.TypeOperation.OraclePrice /
          this.TypeOperation.dtScaled;
        this.borrow.price = +this.inputNumber / +this.TypeOperation.OraclePrice;
        this.borrow.limit = this.AllMyNumber.AllBorrowLimt - this.inputNumber;
      }
      this.upwidth();
      this.inputhelth();
    },
    upwidth() {
      this.inputNumber = this.inputNumber + '';
      if (this.inputNumber.length < 0) {
        this.inputheight = 0;
      } else {
        this.tokennamemargin = 12;
        this.inputheight = 60;
        this.textheight = 68;
        this.align = 'center';
      }
      if (this.inputNumber === '') {
        this.inputNumber = '0';
      }
      this.tokennamemargin = 12;
      if (this.inputNumber.length <= 6) {
        this.inputScale = 1;
      } else {
        this.tokennamemargin = 7;
        this.inputheight = 0;
        this.textheight = 2;
        this.align = 'flex-end';
        this.inputScale = 6 / (this.inputNumber.length + 1);
        if (this.inputScale < 0.45) {
          this.inputScale = 0.45;
        }
      }
      if (this.inputNumber.length === 1) {
        this.tokennamemargin = 0;
      }
    },
    upwidth2() {
      this.borrow.Health = this.borrow.Health + '';
      if (this.borrow.Health.length < 0) {
        this.inputheight2 = 0;
      } else {
        this.inputheight2 = 24;
      }
      if (this.borrow.Health.length > 2) {
        this.widthinput = this.borrow.Health.length * 44.5;
        if (this.borrow.Health.length > 6) {
          this.inputheight2 = 0;
          this.textheight2 = 2;
          this.align2 = 'flex-end';
        }
      } else {
        this.widthinput2 = 100;
        this.inputScale2 = 1;
      }
      this.borrow.Health = +this.borrow.Health;
    },
    BorrowInfo() {
      this.AllMyNumber.AllBorrowLimt =
        this.AllMyNumber.AllSupply * +this.TypeOperation.LTV -
        this.AllMyNumber.AllMyborrow;
      let val = this.inputtype
        ? this.inputNumber
        : +this.inputNumber / +this.TypeOperation.OraclePrice;
      this.borrow = {
        balance: 0,
        price: 0,
        able: 0,
        token: this.TypeOperation.TokenName,
        img: this.TypeOperation.icon,
        limit:
          this.AllMyNumber.AllBorrowLimt - val * this.TypeOperation.OraclePrice,
        limit2: this.AllMyNumber.AllBorrowLimt,
        Utilization: 0,
        apy: this.TypeOperation.BorrowApy,
        loan:
          this.TypeOperation.myBorrow - val * this.TypeOperation.OraclePrice,
        receive: val,
        fee: 1,
        Health:
          (this.AllMyNumber.AllMyborrow +
            +val * +this.TypeOperation.OraclePrice) /
          (this.AllMyNumber.AllSupply * +this.TypeOperation.LTS),
      };
      this.Healthsilde();
    },
    borrowF() {
      this.btnloading = true;
      let val = this.inputtype
        ? this.inputNumber
        : +this.inputNumber / +this.TypeOperation.OraclePrice;
      borrow(
        this.borrow.token,
        this.NetworkName.id,
        val / this.TypeOperation.dtScaled,
        (code, hash) => {
          if (code === 0) {
            this.showmodal = false;
            this.showloading = true;
            this.hash = hash;
          } else if (code === 1) {
            setTransaction('Borrow', this.borrow.token, hash, this.inputNumber);
            this.btnloading = false;
          } else if (code === -1) {
            this.btnloading = false;
            this.$message.error(hash);
          } else if (code === 4) {
            this.btnloading = false;
            getLiquidityValue(this.borrow.token, this.NetworkName.id).then(
              (a) => {
                this.failedinfo.balance = a.balance;
                this.failedinfo.token = this.borrow.token;
                this.showfailed = !this.showfailed;
              }
            );
          } else if (code > 1) {
            this.btnloading = false;
          }
        }
      );
    },
    formatter(value) {
      return `${value}%`;
    },
    closemodel() {
      this.visible = false;
      if (this.setShow) {
        this.setShow(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.borrow {
  .borrow-title {
    text-align: center;
  }
  .header-input {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .max {
      margin-top: 19px;
      width: 24px;
      height: 24px;
      margin-right: 22px;
    }
    .sw {
      margin-top: 19px;
      width: 24px;
      height: 24px;
      margin-left: 22px;
    }
  }
  .price {
    font-family: 'GT America Trial Ext Rg';
    margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
  }
  .title {
    font-family: 'GT America Trial Ext Bd';
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #000000;
  }
  .list {
    margin-top: 17px;
    .d1 {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div:nth-child(1) {
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 23px;
        color: #000000;
        margin-right: 5px;
        img {
        }
        span {
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 23px;
          color: #000000;
          margin-right: 5px;
        }
      }
      div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 23px;
        text-align: right;
        color: #000000;
        .s1 {
          margin-right: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 23px;
          text-align: center;
          color: rgba(0, 0, 0, 0.4);
          font-family: 'GT America Trial Ext Rg';
        }
        .s2 {
          margin-left: 8px;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 23px;
          color: #000000;
        }
      }
    }
  }
  .title3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
    }
    span {
      margin-right: 5px;
      font-family: 'GT America Trial Ext Md';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #1f2226;
    }
  }
  .slidernubmer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    margin-bottom: -12px;

    .p1 {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #000000;
    }
    .ant-input {
      font-family: 'GT America Trial Ext Bd';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      height: 24px;
      padding: 4px 0px;
      color: #000000;
      background-color: #fff;
      background-image: none;
      border: none;
      border-radius: 4px;
      transition: all 0.3s;
    }
  }

  .title4 {
    margin-top: 22px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #787878;
    }
  }
  .title5 {
    padding: 12px 32px;
    background: rgba(170, 104, 240, 0.1);
    margin: 0px -30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #000000;
    }
    .r {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 23px;
      color: #000000;

      img {
        margin-right: 5px;
        height: 24px;
        width: 24px;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep.ant-btn-primary {
      margin-top: 20px;
      width: 100%;
      font-family: 'GT America Trial Ext Md';
      font-size: 16px;
      font-weight: 700;
      margin-right: 12px;
      height: 64px;
      border-radius: 20px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }
    ::v-deep .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      border: 1px solid transparent;
      background: #aa68f0cc !important;
      color: #ffffff !important;
    }
    ::v-deep.disabledtrue.ant-btn-disabled,
    .ant-btn.disabled,
    .ant-btn[disabled],
    .ant-btn-disabled:hover,
    .ant-btn.disabled:hover,
    .ant-btn[disabled]:hover,
    .ant-btn-disabled:focus,
    .ant-btn.disabled:focus,
    .ant-btn[disabled]:focus,
    .ant-btn-disabled:active,
    .ant-btn.disabled:active,
    .ant-btn[disabled]:active,
    .ant-btn-disabled.active,
    .ant-btn.disabled.active,
    .ant-btn[disabled].active {
      margin-top: 20px;
      font-family: 'GT America Trial Ext Md';
      color: white;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
      height: 64px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.borrow-dark {
  .borrow-title {
    text-align: center;
  }
  .header-input {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .max {
      margin-top: 19px;
      width: 24px;
      height: 24px;
      margin-right: 22px;
    }
    .sw {
      margin-top: 19px;
      width: 24px;
      height: 24px;
      margin-left: 22px;
    }
  }
  .price {
    font-family: 'GT America Trial Ext Rg';
    margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
  }
  .title {
    font-family: 'GT America Trial Ext Bd';
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #f2f2f2;
  }
  .list {
    margin-top: 17px;
    .d1 {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div:nth-child(1) {
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 23px;
        color: #f2f2f2;
        span {
          margin-right: 5px;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 23px;
          color: #f2f2f2;
        }
      }
      div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 23px;
        text-align: right;
        color: #f2f2f2;
        .s1 {
          margin-right: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 23px;
          text-align: center;
          color: rgba(255, 255, 255, 0.4);
          font-family: 'GT America Trial Ext Rg';
        }
        .s2 {
          margin-left: 8px;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 23px;
          color: #f2f2f2;
        }
      }
    }
  }
  .title3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
    }
    span {
      margin-right: 5px;
      font-family: 'GT America Trial Ext Md';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #f2f2f2;
    }
  }
  .slidernubmer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    margin-bottom: -12px;

    .p1 {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #f2f2f2;
    }
    .ant-input {
      font-family: 'GT America Trial Ext Bd';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      height: 24px;
      padding: 4px 0px;
      color: #f2f2f2;
      background-color: #fff;
      background-image: none;
      border: none;
      border-radius: 4px;
      transition: all 0.3s;
    }
  }

  .title4 {
    margin-top: 22px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #747474;
    }
  }
  .title5 {
    padding: 12px 32px;
    background: rgba(170, 104, 240, 0.1);
    margin: 0px -30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #f2f2f2;
    }
    .r {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 23px;
      color: #f2f2f2;

      img {
        margin-right: 5px;
        height: 24px;
        width: 24px;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep.ant-btn-primary {
      margin-top: 20px;
      width: 100%;
      font-family: 'GT America Trial Ext Md';
      font-size: 16px;
      font-weight: 700;
      margin-right: 12px;
      height: 64px;
      border-radius: 20px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }
    ::v-deep .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      border: 1px solid transparent;
      background: #aa68f0cc !important;
      color: #ffffff !important;
    }
    ::v-deep.disabledtrue.ant-btn-disabled,
    .ant-btn.disabled,
    .ant-btn[disabled],
    .ant-btn-disabled:hover,
    .ant-btn.disabled:hover,
    .ant-btn[disabled]:hover,
    .ant-btn-disabled:focus,
    .ant-btn.disabled:focus,
    .ant-btn[disabled]:focus,
    .ant-btn-disabled:active,
    .ant-btn.disabled:active,
    .ant-btn[disabled]:active,
    .ant-btn-disabled.active,
    .ant-btn.disabled.active,
    .ant-btn[disabled].active {
      margin-top: 20px;
      font-family: 'GT America Trial Ext Md';
      color: rgba(255, 255, 255, 0.3);
      background: rgba(255, 255, 255, 0.12);
      border-radius: 20px;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
      height: 64px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

::v-deep .ant-modal-body {
  border-radius: 18px;
  background: var(--bg);
  padding: 32px !important;
}
::v-deep .ant-modal-content {
  border-radius: 20px;
}
::v-deep .ant-input:hover {
  border-color: transparent;
  border-right-width: 0px !important;
}
.ant-input:focus {
  border-color: transparent;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none !important;
}
::v-deep .ant-slider:hover .ant-slider-rail {
  background-color: var(--sliderbg);
}
::v-deep .ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: var(--sliderbg);
  border-radius: 2px;
  transition: background-color 0.3s;
}
::v-deep .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  //border-color: rgba(0, 0, 0, 0.5);
  border: 2px solid #aa68f0;
  box-shadow: 0px 4px 4px rgba(170, 104, 240, 0.2);
}
::v-deep .ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 14px 0px 10px !important;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
::v-deep .ant-slider-track {
  position: absolute;
  height: 10px;
  /*background-color: red !important;*/
  background: linear-gradient(
    89.97deg,
    #0fc036 3.57%,
    #ffb84d 48.34%,
    #ff002e 100%
  ) !important;
  border-radius: 2px;
  transition: background-color 0.3s;
}

::v-deep .slider1 .ant-slider-track {
  position: absolute;
  height: 10px;
  /*background-color: red !important;*/
  background: linear-gradient(
    89.97deg,
    #0fc036 3.57%,
    #0fc036 48.34%
  ) !important;
  border-radius: 2px;
  transition: background-color 0.3s;
}
::v-deep .slider2 .ant-slider-track {
  position: absolute;
  height: 10px;
  /*background-color: red !important;*/
  background: linear-gradient(
    89.97deg,
    #0fc036 3.57%,
    #ffb84d 48.34%
  ) !important;
  border-radius: 2px;
  transition: background-color 0.3s;
}
::v-deep .slider3 .ant-slider-track {
  position: absolute;
  height: 10px;
  /*background-color: red !important;*/
  background: linear-gradient(
    89.97deg,
    #0fc036 3.57%,
    #ffb84d 48.34%,
    #ff3f62 100%
  ) !important;
  border-radius: 2px;
  transition: background-color 0.3s;
}
::v-deep .ant-slider-handle {
  position: absolute;
  width: 22px;
  height: 22px;
  margin-top: -5px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  transition: border-color 0.3s, box-shadow 0.6s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-input {
  font-family: 'GT America Trial Ext Bd';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 68px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  height: 68px;
  padding: 4px 11px;
  color: var(--inputcolor);
  background-color: #fff;
  background-image: none;
  border: none;
  border-radius: 4px;
  transition: all 0.3s;
}
::v-deep.ant-dropdown-menu {
  //width: 183px;
  background: var(--dropdownbg);
  border-radius: 12px;
  position: relative;
  //padding: 20px 0;
  padding-top: 20px !important;
  text-align: left;
  list-style-type: none;
  background-clip: padding-box;
  outline: none;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  -webkit-transform: translate3d(0, 0, 0);
}
::v-deep .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  margin-bottom: 20px !important;
  clear: both;
  margin: 0;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;

  div {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--dropdowncolor);
    font-family: 'GT America Trial Ext Md';
  }
  div:hover {
    color: var(--dropdowncolorhover);
  }
}
::v-deep .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: transparent !important;
}
</style>
