<template>
  <div>
    <Modal @close-modal="closeModal()">
      <div class="enable-token">
        <template slot="closeIcon">
          <div class="close-icon-wrapper">
            <img
              src="../../../assets/image/dark/Close_L3.svg"
              v-if="themetype"
            />
            <img src="../../../assets/image/icon/Close_L.svg" v-else />
          </div>
        </template>

        <div class="modal-content">
          <div class="modal-header">
            <div class="title">Enable {{ tokenInfo.token.symbol }}</div>
          </div>

          <div class="modal-body">
            <div class="icon">
              <img
                src="../../../assets/image/icon/new/chain/avax.svg"
                width="80px"
                height="80px"
                alt="{testAvax}"
              />
            </div>
            <div class="text">
              To Supply {{ tokenInfo.token.symbol }} to Cedro Protocol as
              collateral, you need to enable it first.
            </div>
          </div>

          <div class="modal-footer">
            <div class="buttons">
              <Button
                showIcon
                :handleClick="EnableF"
                :isDisabled="btnDisabled"
                :isLoading="btnLoading"
                >Enable</Button
              >
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { approveToken, setHash } from '../../../SDK';

import Modal from '../../../components/new/Modal/index';
import Button from '../Btn/index';

export default {
  name: 'EnableToken',
  components: {
    Button,
    Modal,
  },
  computed: {
    ...mapGetters(['chainid', 'themetype']),
  },

  data() {
    return {
      btnLoading: false,
      btnDisabled: false,
    };
  },
  props: {
    setShow: Function,
    tokenInfo: Object,
  },
  created() {},
  methods: {
    closeModal() {
      this.setShow(false);
    },
    EnableF() {
      this.btnLoading = true;
      approveToken(this.tokenInfo.token.id, (code, hash) => {
        if (code === 1) {
          this.btnLoading = false;
          this.closeModal();
        } else if (code > 1) {
          this.btnLoading = false;
        } else if (code === 0) {
          setHash(this.tokenInfo.token.id, hash);
        }
      });
    },
  },
  watch: {},
};
</script>

<style>
.close-icon-wrapper {
  cursor: pointer;
  background: #ffffff;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  margin-top: 18px;
  z-index: 4;
}
.enable-token {
  position: relative;
  z-index: 3;

  .modal-content {
    margin-top: 15px;

    .modal-header {
      .title {
        font-size: 26px;
        font-weight: 700;
        line-height: 130%;
        color: #141414;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin: 36px 0;

      .text {
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        color: #00000080;
      }
    }
    .modal-footer {
      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
