<template>
  <div>
    <a-drawer
      wrapClassName="MoblieSwitchs"
      :title="false"
      placement="bottom"
      :closable="false"
      :visible="visible"
      @close="onClose"
      height="450px"
      width="100%"
      style="max-height: 100vh"
    >
      <div class="drw-title">
        <img src="../../../assets/image/icon/Close_L.svg" @click="onClose()" />
      </div>
      <div class="t1">Switch Network</div>
      <div class="t2" v-if="ismoneytype === 3">
        <div style="width: 75%; margin-top: 24px">
          Your current network is
          <span
            style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ chain }}</span
          >
          please switch to
          <span style="color: #AA68F0;font-family: 'GT America Trial Ext Md">{{
            this.SwtoNetworkName
          }}</span>
        </div>
      </div>
      <!--     sully或repay-->
      <div class="t2" v-if="ismoneytype === 2">
        <div style="width: 89%; margin-top: 24px">
          Your asset is in
          <span style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ this.SwtoNetworkName }}<br
          /></span>
          and you're currently in
          <span style="color: #AA68F0;font-family: 'GT America Trial Ext Md">{{
            chain
          }}</span
          >. You need to switch to
          <span
            style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ this.SwtoNetworkName }}</span
          ><br />
          for Supply and Repay.
        </div>
      </div>
      <!--      borrow withdraw-->
      <div class="t2" v-if="ismoneytype === 1">
        <div style="width: 89%; margin-top: 24px">
          Borrow, Withdraw, and Liquidate originates in the
          <span
            style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ this.SwtoNetworkName }}</span
          >. You're currently on
          <span style="color: #AA68F0;font-family: 'GT America Trial Ext Md">{{
            chain
          }}</span
          >. Please switch to
          <span
            style="color: #aa68f0; font-family: 'GT America Trial Ext Md'"
            >{{ this.SwtoNetworkName }}</span
          >
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 12px 0px 24px 0px;
        "
      >
        <img :src="fromPath" style="width: 60px; height: 60px" />
        <img
          src="../../../assets/image/dark/right-icon.svg"
          width="32px"
          height="32px"
          style="margin: 0px 20px"
          v-if="themetype"
        />
        <img
          src="../../../assets/image/wallect/right-icon.svg"
          width="32px"
          height="32px"
          style="margin: 0px 20px"
          v-else
        />
        <img :src="toPath" style="width: 60px; height: 60px" />
      </div>
      <div class="btn">
        <a-button type="primary" @click="switchwork()" :loading="btnloading">
          Switch
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { changeMetamaskChain } from '../../../SDK';
import { chainIdDict } from '../../../SDK/config';

export default {
  name: 'MoblieSwitchs',
  props: {
    ismoneytype: Number,
    setShow: Function,
    switchnetwork: String,
    switchid: Number,
  },
  computed: {
    ...mapGetters(['chainid', 'chain', 'themetype']),
  },
  data() {
    return {
      bg: '#ffffff',
      btnloading: false,
      visible: true,
      fromPath: require('../../../assets/image/icon/unknown.svg'),
      toPath: require('../../../assets/image/icon/unknown.svg'),
      polygonLogo: require('../../../assets/image/wallect/ProtocolIcon.svg'),
      fantomLogo: require('../../../assets/image/wallect/fantom.svg'),
      unkonwnLogo: require('../../../assets/image/icon/unknown.svg'),
      SwtoNetworkName: '',
    };
  },
  created() {
    this.SwtoNetworkName = chainIdDict[this.switchid];
    this.fromPath = this.getIcon(this.chain);
    this.toPath = this.getIcon(this.switchnetwork);
  },
  methods: {
    // getIcon(name) {
    //   if (name === "Fantom Testnet") {
    //     return require("../../../assets/image/icon/FTM.svg")
    //   } else if (name === "Avalanche Testnet") {
    //     return require("../../../assets/image/icon/AVAX.svg")
    //   } else if (name === "Bsc Testnet(Root Chain)") {
    //     return require("../../../assets/image/icon/BNB.svg")
    //   } else if(name === "Mumbai"){
    //     return require("../../../assets/image/icon/polygon.svg")
    //   } else {
    //     return require("../../../assets/image/icon/unknown.svg")
    //   }
    // },
    getIcon(name) {
      if (name === 'Fantom Testnet') {
        return require('../../../assets/image/icon/FTM.svg');
      } else if (name === 'Avalanche Testnet') {
        return require('../../../assets/image/icon/AVAX.svg');
      } else if (name === 'Bsc Testnet(Root Chain)') {
        return require('../../../assets/image/icon/BNB.svg');
      } else if (name === 'Polygon Mumbai') {
        return require('../../../assets/image/icon/polygon.svg');
      } else if (name === 'Arbitrum Testnet') {
        return require('../../../assets/image/icon/Arbitrum.svg');
      } else if (name === 'Goerli Testnet') {
        return require('../../../assets/image/icon/ETHicon.svg');
      } else {
        return require('../../../assets/image/icon/unknown.svg');
      }
    },
    async switchwork() {
      this.btnloading = true;
      await changeMetamaskChain(this.switchid);
      this.btnloading = false;
      this.onClose();
    },
    onClose() {
      this.visible = false;
      if (this.setShow) {
        this.setShow(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.MoblieSwitchs {
  .drw-title {
    background: #ffffff;
    padding: 12px 16px !important;
    text-align: right;
  }
  ::v-deep .ant-drawer-body {
    overflow-x: hidden;
    padding: 16px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  ::v-deep .ant-drawer-content {
    position: absolute;
    z-index: 999;
    overflow: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius-top: 25px;
    border-radius: 25px 25px 0px 0px;
  }
  .t1 {
    margin-top: -28px;
    font-family: 'GT America Trial Ext Bd';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    color: #000000;
  }
  .t2 {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      font-family: 'GT America Trial Ext Rg';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
      color: #000000;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep.ant-btn-primary {
      width: 100%;
      font-family: Inter;
      font-size: 16px;
      font-weight: 700;
      height: 52px;
      border-radius: 20px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }
    ::v-deep .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      border: 1px solid transparent;
      background: #aa68f0cc !important;
      color: #ffffff !important;
    }
  }
}
</style>
