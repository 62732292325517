<template>
  <div>
    <a-drawer
      wrapClassName="MoblieSupply"
      :title="false"
      placement="bottom"
      :closable="false"
      :visible="visible"
      @close="onClose"
      height="621px"
      width="100%"
      style="max-height: 100vh"
    >
      <div class="drw-title">
        <img
          src="../../../../assets/image/icon/Close_L.svg"
          @click="onClose()"
        />
      </div>
      <div class="title">Supply</div>

      <div class="header-input">
        <div
          @mouseenter="iconHighlight = false"
          @mouseleave="iconHighlight = true"
        >
          <img
            src="../../../../assets/image/icon/maxG.svg"
            class="max"
            v-if="iconHighlight"
          />
          <img
            src="../../../../assets/image/icon/max.svg"
            class="max"
            v-else
            @click="maxinput"
          />
        </div>

        <div
          :style="
            'position: relative;display: flex;justify-content: space-between;align-items:' +
            align
          "
          v-if="themetype"
        >
          <div
            :style="
              'position: relative;right: 0;bottom: -7px;font-style: normal;font-weight: 700;font-size: 34px;line-height:-' +
              textheight +
              'px;color: #000000;font-family: GT America Trial Ext Bd'
            "
          >
            {{ money }}
          </div>
          <!--            <a-input v-model="inputNumber" placeholder="0" :style="'width:'+widthinput+'px;'+'zoom:'+inputScale" @change="inputmax" type="number"   min="-999999999" max="9999999999" :step="0.0000001" />-->
          <div class="9999" style="text-align: center; position: relative">
            <div
              :style="
                'position: relative;display: inline-block;background: transparent;height:' +
                inputheight +
                'px;text-align: left;'
              "
            >
              <a-input
                :style="
                  'position: absolute;width: 100%;background: transparent;padding: 0;zoom:' +
                  inputScale
                "
                v-model="inputNumber"
                placeholder="0"
                @change="inputmax"
                type="text"
              ></a-input>
              <span
                :style="
                  'color: transparent;font-size: 40px;font-weight: 700;font-family: GT America Trial Ext Bd; display: block;min-width: 35px;zoom:' +
                  inputScale
                "
                >{{ inputNumber }}</span
              >
            </div>
          </div>
          <div
            :style="
              'position: relative;right: 0;bottom: -7px;font-style: normal;font-weight: 700;font-size: 30px;line-height:-' +
              textheight +
              'px;color: #FFFFFF;margin-left:' +
              tokennamemargin +
              'px'
            "
          >
            {{ token1 }}
          </div>
        </div>
        <div
          :style="
            'position: relative;display: flex;justify-content: space-between;align-items:' +
            align
          "
          v-else
        >
          <div
            :style="
              'position: relative;right: 0;bottom: -7px;font-style: normal;font-weight: 700;font-size: 34px;line-height:-' +
              textheight +
              'px;color: #000000;font-family: GT America Trial Ext Bd'
            "
          >
            {{ money }}
          </div>
          <div class="9999" style="text-align: center; position: relative">
            <div
              :style="
                'position: relative;display: inline-block;background: transparent;height:' +
                inputheight +
                'px;text-align: left;'
              "
            >
              <a-input
                :style="
                  'position: absolute;width: 100%;background: transparent;padding: 0;zoom:' +
                  inputScale
                "
                v-model="inputNumber"
                placeholder="0"
                @change="inputmax"
                type="text"
              ></a-input>
              <span
                :style="
                  'color: transparent;font-size: 40px;font-weight: 700;font-family: GT America Trial Ext Bd; display: block;min-width: 35px;zoom:' +
                  inputScale
                "
                >{{ inputNumber }}</span
              >
            </div>
          </div>
          <div
            :style="
              'position: relative;right: 0;bottom: -7px;font-style: normal;font-weight: 700;font-size: 28px;line-height:-' +
              textheight +
              'px;color: #000000;margin-left:' +
              tokennamemargin +
              'px'
            "
          >
            {{ token1 }}
          </div>
        </div>
        <div
          @mouseenter="iconHighlight2 = false"
          @mouseleave="iconHighlight2 = true"
        >
          <img
            src="../../../../assets/image/icon/switchG.svg"
            class="sw"
            v-if="iconHighlight2"
          />
          <img
            src="../../../../assets/image/icon/switchG2.svg"
            class="sw"
            v-else
            @click="Switchupdown()"
          />
        </div>
      </div>
      <!--      <div class="price">≈ ${{Supply.price}}</div>-->
      <div class="price">
        ≈ {{ money2 }}{{ toFixedNumber(Supply.price, 2, true) }}{{ token2 }}
      </div>
      <div class="max-title">
        <div>
          Wallet Balance:{{ toFixedCutZero(wallectbanlance, 4, false) }}
        </div>
        <div style="margin-left: 4px">{{ Supply.token }}</div>
        <img :src="Supply.img" width="16px" height="16px" />
      </div>
      <div class="list">
        <div class="d1">
          <div class="p1">New Borrow limit</div>
          <div class="p2">
            <div class="s1">${{ toFixedNumber(Supply.limit2, 2, true) }}</div>
            <img
              src="../../../../assets/image/dark/SmallArrow2.svg"
              width="12px"
              height="12px"
              v-if="themetype"
            />
            <img
              src="../../../../assets/image/icon/SmallArrow.svg"
              width="12px"
              height="12px"
              v-else
            />
            <div class="s2">${{ toFixedNumber(Supply.limit, 2, true) }}</div>
          </div>
        </div>
        <div class="d1">
          <div class="p1">Supply APY</div>
          <div class="p2">{{ toFixedCutZero(Supply.apy, 2, false) }}%</div>
        </div>
        <div class="d1">
          <div class="p1">You Will Receive</div>
          <div class="p2">
            {{ Supply.receive }} {{ Supply.token.replace('test', 'ce') }}
          </div>
        </div>
        <div class="d1">
          <div
            class="p1"
            style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <span>Cross Chain Fee</span>
            <a-popover :title="false" placement="top">
              <template #content>
                <p
                  style="
                    font-weight: 400;
                    font-size: 10px;
                    color: #8f9092;
                    font-family: 'GT America Trial Ext Rg';
                    padding-top: 15px;
                    width: 169px;
                  "
                >
                  The cost required to use layerzero for cross-chain,related to
                  dest chain coin price.<br />
                </p>
              </template>
              <div
                @mouseenter="iconHighlight5 = false"
                @mouseleave="iconHighlight5 = true"
                v-if="themetype"
              >
                <img
                  src="../../../../assets/image/dark/question3.svg"
                  style="cursor: pointer"
                  v-if="iconHighlight5"
                />
                <img
                  src="../../../../assets/image/icon/question2.svg"
                  style="cursor: pointer"
                  v-else
                />
              </div>
              <div
                @mouseenter="iconHighlight5 = false"
                @mouseleave="iconHighlight5 = true"
                v-else
              >
                <img
                  src="../../../../assets/image/icon/question.svg"
                  style="cursor: pointer"
                  v-if="iconHighlight5"
                />
                <img
                  src="../../../../assets/image/icon/question2.svg"
                  style="cursor: pointer"
                  v-else
                />
              </div>
            </a-popover>
          </div>
          <div class="p2">{{ Supply.fee }} {{ feetoken() }}</div>
        </div>
        <!--          <div class="d1">-->
        <!--            <div class="p1">Utilization</div>-->
        <!--            <div :class="'p2'+this.textcolor">{{Supply.Utilization}}%</div>-->
        <!--          </div>-->
      </div>
      <div>
        <div class="title3">
          <span>Health Factor</span>
          <a-popover :title="false" placement="top">
            <template #content>
              <p
                style="
                  font-weight: 400;
                  font-size: 10px;
                  color: #8f9092;
                  font-family: 'GT America Trial Ext Rg';
                  padding-top: 15px;
                  width: 169px;
                "
              >
                It is the ratio of the Collateral value multiplied by the LTV,
                and the borrowed value. H.F. 1 is considered unhealthy. So the
                collateral will be available for liquidation.<br />
              </p>
            </template>
            <div
              @mouseenter="iconHighlight3 = false"
              @mouseleave="iconHighlight3 = true"
              v-if="themetype"
            >
              <img
                src="../../../../assets/image/dark/question3.svg"
                style="cursor: pointer"
                v-if="iconHighlight3"
              />
              <img
                src="../../../../assets/image/icon/question2.svg"
                style="cursor: pointer"
                v-else
              />
            </div>
            <div
              @mouseenter="iconHighlight3 = false"
              @mouseleave="iconHighlight3 = true"
              v-else
            >
              <img
                src="../../../../assets/image/icon/question.svg"
                style="cursor: pointer"
                v-if="iconHighlight3"
              />
              <img
                src="../../../../assets/image/icon/question2.svg"
                style="cursor: pointer"
                v-else
              />
            </div>
          </a-popover>
        </div>
      </div>
      <div class="slidernubmer">
        <div class="9999" style="text-align: center; position: relative">
          <div
            :style="
              'position: relative;display: inline-block;background: transparent;height:' +
              inputheight2 +
              'px;text-align: left;'
            "
            @mouseenter="iconHighlight4 = false"
            @mouseleave="iconHighlight4 = true"
          >
            <a-input
              :style="'position: absolute;width: 100%;background: transparent;padding: 0'"
              v-model="Supply.Health"
              placeholder="0"
              @change="SliderInput"
              type="number"
              min="0"
              max="9999999999"
              :step="0.0000000000000000000001"
              v-if="iconHighlight4"
            ></a-input>
            <a-input
              :style="'position: absolute;width: 100%;background: transparent;border: #8F9092 solid 0.5px;padding: 0'"
              v-model="Supply.Health"
              placeholder="0"
              @change="SliderInput"
              type="number"
              min="0"
              max="9999999999"
              :step="0.0000000000000000000001"
              v-else
            ></a-input>
            <span
              :style="'color: transparent;font-size: 10px;font-weight: 400;font-family: GT America Trial Ext Bd; display: block;min-width: 18px;padding: 0px 3px;'"
              >{{ Supply.Health }}</span
            >
          </div>
        </div>
        <div class="p1" :style="'height:' + inputheight2">%</div>
      </div>
      <a-slider
        :default-value="1"
        :value="Supply.Health"
        @change="slide"
        :class="'slider' + showslider"
        :tooltipVisible="false"
      />
      <div class="title4">
        <div>Safer</div>
        <div>Riskier</div>
      </div>
      <div class="title5">
        <div class="l">Current Chain</div>
        <div class="r">
          <img :src="icons(chain)" />
          <div>{{ chain }}</div>
        </div>
      </div>
      <div class="btn">
        <a-button
          type="primary"
          class="disabledtrue"
          :disabled="true"
          v-if="btndis"
        >
          Supply
        </a-button>
        <a-button type="primary" class="btn2" @click="supplyF" v-else>
          Supply
        </a-button>
      </div>
    </a-drawer>
    <MoblieAllowance
      v-if="showEnable"
      :setShow="setShowModal"
      :EnableType="EnableType"
    ></MoblieAllowance>
    <MoblieLoading v-if="showloading" :hash="hash"></MoblieLoading>
  </div>
</template>

<script>
import {
  deposit,
  estimateFees,
  getBalance,
  setTransaction,
  toFixedCutZero,
  toFixedNumber,
} from '../../../../SDK';
import { mapGetters } from 'vuex';
import '../../../../style/drawer.scss';
import MoblieLoading from '../../../../components/Signin/Moblie/MoblieLoading';
import MoblieAllowance from '../../../../components/Signin/Moblie/MoblieAllowance';
export default {
  name: 'MoblieSupply',
  components: { MoblieAllowance, MoblieLoading },
  props: {
    tokenid: String,
    setShow: Function,
    wallectbanlance: Number,
    TypeOperation: Object,
    AllMyNumber: Object,
  },
  computed: {
    ...mapGetters([
      'account',
      'btnshow',
      'isLogin',
      'chainid',
      'chain',
      'themetype',
    ]),
  },
  data() {
    return {
      visible: true,
      toFixedNumber: toFixedNumber,
      toFixedCutZero: toFixedCutZero,
      btnloading: false,
      showEnable: false,
      showslider: 3,
      inputheight: 55,
      inputheight2: 24,
      align: 'center',
      textheight: 68,
      tokennamemargin: 0,
      iconHighlight: true,
      iconHighlight2: true,
      iconHighlight3: true,
      iconHighlight4: true,
      EnableType: {},
      iconHighlight5: true,
      textcolor: 1,
      btndis: true,
      money: '$',
      money2: '$',
      inputtype: true,
      token1: '',
      token2: '',
      hash: '',
      showloading: false,
      shouwcomplete: false,
      showmodal: true,
      inputScale: 1,
      inputNumber: 0,
      letFactor: 0,
      bg: '#ffffff',
      inputcolor: '#ffffff',
      sliderbg: '#ffffff',
      Complete: {
        title: '',
        settype: 0,
        input: 0,
        tokenname: '',
        Utilization: 0,
        hash: '',
      },
      Supply: {
        balance: 0,
        price: 0,
        able: 0,
        token: ' ',
        img: require('../../../../assets/image/icon/MATIC.svg'),
        limit: 0.0,
        limit2: 0.0,
        Utilization: 0,
        apy: 0,
        receive: 0,
        fee: 0,
        Health: 0,
      },
    };
  },
  mounted() {},
  created() {
    this.themetypeF();
    this.SupplyInfo();
    this.inputhelth();
    this.inputhelth2();
    this.money = '';
    this.money2 = '$';
    this.token1 = this.Supply.token;
    this.token2 = '';
  },
  watch: {
    inputNumber(val) {
      if (+this.inputNumber > 0) {
        this.btndis = false;
        val = val.toString();
        this.inputNumber = val.match(/\d+(\.\d{0,8})?/)
          ? val.match(/\d+(\.\d{0,8})?/)[0]
          : '';
        this.inputNumber = this.toFixedCutZero(this.inputNumber, 8, false);
      } else {
        this.btndis = true;
      }
      this.inputNumber = this.toCutFrontZero(this.inputNumber);
    },
  },
  methods: {
    setShowModal(value) {
      this.showEnable = value;
    },
    toCutFrontZero(num) {
      let str = num + '';
      if (str.startsWith('.')) {
        str = '0' + str;
      }
      while (str.length >= 2 && str.startsWith('0') && str[1] !== '.') {
        str = str.slice(1);
      }
      return str;
    },
    themetypeF() {
      if (this.themetype) {
        this.bg = `#1D1426`;
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--inputcolor', `#ffffff`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--sliderbg', `#42374D`);
      } else {
        this.bg = `#ffffff`;
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--inputcolor', `#1D1426`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--sliderbg', `#f5f5f5`);
      }
    },
    SliderInput() {
      this.Supply.Health = +this.Supply.Health;
      clearTimeout(this.setoutid);
      this.setoutid = setTimeout(() => {
        this.Supply.Health =
          +this.Supply.Health < this.letFactor
            ? this.letFactor
            : +this.Supply.Health;
        this.slide(+this.Supply.Health);
      }, 1500);
      this.upwidth2();
    },
    Healthsilde() {
      if (this.Supply.Health < 33) {
        this.showslider = 1;
      } else if (this.Supply.Health < 66) {
        this.showslider = 2;
      } else {
        this.showslider = 3;
      }
    },
    inputhelth() {
      let risk = this.TypeOperation.LTS;
      let a = 0;
      if (this.inputtype) {
        a =
          (this.AllMyNumber.AllSupply +
            this.inputNumber * this.TypeOperation.OraclePrice) *
          risk;
      } else {
        a = (this.AllMyNumber.AllSupply + +this.inputNumber) * risk;
      }
      let b = this.AllMyNumber.AllMyborrow;
      let c = b / a;
      this.Supply.Health = c * 100;
      this.Supply.Health = this.toFixedCutZero(this.Supply.Health, 2, false);
      this.Supply.Health = +this.Supply.Health;
      this.Healthsilde();
    },
    inputhelth2() {
      let risk = this.TypeOperation.LTS;
      let a = 0;
      if (this.inputtype) {
        a =
          (this.AllMyNumber.AllSupply +
            this.inputNumber * this.TypeOperation.OraclePrice) *
          risk;
      } else {
        a = (this.AllMyNumber.AllSupply + this.inputNumber) * risk;
      }
      let b = this.AllMyNumber.AllMyborrow;
      let c = b / a;
      this.Supply.Health = c * 100;
      this.letFactor = this.Supply.Health;
      this.Supply.Health = this.toFixedCutZero(this.Supply.Health, 2, false);
      this.Supply.Health = +this.Supply.Health;
      this.Healthsilde();
    },
    slide(value) {
      if (this.letFactor === 0) {
        return;
      }
      value = value > this.letFactor ? this.letFactor : value;
      if (this.letFactor > 0.1) {
        value = value < 0.1 ? 0.1 : value;
      }
      let health = value / 100;
      let risk = this.TypeOperation.LTS;
      if (this.inputtype) {
        this.inputNumber =
          (this.AllMyNumber.AllMyborrow / (risk * health) -
            this.AllMyNumber.AllSupply) /
          this.TypeOperation.OraclePrice;
        this.inputmax();
      } else {
        this.inputNumber =
          this.AllMyNumber.AllMyborrow / (risk * health) -
          this.AllMyNumber.AllSupply;
        this.inputmax();
      }
    },
    Switchupdown() {
      this.inputtype = !this.inputtype;
      if (this.inputtype) {
        this.money = '';
        this.money2 = '$';
        this.token1 = this.Supply.token;
        this.token2 = '';
      } else {
        this.money = '$';
        this.money2 = '';
        this.token1 = '';
        this.token2 = ' ' + this.Supply.token;
      }
      [this.inputNumber, this.Supply.price] = [
        this.Supply.price,
        this.inputNumber,
      ];
      this.inputNumber = '' + this.inputNumber;
      // let wid = Math.min((this.inputNumber.length) * 40, 11 * 35);
      // this.widthinput = wid < 100 ? 100 : wid;
      this.upwidth();
    },
    // icons(name) {
    //   if (name === "Fantom Testnet") {
    //     return require("../../../../assets/image/icon/FTM.svg")
    //   } else if (name === "Avalanche Testnet") {
    //     return require("../../../../assets/image/icon/AVAX.svg")
    //   } else if (name === "Mumbai") {
    //     return require("../../../../assets/image/icon/polygon.svg")
    //   } else {
    //     return require("../../../../assets/image/icon/unknown.svg")
    //   }
    // },
    icons(name) {
      if (name === 'Fantom Testnet') {
        return require('../../../../assets/image/icon/FTM.svg');
      } else if (name === 'Avalanche Testnet') {
        return require('../../../../assets/image/icon/AVAX.svg');
      } else if (name === 'Polygon Mumbai') {
        return require('../../../../assets/image/icon/polygon.svg');
      } else if (name === 'Goerli Testnet') {
        return require('../../../../assets/image/icon/ETHicon.svg');
      } else if (name === 'Arbitrum Testnet') {
        return require('../../../../assets/image/icon/Arbitrum.svg');
      } else {
        return require('../../../../assets/image/wallect/unknown.webp');
      }
    },
    supplyF() {
      this.getEnable().then((ele) => {
        if (ele) {
          this.btnloading = true;
          let val = this.inputtype
            ? this.inputNumber
            : +this.inputNumber / +this.TypeOperation.OraclePrice;
          deposit(this.Supply.token, val, (code, hash) => {
            if (code === 0) {
              this.showmodal = false;
              this.showloading = true;
              this.hash = hash;
            } else if (code === 1) {
              setTransaction(
                'Deposit',
                this.Supply.token,
                hash,
                this.inputNumber
              );
              this.btnloading = false;
            } else if (code > 1) {
              this.btnloading = false;
            } else if (code === -1) {
              this.$message.error(hash);
            }
          });
        }
      });
    },
    SupplyInfo() {
      let Risk = +this.TypeOperation.LTV;
      this.AllMyNumber.AllBorrowLimt =
        this.AllMyNumber.AllSupply * Risk - this.AllMyNumber.AllMyborrow;
      let val = this.inputtype
        ? this.inputNumber
        : +this.inputNumber / +this.TypeOperation.OraclePrice;
      this.Supply = {
        balance: 0,
        price: 0,
        able: 0,
        token: this.TypeOperation.TokenName,
        img: this.TypeOperation.icon,
        limit:
          this.AllMyNumber.AllBorrowLimt +
          val * this.TypeOperation.OraclePrice * Risk,
        limit2: this.AllMyNumber.AllBorrowLimt,
        Utilization: 0,
        // apy: 0,
        apy: this.TypeOperation.SupplyApy,
        receive: val,
        fee: 0,
        Health:
          this.AllMyNumber.AllMyborrow /
          ((this.AllMyNumber.AllSupply +
            val * +this.TypeOperation.OraclePrice) *
            +this.TypeOperation.LTS),
      };
      this.Healthsilde();
      estimateFees('deposit', this.chainid).then((a) => {
        this.Supply.fee = a;
      });
    },
    maxinput() {
      if (this.inputtype) {
        this.inputNumber = this.wallectbanlance;
      } else {
        this.inputNumber =
          this.wallectbanlance * this.TypeOperation.OraclePrice;
      }
      this.inputmax();
      this.upwidth();
    },
    feetoken() {
      if (this.chainid === 4002) {
        return 'FTM';
      } else if (this.chainid === 97) {
        return 'BNB';
      } else if (this.chainid === 43113) {
        return 'AVAX';
      }
    },
    inputmax() {
      if (this.inputtype) {
        if (+this.inputNumber > this.wallectbanlance) {
          this.inputNumber = this.wallectbanlance;
        }
      } else {
        if (
          +this.inputNumber >
          this.wallectbanlance * this.TypeOperation.OraclePrice
        ) {
          this.inputNumber =
            this.wallectbanlance * this.TypeOperation.OraclePrice;
        }
      }
      if (this.inputNumber === ' ') {
        this.inputNumber = '0';
      }
      let risk = this.TypeOperation.LTV;
      if (this.inputtype) {
        this.Supply.receive = +this.inputNumber / this.TypeOperation.ceScaled;
        this.Supply.price = +this.inputNumber * +this.TypeOperation.OraclePrice;
        this.Supply.limit =
          this.AllMyNumber.AllBorrowLimt +
          this.inputNumber * this.TypeOperation.OraclePrice * risk;
        this.Supply.limit2 = this.AllMyNumber.AllBorrowLimt;
      } else {
        this.Supply.receive =
          +this.inputNumber /
          +this.TypeOperation.OraclePrice /
          this.TypeOperation.ceScaled;
        this.Supply.price = +this.inputNumber / +this.TypeOperation.OraclePrice;
        this.Supply.limit =
          this.AllMyNumber.AllBorrowLimt + this.inputNumber * risk;
        this.Supply.limit2 = this.AllMyNumber.AllBorrowLimt;
      }
      this.upwidth();
      this.inputhelth();
    },
    upwidth() {
      this.inputNumber = this.inputNumber + '';
      if (this.inputNumber.length < 0) {
        this.inputheight = 0;
      } else {
        this.tokennamemargin = 12;
        this.inputheight = 55;
        this.textheight = 68;
        this.align = 'center';
      }
      if (this.inputNumber === '') {
        this.inputNumber = '0';
      }
      this.tokennamemargin = 12;
      if (this.inputNumber.length <= 6) {
        this.inputScale = 1;
      } else {
        this.tokennamemargin = 7;
        this.inputheight = 0;
        this.textheight = 2;
        this.align = 'flex-end';
        this.inputScale = 6 / (this.inputNumber.length + 1);
        if (this.inputScale < 0.45) {
          this.inputScale = 0.45;
        }
      }
      if (this.inputNumber.length === 1) {
        this.tokennamemargin = 0;
      }
    },
    upwidth2() {
      this.Supply.Health = this.Supply.Health + '';
      if (this.Supply.Health.length < 0) {
        this.inputheight2 = 0;
      } else {
        this.inputheight2 = 24;
      }
      if (this.Supply.Health.length > 2) {
        this.widthinput = this.Supply.Health.length * 44.5;
        if (this.Supply.Health.length > 6) {
          this.inputheight2 = 0;
          this.textheight2 = 2;
          this.align2 = 'flex-end';
        }
      } else {
        this.widthinput2 = 100;
        this.inputScale2 = 1;
      }
      this.Supply.Health = +this.Supply.Health;
    },
    async getEnable() {
      let a = await getBalance(this.tokenid);
      if (+a.allowance <= +this.inputNumber) {
        this.EnableType = a;
        this.showEnable = !this.showEnable;
        this.btnloading = false;
        return false;
      } else {
        return true;
      }
    },
    calculeinfo() {},
    formatter(value) {
      return `${value}%`;
    },
    onClose() {
      this.visible = false;
      if (this.setShow) {
        this.setShow(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.MoblieSupply {
  ::v-deep .ant-drawer-body {
    overflow-x: hidden;
    padding: 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }

  ::v-deep .ant-drawer-content {
    position: absolute;
    z-index: 999;
    overflow: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius-top: 25px;
    border-radius: 25px 25px 0px 0px;
  }

  .drw-title {
    background: #ffffff;
    padding: 12px 16px !important;
    text-align: right;
  }

  .title {
    margin-top: -41px;
    font-family: 'GT America Trial Ext Bd';
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }

  .price {
    font-family: 'GT America Trial Ext Rg';
    margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
  }

  .max-title {
    margin-top: 12px;
    margin-bottom: 17px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      font-family: 'GT America Trial Ext Rg';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    img {
      margin-left: 9px;
    }
  }

  .title3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
    }

    span {
      margin-right: 5px;
      font-family: 'GT America Trial Ext Md';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #1f2226;
    }
  }

  .slidernubmer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    margin-bottom: -12px;

    .p1 {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #000000;
    }

    .ant-input {
      font-family: 'GT America Trial Ext Bd';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      height: 24px;
      padding: 4px 0px;
      color: #000000;
      background-color: #fff;
      background-image: none;
      border: none;
      border-radius: 4px;
      transition: all 0.3s;
    }
  }

  .title4 {
    margin-top: 22px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #787878;
    }
  }

  .title5 {
    padding: 12px 32px;
    background: rgba(170, 104, 240, 0.1);
    margin: 0px -30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #000000;
    }

    .r {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 23px;
      color: #000000;

      img {
        margin-right: 5px;
        height: 24px;
        width: 24px;
      }
    }
  }

  .list {
    .d1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .p1 {
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;

        img {
        }

        span {
          margin-right: 5px;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
      }

      .p2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;

        .s1 {
          margin-right: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: rgba(0, 0, 0, 0.4);
          font-family: 'GT America Trial Ext Rg';
        }

        .s2 {
          margin-left: 8px;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
      }

      .p21 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #0da500;

        .s1 {
          margin-right: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: rgba(0, 0, 0, 0.4);
          font-family: 'GT America Trial Ext Rg';
        }

        .s2 {
          margin-left: 8px;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
      }

      .p22 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #ec9a1e;

        .s1 {
          margin-right: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: rgba(0, 0, 0, 0.4);
          font-family: 'GT America Trial Ext Rg';
        }

        .s2 {
          margin-left: 8px;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
      }

      .p23 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #ec1e9a;

        .s1 {
          margin-right: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: rgba(0, 0, 0, 0.4);
          font-family: 'GT America Trial Ext Rg';
        }

        .s2 {
          margin-left: 8px;
          font-family: 'GT America Trial Ext Rg';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
      }
    }
  }

  .header-input {
    margin-top: -16px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .max {
      margin-top: 19px;
      width: 24px;
      height: 24px;
      margin-right: 11px;
    }

    .sw {
      margin-top: 19px;
      width: 24px;
      height: 24px;
      margin-left: 11px;
    }
  }

  ::v-deep.disabledtrue.ant-btn-disabled,
  .ant-btn.disabled,
  .ant-btn[disabled],
  .ant-btn-disabled:hover,
  .ant-btn.disabled:hover,
  .ant-btn[disabled]:hover,
  .ant-btn-disabled:focus,
  .ant-btn.disabled:focus,
  .ant-btn[disabled]:focus,
  .ant-btn-disabled:active,
  .ant-btn.disabled:active,
  .ant-btn[disabled]:active,
  .ant-btn-disabled.active,
  .ant-btn.disabled.active,
  .ant-btn[disabled].active {
    font-family: 'GT America Trial Ext Md';
    color: white;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border-color: transparent;
    margin-top: 16px;
    text-shadow: none;
    box-shadow: none;
    height: 52px;
    font-size: 18px;
    font-weight: 700;
  }

  .btn {
    flex-direction: column;
    display: flex;
    justify-content: center;

    ::v-deep .ant-btn-primary.btn1 {
      margin-top: 20px;
      width: 100%;
      font-family: 'GT America Trial Ext Md';
      font-size: 18px;
      font-weight: 600;
      margin-right: 12px;
      height: 52px;
      border-radius: 18px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }

    ::v-deep .ant-btn-primary.btn1:hover,
    .ant-btn-primary.btn1:focus {
      border: 1px solid transparent;
      background: #aa68f0cc !important;
      color: white !important;
    }

    ::v-deep .ant-btn-primary.btn2 {
      margin-top: 16px;
      width: 100%;
      font-family: 'GT America Trial Ext Md';
      font-size: 16px;
      font-weight: 700;
      margin-right: 12px;
      height: 64px;
      border-radius: 20px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }

    ::v-deep .btn2.ant-btn-primary:hover,
    .ant-btn-primary:focus {
      color: #ffffff !important;
      background-color: #aa68f0cc !important;
    }
  }
}

::v-deep .ant-input:hover {
  border-color: transparent;
  border-right-width: 0px !important;
}

.ant-input:focus {
  border-color: transparent;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none !important;
}

::v-deep .ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 10px;
  //background-color: #f5f5f5;
  background-color: var(--sliderbg);
  border-radius: 2px;
  transition: background-color 0.3s;
}

::v-deep .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  //border-color: rgba(0, 0, 0, 0.5);
  border: 2px solid #aa68f0;
  box-shadow: 0px 4px 4px rgba(170, 104, 240, 0.2);
}

::v-deep .ant-slider:hover .ant-slider-rail {
  background-color: var(--sliderbg);
}

::v-deep .slider1 .ant-slider-track {
  position: absolute;
  height: 10px;
  /*background-color: red !important;*/
  background: linear-gradient(
    89.97deg,
    #0fc036 3.57%,
    #0fc036 48.34%
  ) !important;
  border-radius: 2px;
  transition: background-color 0.3s;
}

::v-deep .slider2 .ant-slider-track {
  position: absolute;
  height: 10px;
  /*background-color: red !important;*/
  background: linear-gradient(
    89.97deg,
    #0fc036 3.57%,
    #ffb84d 48.34%
  ) !important;
  border-radius: 2px;
  transition: background-color 0.3s;
}

::v-deep .slider3 .ant-slider-track {
  position: absolute;
  height: 10px;
  /*background-color: red !important;*/
  background: linear-gradient(
    89.97deg,
    #0fc036 3.57%,
    #ffb84d 48.34%,
    #ff3f62 100%
  ) !important;
  border-radius: 2px;
  transition: background-color 0.3s;
}

::v-deep .ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 14px 0px 10px !important;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}

::v-deep .ant-slider-handle {
  position: absolute;
  width: 22px;
  height: 22px;
  margin-top: -5px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  transition: border-color 0.3s, box-shadow 0.6s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.ant-input {
  font-family: 'GT America Trial Ext Bd';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 68px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  height: 68px;
  padding: 4px 11px;
  color: var(--inputcolor);
  background-color: #fff;
  background-image: none;
  border: none;
  border-radius: 4px;
  transition: all 0.3s;
}
</style>
