<template>
  <div>
    <a-drawer
      wrapClassName="MoblieEnable"
      :title="false"
      placement="bottom"
      :closable="false"
      :visible="visible"
      @close="onClose"
      height="400px"
      width="100%"
      style="max-height: 100vh"
    >
      <div class="drw-title">
        <img src="../../../assets/image/icon/Close_L.svg" @click="onClose()" />
      </div>
      <div class="title">Enable {{ EnableType.token.symbol }}</div>
      <div class="icon">
        <img :src="icons(EnableType.token.symbol)" width="80px" height="80px" />
      </div>
      <div class="text">
        To Supply {{ EnableType.token.symbol }} to Cedro Protocol as collateral,
        you need to enable it first.
      </div>
      <div class="btn">
        <a-button
          type="primary"
          class="btn1"
          @click="EnableF()"
          :loading="btnloading"
        >
          Enable
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { approveToken } from '../../../SDK';

export default {
  name: 'MoblieEnable',
  props: {
    setShow: Function,
    EnableType: Object,
  },
  data() {
    return {
      btnloading: false,
      visible: true,
    };
  },
  methods: {
    icons(name) {
      if (name === 'testUSDC') {
        return require('../../../assets/image/icon/USDC.svg');
      } else if (name === 'testAVAX') {
        return require('../../../assets/image/icon/AVAX.svg');
      } else if (name === 'testFTM') {
        return require('../../../assets/image/icon/FTM.svg');
      } else if (name === 'testMATIC') {
        return require('../../../assets/image/icon/polygon.svg');
      } else if (name === 'testETH') {
        return require('../../../assets/image/icon/ETHicon.svg');
      } else {
        return require('../../../assets/image/icon/unknown.svg');
      }
    },
    EnableF() {
      this.btnloading = true;
      approveToken(this.EnableType.token.id, (code, hash) => {
        console.log('____approveToken___', code, hash);
        if (code === 1) {
          this.btnloading = false;
          this.visible = false;
        } else if (code > 1) {
          this.btnloading = false;
        }
      });
    },
    onClose() {
      this.visible = false;
      if (this.setShow) {
        this.setShow(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.MoblieEnable {
  .drw-title {
    //margin-top: 60%;
    background: #ffffff;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.04);
    padding: 12px 16px !important;
    text-align: right;
  }
  ::v-deep .ant-drawer-body {
    overflow-x: hidden;
    padding: 16px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  ::v-deep .ant-drawer-content {
    position: absolute;
    z-index: 999;
    overflow: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius-top: 25px;
    border-radius: 25px 25px 0px 0px;
  }
  .title {
    //margin-top: -20px;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #000000;
  }
  .icon {
    margin: 22px 0px 27px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      //image-rendering:-moz-crisp-edges;
      //image-rendering:-o-crisp-edges;
      //image-rendering:-webkit-optimize-contrast;
      //image-rendering: crisp-edges;
      //-ms-interpolation-mode:nearest-neighbor;
    }
  }
  .text {
    margin-bottom: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep.ant-btn-primary {
      width: 100%;
      font-family: 'GT America Trial Ext Md';
      font-size: 16px;
      font-weight: 700;
      height: 64px;
      border-radius: 20px;
      color: #fff;
      background: #aa68f0;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
    }
    ::v-deep .ant-btn-primary:hover,
    .ant-btn-primary.btn1:focus {
      border: 1px solid transparent;
      background: #aa68f0cc !important;
      color: #ffffff !important;
    }
    ::v-deep.disabledtrue.ant-btn-disabled,
    .ant-btn.disabled,
    .ant-btn[disabled],
    .ant-btn-disabled:hover,
    .ant-btn.disabled:hover,
    .ant-btn[disabled]:hover,
    .ant-btn-disabled:focus,
    .ant-btn.disabled:focus,
    .ant-btn[disabled]:focus,
    .ant-btn-disabled:active,
    .ant-btn.disabled:active,
    .ant-btn[disabled]:active,
    .ant-btn-disabled.active,
    .ant-btn.disabled.active,
    .ant-btn[disabled].active {
      font-family: 'GT America Trial Ext Md';
      color: white;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      border-color: transparent;
      text-shadow: none;
      box-shadow: none;
      height: 64px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
</style>
